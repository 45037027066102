import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorAuthentication } from '../store/reducers/authentication';
import { ROUTE_PATH } from '../constants/route';

function PrivateRoute({ children }) {
  const {
    profileDetail: {
      data: {
        user: {
          // subType = "",
          type = '',
        } = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);
  const auth = localStorage.getItem('auth');
  if (auth && type === 'organization' && window.location.pathname === ROUTE_PATH?.DASHBOARD) return <Navigate to={ROUTE_PATH?.CUSTOMER_DASHBOARD} />;
  if (auth && type === 'organization' && window.location.pathname === ROUTE_PATH?.VERIFICATION_REQUESTS) return <Navigate to={ROUTE_PATH?.CUSTOMER_DASHBOARD} />;
  return auth ? children : <Navigate to="/" />;
}

PrivateRoute.prototype = {
  children: PropTypes.element,
};

export default PrivateRoute;
