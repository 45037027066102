/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../layout';
import lang from '../../constants/lang';
import { PrimaryButton } from '../../components/Button';
import OtpField from '../../components/Otp/index';
import './otp.scss';
import { selectorAuthentication } from '../../store/reducers/authentication';
import { fetchVerifyOtp } from '../../store/reducers/authentication/apiThunk';
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import { ROUTE_PATH } from '../../constants/route';
import messsages from '../../constants/message';
import { supportMail } from '../../constants/constants';

const Otp = () => {
  const {
    OTP_VERIFICATION,
    ENTER_OTP_RECEIVED, VERIFY, CHANGE,
    VERIFY_EMAIL,
    FACING_ISSUE_CONTACT,
    ORGANIZATION,
  } = lang;
  const { MSG_SIGNED_IN, MSG_SIGNED_UP } = messsages;
  const { DASHBOARD, CUSTOMER_DASHBOARD } = ROUTE_PATH;
  const params = useParams();

  const {
    signup: {
      data: {
        user: {
          _id: signupId = '',
          email: signupEmail = '',
        } = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);

  const {
    login: {
      data: {
        user: {
          id: loginId = '',
          email: loginEmail = '',
        } = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otpValue, setOtpValue] = useState('');

  const customOtpChange = (e) => {
    setOtpValue(e);
  };

  const handleSubmitOtp = async () => {
    const requestObj = {
      userId: '',
      otp: otpValue,
      type: '',
    };
    let message = MSG_SIGNED_IN;
    if (params?.type === '1') {
      requestObj.userId = signupId;
      requestObj.type = 1;
      message = MSG_SIGNED_UP;
    } else {
      requestObj.userId = loginId;
      requestObj.type = 2;
    }
    const response = await dispatch(fetchVerifyOtp(requestObj)).then((val) => val);
    const {
      payload: { status = '', data: { user: { type = '' } = {} } = {} } = {},
    } = response;
    if (status === true) {
      ToastNotifySuccess(message);
      if (type === ORGANIZATION.toLowerCase()) {
        navigate(CUSTOMER_DASHBOARD);
      } else {
        navigate(DASHBOARD);
      }
    }
  };

  const handleSupportMail = () => {
    const mailtoLink = `mailto:${supportMail}`;
    window.open(mailtoLink);
  };

  return (
    <Layout>
      <div className="otp-wrap">
        <div className="content-heading">
          {params?.type === '1' ? VERIFY_EMAIL : OTP_VERIFICATION}
        </div>
        <div className="otp-content-wrap">
          <div className="subheading">
            {ENTER_OTP_RECEIVED}
          </div>
          <div className="email-wrap">
            {params?.type === '1' ? signupEmail : loginEmail}
            {' '}
            <span className="change-btn" onClick={() => navigate(-1)}>{CHANGE}</span>
          </div>
          <div className="field-wrap">
            <OtpField value={otpValue} handleOtpChange={(e) => customOtpChange(e)} resendOtpEmail={params?.type === '1' ? signupEmail : loginEmail} />
          </div>
          <div className="button-wrap">
            <PrimaryButton disabled={otpValue === ''} onClick={() => handleSubmitOtp()}>{VERIFY}</PrimaryButton>
          </div>
        </div>
        <div className="otp-content-footer-wrap">
          {FACING_ISSUE_CONTACT}
          <span className="supportMail" onClick={() => handleSupportMail()}>{supportMail}</span>
        </div>
      </div>
    </Layout>
  );
};

export default Otp;
