/* eslint-disable arrow-body-style */
import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import TextInput from '../TextField';
import lang from '../../constants/lang';
import { PrimaryButton } from '../Button';
import { validationRequestSchema } from '../../utils/validations/authValidation';
import { ToastNotifySuccess } from '../Toast/ToastNotify';
import { sendVerificationRequest } from '../../store/reducers/authentication/apiThunk';
import ReadMore from '../ReadMore';

const VerificationModal = ({
  id, designation, organizationName, type, year, setModalOpen, otherDetails = '',
}) => {
  const {
    EMAIL_ADDRESS, MESSAGE, ADD_MESSAGE, SEND_DETAILS, REQUEST_VERIFICATION, VERIFICATION_NOTE,
  } = lang;
  const dispatch = useDispatch();
  const submitHandler = async (values) => {
    const data = {
      email: values?.email,
      verificationTypeId: id,
      comment: values.message,
    };
    const response = await dispatch(sendVerificationRequest(data)).then((val) => val);
    const {
      payload: { status = '', message = '' },
    } = response;
    if (status === true) {
      setModalOpen(false);
      ToastNotifySuccess(message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    validationSchema: validationRequestSchema,
    onSubmit: submitHandler,
  });

  const handleChange = (e, name) => {
    const { target } = e;
    formik.setFieldValue(name, target.value);
  };

  const {
    handleSubmit,
    values: {
      email, message,
    } = {},
    touched: {
      email: tucEmail,
      message: tucMessage,

    } = {},
    errors: {
      email: errEmail,
      message: errMessage,
    } = {},
  } = formik;
  return (
    <div className="verification-modal-wrap">
      <div className="verification-heading">{REQUEST_VERIFICATION}</div>
      <div className="verification-modal-wrap">
        <div className="employment-details">
          {type === 2 ? 'Education details' : 'Employment details'}
          <div className="organization">{organizationName}</div>
          <div className="position">{designation}</div>
          <div className="position year-range">{year}</div>
          {
            otherDetails ? (
              <div className="description" style={{ marginTop: '8px' }}>
                <ReadMore textSize={70}>{otherDetails}</ReadMore>
              </div>
            ) : ''
          }
        </div>
        <div className="note">{VERIFICATION_NOTE}</div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="text-fields">
            <TextInput
              label={EMAIL_ADDRESS}
              placeholder={EMAIL_ADDRESS}
              require="true"
              type="text"
              name="email"
              onChange={(e) => handleChange(e, 'email')}
              value={email}
              error={tucEmail && !!errEmail}
              helper={tucEmail && errEmail}
              noIcon
              icon={(
                <EmailOutlinedIcon sx={{
                  color: '#7a869aa3',
                }}
                />
              )}
            />
            <TextInput
              label={MESSAGE}
              placeholder={ADD_MESSAGE}
              type="text"
              name="message"
              multiline
              rows={2}
              onChange={(e) => handleChange(e, 'message')}
              error={tucMessage && !!errMessage}
              helper={tucMessage && errMessage}
              value={message}
              noIcon
            />
            <PrimaryButton
              type="submit"
              variant="contained"
              color="primary"
            >
              {SEND_DETAILS}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerificationModal;
