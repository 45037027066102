import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import './tabs.scss';

const CustomTabs = ({
  tabList = [], selectedtabValue = '', OnChange, data = null,
}) => {
  const [tabValue, setTabValue] = useState(selectedtabValue);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    OnChange(newValue);
  };

  return (
    <div className="tabs-wrap">
      <Tabs
        value={tabValue}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        {
            tabList.map(({ value = '', label = '' }) => {
              let newLabel = label;
              if (data && (data[value]?.count || data[value]?.count === 0)) {
                newLabel = `${label}(${data[value]?.count})`;
              }
              return <Tab key={value} label={newLabel} />;
            })
        }
      </Tabs>
    </div>
  );
};

export default CustomTabs;
