/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../layout';
import lang from '../../constants/lang';
import './landingPage.scss';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { ROUTE_PATH } from '../../constants/route';
import Modal from '../../components/Modal';

const LandingPage = () => {
  const {
    CREDIBLE_CIRCLE,
    WHERE_CREDIBILITY_MATTERS,
    LANDING_DESCRIPTION_ONE,
    LANDING_DESCRIPTION_TWO,
    HOW_DOES_IT_WORKS,
    SIGN_UP,
    SIGN_IN,
    ABOUT_US, CONTACT,
    TERMS_CONDITION_PRIVACY_POLICY,
    ABOUT_DESCRIPTION,
    WE_VALUE_UR_FEEDBACK,
    CONTACT_EMAIL,
    ACCEPTANCE, REGISTRATION_MSG,
    ACCEPTANCE_MSG, USER_REGISTRATION,
    USER_RESPONSIBILITY, STEP_ONE, STEP_TWO, STEP_THREE,
  } = lang;
  const navigate = useNavigate();
  const { SIGNUP, LOGIN } = ROUTE_PATH;
  const [openAboutModal, setOpenAboutModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openTCmodal, setOpenTCmodal] = useState(false);

  const handleCloseDialog = (name) => {
    switch (name) {
      case 'about':
        setOpenAboutModal(false);
        break;
      case 'contact':
        setOpenContactModal(false);
        break;
      case 'termsCondition':
        setOpenTCmodal(false);
        break;
      default:
        break;
    }
  };

  const aboutContent = () => (
    <div className="edit-profile-wrap about-wrap">
      <div className="heading">{ABOUT_US}</div>
      <div className="card-wrap">
        <div className="subheading">{ABOUT_US}</div>
        <div className="description">{ABOUT_DESCRIPTION}</div>
      </div>
      <div className="card-wrap">
        <div className="subheading">{ABOUT_US}</div>
        <div className="description">{ABOUT_DESCRIPTION}</div>
      </div>
    </div>
  );
  const contantContent = () => (
    <div className="edit-profile-wrap about-wrap">
      <div className="heading">{CONTACT}</div>
      <div className="description centerAlign">{WE_VALUE_UR_FEEDBACK}</div>
      <div className="email-wrap subheading centerAlign">{CONTACT_EMAIL}</div>
    </div>
  );
  const TermsAndPrivacyContent = () => (
    <div className="edit-profile-wrap about-wrap">
      <div className="heading">{TERMS_CONDITION_PRIVACY_POLICY}</div>
      <div className="card-wrap">
        <div className="subheading">{ACCEPTANCE}</div>
        <div className="description">{ACCEPTANCE_MSG}</div>
      </div>
      <div className="card-wrap">
        <div className="subheading">{USER_REGISTRATION}</div>
        <div className="description">{REGISTRATION_MSG}</div>
      </div>
      <div className="card-wrap">
        <div className="subheading">{USER_RESPONSIBILITY}</div>
        <div className="description">
          <div>{STEP_ONE}</div>
          <div>{STEP_TWO}</div>
          <div>{STEP_THREE}</div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Layout showButtons showFooter>
        <div className="landing-content-wrap">
          <div className="heading">{CREDIBLE_CIRCLE}</div>
          <hr className="sepratorLine" />
          <div className="subheading">{WHERE_CREDIBILITY_MATTERS}</div>
          <div className="description">{LANDING_DESCRIPTION_ONE}</div>
          <div className="description">{LANDING_DESCRIPTION_TWO}</div>
          <div className="description link">{HOW_DOES_IT_WORKS}</div>
          <div className="button-wrap">
            <SecondaryButton
              sx={{
                marginRight: '32px',
                width: '172px',
                height: '42px',
                '@media screen and (max-width: 600px)': {
                  marginBottom: '20px',
                  width: '100%',
                },
              }}
              onClick={() => navigate(SIGNUP)}
            >
              {SIGN_UP}
            </SecondaryButton>
            <PrimaryButton
              sx={{
                width: '172px',
                height: '42px',
                '@media screen and (max-width: 600px)': {
                  marginBottom: '20px',
                  width: '100%',
                },
              }}
              onClick={() => navigate(LOGIN)}
            >
              {SIGN_IN}

            </PrimaryButton>
          </div>
          <div className="footer-wrap">
            <div className="description link tabs pb-5" onClick={() => setOpenAboutModal(true)}>{ABOUT_US}</div>
            <div className="description link tabs pb-5" onClick={() => setOpenContactModal(true)}>{CONTACT}</div>
            <div className="description link tabs" onClick={() => setOpenTCmodal(true)}>{TERMS_CONDITION_PRIVACY_POLICY}</div>
          </div>
        </div>
      </Layout>
      <Modal
        openDialog={openAboutModal}
        closeDialog={() => handleCloseDialog('about')}
        dialogContent={aboutContent()}
        dialogCss="editProfileDialog aboutDialog"
      />
      <Modal
        openDialog={openContactModal}
        closeDialog={() => handleCloseDialog('contact')}
        dialogContent={contantContent()}
        dialogCss="editProfileDialog aboutDialog"
      />
      <Modal
        openDialog={openTCmodal}
        closeDialog={() => handleCloseDialog('termsCondition')}
        dialogContent={TermsAndPrivacyContent()}
        dialogCss="editProfileDialog aboutDialog"
      />
    </>
  );
};
export default LandingPage;
