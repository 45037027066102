/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Tooltip } from '@mui/material';
import info from '../../assets/svg/infoCircle.svg';
import styles from './customized-tooltip.module.css';

const CustomizedToolTip = ({ title, children }) => (
  <Tooltip
    title={title}
    arrow
    // open
    placement="top"
    enterDelay={500}
    leaveDelay={200}
    enterTouchDelay={0}
    slotProps={{
      tooltip: {
        style: {
          width: '126px',
          color: '#000',
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '16px',
          backgroundColor: '#FBFBFB',
          border: '2px solid #C1C1C1',
          textAlign: 'center',
          padding: '12px 14px 13px 13px',
        },
      },
      arrow: {
        style: {
          color: '#FBFBFB',
        },
        className: styles.arrow_before,
      },
    }}
  >
    {children || <img src={info} className="info-icon" />}
  </Tooltip>
);

export default CustomizedToolTip;
