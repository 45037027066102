/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable space-before-function-paren */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
import { REGEX, CONSTANTS } from '../../constants/constants';
import validationMessages from '../../constants/message';

const {
  MSG_VALID_EMAIL,
  MSG_EMAIL_REQ,
  MSG_REQUIRED_PASSWORD,
  MSG_EMAIL_LENGTH,
  MSG_VALID_PASSWORD,
  MSG_PASSWORD_MATCH,
  MSG_FIRSTNAME_REQ,
  MSG_LASTNAME_REQ,
  MSG_COUNTRY_REQ,
  MSG_CONFIRM_PASSWORD,
  MSG_TERMS_CONDITION,
  MSG_QUALIFICATION_YEAR,
  MSG_INSTITUTE_NAME,
  MSG_COURSE_NAME,
  MSG_START_DATE,
  MSG_MATCH_YEAR,
  MSG_ROLE_YEAR,
  MSG_ORGANIZATION_YEAR,
  MSG_ORGANIZATION_NAME,
} = validationMessages;

const {
  ALLOWED_EXTENSION,
  MB_1,
} = CONSTANTS;

const { EMAIL_PATTERN, PASSWORD_PATTERN, QUALIFICATION_YEAR } = REGEX;
const validationSchema = yup.object({
  email: yup
    .string()
    .required(MSG_EMAIL_REQ)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH),
  password: yup
    .string()
    .required(MSG_REQUIRED_PASSWORD),
});

const validationSignupSchema = yup.object({
  firstName: yup.string().trim().required(MSG_FIRSTNAME_REQ),
  lastName: yup.string().trim().required(MSG_LASTNAME_REQ),
  email: yup.string().required(MSG_EMAIL_REQ).matches(EMAIL_PATTERN, MSG_VALID_EMAIL),
  password: yup.string().required(MSG_REQUIRED_PASSWORD).matches(PASSWORD_PATTERN, MSG_VALID_PASSWORD),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], MSG_PASSWORD_MATCH)
    .required(MSG_CONFIRM_PASSWORD),
  agreeToTerms: yup.boolean().oneOf([true], MSG_TERMS_CONDITION),
});

const validationEducationSchema = yup.object({
  courseName: yup.string().required(MSG_COURSE_NAME),
  instituteName: yup.string().required(MSG_INSTITUTE_NAME),
  qualificationYear: yup
    .string()
    .required(MSG_QUALIFICATION_YEAR)
    .test(MSG_MATCH_YEAR, MSG_MATCH_YEAR, (value) => {
      const currentYear = new Date().getFullYear();
      const isNumber = QUALIFICATION_YEAR.test(value);
      return isNumber && parseInt(value) <= currentYear && parseInt(value) > 999;
    }),
});

const validationEmploymentSchema = yup.object({
  organization: yup.string().required(MSG_ORGANIZATION_YEAR),
  role: yup.string().required(MSG_ROLE_YEAR),
  startDate: yup.string().required(MSG_START_DATE)
    .test({
      name: 'startDateValidation',
      test: function (value) {
        if (!value || value.trim() === '') {
          throw this.createError({
            message: MSG_START_DATE,
          });
        } else if (!value || value.trim() === '' || new Date(value) > new Date()) {
          throw this.createError({
            message: 'Start date must be on or before today',
          });
        }
        // Validation passed
        return true;
      },
    }),
  checked: yup.boolean(),
  endDate: yup.string().test({
    name: 'endDateValidation',
    test: function (value) {
      const { checked } = this.parent;

      if (!checked) {
        // If checked is true, endDate is required

        if (!value || value.trim() === '') {
          throw this.createError({
            message: 'End date is required',
          });
        } else if (!value || value.trim() === '' || new Date(value) > new Date()) {
          throw this.createError({
            message: 'End date must be on or before today',
          });
        }
      }

      // Validation passed
      return true;
    },
  }),
});
const validationEmailSchema = yup.object({
  email: yup
    .string()
    .required(MSG_VALID_EMAIL)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH),
});

const validationRequestSchema = yup.object({
  email: yup
    .string()
    .required(MSG_VALID_EMAIL)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH),
  message: yup
    .string()
    .trim()
    .max(250, 'Message cannot exceed 250 characters'),
});

const validationPasswordSchema = yup.object({
  password: yup
    .string()
    .required(MSG_REQUIRED_PASSWORD)
    .matches(PASSWORD_PATTERN, MSG_VALID_PASSWORD),
  confirmPassword: yup
    .string()
    .required(MSG_REQUIRED_PASSWORD)
    .oneOf([yup.ref('password'), null], MSG_PASSWORD_MATCH),
});

const validationEditProfileSchema = yup.object({
  firstName: yup.string().trim().required(MSG_FIRSTNAME_REQ),
  lastName: yup.string().trim().required(MSG_LASTNAME_REQ),
  email: yup.string().required(MSG_EMAIL_REQ).matches(EMAIL_PATTERN, MSG_VALID_EMAIL),
  country: yup.string().required(MSG_COUNTRY_REQ),
});

const validationEditOrganizationProfileSchema = yup.object({
  firstName: yup.string().trim().required(MSG_FIRSTNAME_REQ),
  lastName: yup.string().trim().required(MSG_LASTNAME_REQ),
  email: yup.string().required(MSG_EMAIL_REQ).matches(EMAIL_PATTERN, MSG_VALID_EMAIL),
  country: yup.string().required(MSG_COUNTRY_REQ),
  organizationName: yup.string().trim().required('Name of Organization is required'),
});

const validationChangePasswordSchema = yup.object({
  currentPassword: yup
    .string()
    .required(MSG_REQUIRED_PASSWORD)
    .matches(PASSWORD_PATTERN, MSG_VALID_PASSWORD),
  newPassword: yup
    .string()
    .required(MSG_REQUIRED_PASSWORD),
  confirmPassword: yup
    .string()
    .required(MSG_REQUIRED_PASSWORD)
    .oneOf([yup.ref('newPassword'), null], MSG_PASSWORD_MATCH),
});

const validationOrganizationSchema = yup.object({
  nameOfOrganization: yup
    .string().trim()
    .required(MSG_ORGANIZATION_NAME),
});

const validateFileType = (extension) => {
  if (ALLOWED_EXTENSION.includes(extension.toLowerCase())) {
    return true;
  }
  return false;
};

const validateFileSize = (uploadFile) => {
  const { size = 0 } = uploadFile;
  if (size > MB_1) {
    return true;
  }
  return false;
};

export {
  validationSchema,
  validationEmailSchema,
  validationPasswordSchema,
  validationSignupSchema,
  validationEditProfileSchema,
  validationChangePasswordSchema,
  validationEducationSchema,
  validationEmploymentSchema,
  validationOrganizationSchema,
  validateFileType,
  validateFileSize,
  validationRequestSchema,
  validationEditOrganizationProfileSchema,
};
