/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from 'react';
import './profile.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../constants/lang';
import eye from '../../assets/svg/eye.svg';
import people from '../../assets/svg/people.svg';
import avatar from '../../assets/svg/avatar.svg';
import Modal from '../Modal';
import { ROUTE_PATH } from '../../constants/route';
import { fetchProfileViews } from '../../store/reducers/authentication/apiThunk';
import { selectorAuthentication } from '../../store/reducers/authentication';
import { ToastNotifyError } from '../Toast/ToastNotify';

const ProfileSection = ({ isSearch = false }) => {
  const {
    VERIFICATION_REQUEST, VIEW_NO, SENT_REQUESTS,
  } = lang;

  const {
    profileDetail: {
      data: {
        user: {
          firstName = '',
          lastName = '',
          email = '',
          profileViewCount = 0,
          profileImagePath = '',
          receivedRequestCount = 0,
          SentRequestCount = 0,
        } = {},
      } = {},
    } = {},
    searchDetail: {
      data: {
        user: {
          firstName: fName = '',
          lastName: lName = '',
          profileImagePath: ImagePath = '',
          // email: mail = '',
          profileViewCount: viewCount = 0,
          receivedRequestCount: reqCount = 0,
          SentRequestCount: searchSentRequestCount = 0,
        } = {},
      } = {},
    } = {},

  } = useSelector(selectorAuthentication);

  const observerTarget = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const navigate = useNavigate();
  const [openViewedProfileDialog, setOpenViewedProfileDialog] = useState(false);
  const [viewedProfileUserList, setViewedProfileUserList] = useState([]);
  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    setOpenViewedProfileDialog(false);
    setCurrentPage(1);
    setIsLastPage(false);
  };

  const handleViewedProfileModal = async (page = 1) => {
    if (profileViewCount !== 0) {
      const query = `?page=${page}&limit=10`;
      const response = await dispatch(fetchProfileViews(query)).then((val) => val);
      const { payload: { data: { data = [], pagination = {} } = {}, status = '', message = '' } = {} } = response;
      if (status) {
        if (Math.ceil(pagination?.totalRecords / pagination?.limit) <= Number(page)) {
          console.log('page: ', page);
          setIsLastPage(true);
        }
        if (page === 1) {
          setViewedProfileUserList(data);
          setCurrentPage(2);
        } else {
          if (Math.ceil(pagination?.totalRecords / pagination?.limit) > Number(page)) {
            setCurrentPage((prev) => prev + 1);
          }
          setViewedProfileUserList((prev) => [...prev, ...data]);
        }
        if (!openViewedProfileDialog) {
          setOpenViewedProfileDialog(true);
        }
      } else {
        ToastNotifyError(message);
      }
    }
  };

  const onScroll = () => {
    if (observerTarget.current) {
      const { scrollTop, scrollHeight, clientHeight } = observerTarget.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log('isLastPage: ', isLastPage, currentPage);
        if (!isLastPage) {
          handleViewedProfileModal(currentPage);
        }
      }
    }
  };

  const viewedProfileContent = () => (
    <div className="edit-profile-wrap viewed-profile-wrap">
      <div className="heading mb-c-3">{VIEW_NO}</div>
      <div className="content-wrap" ref={observerTarget} onScroll={onScroll}>
        {viewedProfileUserList.map(
          (
            {
              profileImagePath: userProfileImagePath = '',
              firstName: userFirstName = '',
              lastName: userLastName = '',
              // email: userEmail = '',
            },
            index,
          ) => (
            <div
              className={`card-wrap ${index > 0 ? 'mt-c-2' : ''}`}
              key={index}
            >
              <div className="profile-pic-wrap">
                <img
                  src={
                    userProfileImagePath
                      ? `${process.env.REACT_APP_IMAGE_URL}${userProfileImagePath}`
                      : avatar
                  }
                  className="profileImage"
                  style={{ borderRadius: '50%' }}
                  alt=""
                />
              </div>
              <div className="right-wrap">
                <div className="name">
                  {`${userFirstName} ${userLastName}`}
                </div>
                {/* <div className="mail">{userEmail}</div> */}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );

  return (
    <>
      {!isSearch
        ? (
          <div className="profile-wrap">
            <div className="profile-section">
              <div className="profile">
                <img src={profileImagePath ? process.env.REACT_APP_IMAGE_URL + profileImagePath : avatar} className="profileImage" />
              </div>
            </div>
            <div className="profile-section-content">
              <div className="profile-name">{`${firstName} ${lastName}`}</div>
              <div className="profile-mail">{email}</div>
            </div>
            <div className="view-no">
              <img src={eye} alt="" />
              <div className="title" onClick={() => handleViewedProfileModal(1)}>
                {VIEW_NO}
              </div>
              <div className="number">{profileViewCount}</div>
            </div>
            <div className="view-no">
              <img src={people} alt="" />
              <div className="title" onClick={() => navigate(ROUTE_PATH.VERIFICATION_REQUESTS)}>
                Requests Received
              </div>
              <div className="number">{receivedRequestCount}</div>
            </div>
            <div className="view-no">
              <img src={people} alt="" />
              <div className="title" onClick={() => navigate(ROUTE_PATH.SENT_REQUESTS)}>
                {SENT_REQUESTS}
              </div>
              <div className="number">{SentRequestCount}</div>
            </div>
          </div>
        ) : (
          <div className="profile-wrap">
            <div className="profile-section">
              <div className="profile">
                <img src={ImagePath ? process.env.REACT_APP_IMAGE_URL + ImagePath : avatar} className="profileImage" />
              </div>
            </div>
            <div className="profile-section-content">
              <div className="profile-name">{`${fName} ${lName}`}</div>
              {/* <div className="profile-mail">{mail}</div> */}
            </div>
            <div className="view-no">
              <img src={eye} alt="" />
              <div className="title" style={{ cursor: 'default' }}>
                {VIEW_NO}
              </div>
              <div className="number">{viewCount}</div>
            </div>
            <div className="view-no">
              <img src={people} alt="" />
              <div className="title" style={{ cursor: 'default' }}>
                {VERIFICATION_REQUEST}
              </div>
              <div className="number">{reqCount}</div>
            </div>
            <div className="view-no">
              <img src={people} alt="" />
              <div className="title" style={{ cursor: 'default' }}>
                {SENT_REQUESTS}
              </div>
              <div className="number">{searchSentRequestCount}</div>
            </div>
          </div>
        )}
      <Modal
        openDialog={openViewedProfileDialog}
        closeDialog={() => handleCloseDialog()}
        dialogContent={viewedProfileContent()}
        dialogCss="editProfileDialog viewedProfileDialog"
      />
    </>
  );
};

export default ProfileSection;
