/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
const REGEX = {
  EMAIL_PATTERN: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,4}([-.]\w+)*$/,
  PASSWORD_PATTERN:
    /(?=[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])(?=.{8,16}).*$/, // Passwords must be Include at least 1 lowercase letter, 1 capital letter, 1 number,  1 special character => !@#$%^&*
  NAME_PATTERN: /^[ A-Za-z_@./#&+-]*$/,
  OTP_PATTERN: /[^a-zA-Z0-9]/g,
  QUALIFICATION_YEAR: /^\d+$/,
};

const supportMail = 'support@crediblecircle.com';

const CONSTANTS = {
  ALLOWED_EXTENSION: ['png', 'jpeg', 'webp', 'jpg'],
  MB_1: 5048576,
};

export {
  REGEX,
  supportMail,
  CONSTANTS,
};
