const messages = {
  MSG_VALID_EMAIL: 'Please enter a valid email',
  MSG_EMAIL_REQ: 'Email is required',
  MSG_EMAIL_LENGTH: 'Email should not be of more than 254 characters',
  MSG_EMAIL_EXIST: 'Email already exists',
  MSG_EMAIL_NOT_EXIST: 'Email not exists',
  MSG_REQUIRED_PASSWORD: 'Password is required',
  MSG_CURRENT_PASSWORD: 'Current Password is required',
  MSG_NEW_PASSWORD: 'New Password is required',
  MSG_CONFIRM_PASSWORD: 'Confirm Password is required',
  MSG_PASSWORD_MATCH: 'Passwords must match',
  MSG_VALID_PASSWORD: 'A valid password should contain atleast 8 characters, 1 small letter, 1 capital letter, 1 number, 1 special character',
  MSG_PASSWORD_RESET: 'Password Changed Successfully',
  MSG_OTP_NOT_MATCH: 'OTP does not matched',
  MSG_EMAIL_VERIFIED: 'Email Verified Successfully',
  MSG_OTP_VERIFIED: 'OTP Verified Successfully',
  MSG_OTP_RESEND: 'OTP Resent Successfully',
  MSG_OTP_SENT: 'OTP Sent Successfully',
  MSG_SIGNED_IN: 'Signed In Successfully',
  MSG_SIGNED_UP: 'Signed Up Successfully',
  MSG_INVALID_EMAIL_PASS: 'Invalid Email or Password',
  MSG_FIRSTNAME_REQ: 'First Name is required',
  MSG_LASTNAME_REQ: 'Last Name is required',
  MSG_COUNTRY_REQ: 'Country is required',
  MSG_FIRSTNAME_LENGTH: 'First name should not be of more than 50 characters',
  MSG_FIRSTNAME_VALID: 'First name should contain alphabets and special characters',
  MSG_LASTNAME_LENGTH: 'Last name should not be of more than 50 characters',
  MSG_LASTNAME_VALID: 'Last name should contain alphabets and special characters',
  MSG_CONTACT_NUMBER_REQ: 'Contact number is required',
  MSG_CONTACT_NUMBER_LENGTH: 'Contact number should be atleast 8 digits',
  MSG_CONTACT_INFO_VALID: 'Please enter valid Contact Info',
  MSG_TERMS_CONDITION: 'You must agree to the Terms and Conditions',
  MSG_COURSE_NAME: 'Course name is required',
  MSG_INSTITUTE_NAME: 'Institute name is required',
  MSG_QUALIFICATION_YEAR: 'Qualification year is required',
  MSG_START_DATE: 'Start date is required',
  MSG_END_DATE: 'End date is required',
  MSG_QUALIFICATION: 'Valid year of Qualification',
  MSG_MATCH_YEAR: 'Year should be equal to or before current year',
  MSG_ORGANIZATION_YEAR: 'Organization field is mandatory',
  MSG_ROLE_YEAR: 'Role/Designation field is mandatory',
  MSG_ORGANIZATION_NAME: 'Organization name is mandatory',
  MSG_FILE_FORMATE: 'Only png, jpeg, jpg, webp fomates allowed',
  MSG_FILE_SIZE: 'Image size not allowed more than 5 MB',
};
export default messages;
