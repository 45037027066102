/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import { useSelector } from 'react-redux';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Layout from '../../layout';
import CustomBreadcrummbs from '../../components/Breadcrumbs';
import './aboutCC.scss';
import lang from '../../constants/lang';
import { ROUTE_PATH } from '../../constants/route';
import { selectorAuthentication } from '../../store/reducers/authentication';

const AboutCredible = () => {
  const {
    profileDetail: { data: { user: { type: userType = '' } = {} } = {} } = {},
  } = useSelector(selectorAuthentication);
  const navigate = useNavigate();
  const {
    HOME,
    ABOUT_CC,
    ABOUT_US,
    ABOUT_DESCRIPTION,
    TERMS_CONDITION,
    ACCEPTANCE,
    ACCEPTANCE_MSG,
    USER_REGISTRATION,
    REGISTRATION_MSG,
    USER_RESPONSIBILITY,
    STEP_ONE,
    STEP_TWO,
    STEP_THREE,
    CONTACT_US,
    PRIVACY,
  } = lang;

  const { DASHBOARD, CUSTOMER_DASHBOARD } = ROUTE_PATH;

  const breadcrumbsList = [
    <div
      onClick={() => {
        navigate(userType === 'organization' ? CUSTOMER_DASHBOARD : DASHBOARD);
      }}
      style={{ cursor: 'pointer' }}
    >
      {HOME}
    </div>,
    <Typography key="2" color="text.primary">
      {ABOUT_CC}
    </Typography>,
  ];

  const aboutPage = [
    {
      title: ABOUT_US,
      description: ABOUT_DESCRIPTION,
    },
    {
      title: CONTACT_US,
      description: (
        <div>
          We value your feedback
          {' '}
          <b>contactus@crediblecircle.com</b>
        </div>
      ),
    },
    {
      title: TERMS_CONDITION,
      description: (
        <>
          <div>
            <div>{ACCEPTANCE}</div>
            <div style={{ padding: '4px 0px 8px 12px' }}>{ACCEPTANCE_MSG}</div>
          </div>
          <div>
            <div>{USER_REGISTRATION}</div>
            <div style={{ padding: '4px 0px 8px 12px' }}>
              {REGISTRATION_MSG}
            </div>
          </div>
          <div>
            <div>{USER_RESPONSIBILITY}</div>
            <div style={{ padding: '4px 0px 8px 12px' }}>
              <div>{STEP_ONE}</div>
              <div>{STEP_TWO}</div>
              <div>{STEP_THREE}</div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: PRIVACY,
      description: ABOUT_DESCRIPTION,
    },
  ];

  return (
    <Layout dashboard>
      <div className="myProfile-wrap">
        <div className="myProfile-header">
          <CustomBreadcrummbs breadcrumbs={breadcrumbsList} />
        </div>
        <div style={{ marginTop: '24px' }}>
          {aboutPage.map((data, index) => (
            <Accordion key={data.title}>
              <AccordionSummary
                sx={{
                  background: '#F2F2F4',
                  '&.Mui-expanded': {
                    background: '#0B1F3E',
                  },
                  '& .MuiAccordionSummary-content': {
                    margin: '22px 0',
                    color: '#252525',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    color: '#F2F2F4',
                  },
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    color: '#252525',
                  },
                  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    color: '#F2F2F4',
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}a-content`}
                id={`panel${index + 1}a-header`}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px',
                  }}
                >
                  {data?.title || ''}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ padding: '24px 48px 48px 34px', textAlign: 'start' }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '21px',
                    color: '#252525',
                  }}
                >
                  {data?.description || ''}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default AboutCredible;
