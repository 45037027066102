/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import './singleSelect.scss';
import { makeStyles } from '@mui/styles';
import { FormControl, Tooltip } from '@mui/material';
import infoIcon from '../../assets/svg/InfoEyeIcon.svg';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const focusedColor = '#CDA14B';
const useStyles = makeStyles(() => ({
  formControl: {
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: focusedColor,
    },
  },
  root: {
    // input label when focused
    '.MuiInputBase-root &:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: focusedColor,
    },
    // focused color for input with variant='outlined'
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: focusedColor,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: focusedColor,
      },
    },
  },
  customTooltip: {
    width: '200px',
    borderRadius: '3px !important',
    backgroundColor: '#FFEC89 !important',
    color: 'black !important',
    padding: '12px 18px !important',
    fontSize: '14px !important',
  },
}));
function SingleSelect(props) {
  const classes = useStyles();
  return (
    <FormControl className={`single-select ${props.classes} ${classes.formControl}`}>
      <div className="input-Label_text label-space">
        {props.labeltext}
        {props?.require && <span className="important">*</span>}
        {props?.hidden ? (
          <Tooltip
            title="This field will not be visible to the customer"
            placement="right"
            classes={{ tooltip: classes.customTooltip }}
            arrow
            enterTouchDelay={0}

          >
            <img src={infoIcon} alt="" className="info-icon2" />
          </Tooltip>
        ) : ''}
      </div>
      <Select
        className={props?.error ? `custom-Select field-error ${classes.root}` : `custom-Select ${classes.root}`}
        IconComponent={KeyboardArrowDownIcon}
        error={props.error}
        displayEmpty
        value={props.value || ''} // THIS IS NEEDED
        defaultValue=""
        MenuProps={MenuProps}
        renderValue={(selected) => {
          if (selected?.length === 0 || selected?.length === undefined) {
            return <span className="placeholder">{props.placeholder}</span>;
          }
          return selected;
        }}
        placeholder={props.placeholder}
        {...props}
      >
        {props?.option?.length > 0 && props?.option?.map((item) => (
          <MenuItem
            key={item._id || item.userId || item.value}
            value={item}
          >
            {typeof item === 'string' ? item : item.name || item.brandName}
          </MenuItem>
        ))}
      </Select>
      <div className="textfield-error">
        {
          props?.error ? (
            <div>
              <span>
                {props?.helper}
              </span>
            </div>
          ) : ''
        }
      </div>
    </FormControl>
  );
}

export default SingleSelect;
