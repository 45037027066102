import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './radioButton.scss';

const MyRadioGroup = ({
  option1 = '', option2 = '', option3 = '', onChange, value = '',
}) => (
  <RadioGroup
    row
    name="radio-buttons-group"
    value={value}
    onChange={(e) => onChange(e)}
  >
    <div className="radio-options">
      <FormControlLabel
        value="option1"
        control={<Radio />}
        label={option1}
      />
    </div>
    <div className="radio-options">
      <FormControlLabel
        value="option2"
        control={<Radio />}
        label={option2}
      />
    </div>
    {
      option3 ? (
        <div className="radio-options">
          <FormControlLabel
            value="option3"
            control={<Radio />}
            label={option3}
          />
        </div>
      ) : ''
    }
  </RadioGroup>
);

export default MyRadioGroup;
