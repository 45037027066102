/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Divider } from '@mui/material';
import fileIcon from '../../assets/svg/fileIcon.svg';
import verify from '../../assets/svg/verifyIcon.svg';
import './listingTile.scss';
import { PrimaryButton, SecondaryButton } from '../Button';
import edit from '../../assets/svg/editPen.svg';
import lang from '../../constants/lang';
import VerificationModal from './verificationModal';
import Modal from '../Modal';
import AuthorityDetails from '../authorityDetails';
import ReadMore from '../ReadMore';
import CustomizedToolTip from '../CustomizedTooltip';

const ListingTile = ({
  home, bodyIcon, homeContent, homeLeft, name, designation, year, credibilityScore, verificationCount, verifiedBy = '', handleEdit, id, type, otherDetails = '', isSearch = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const {
    CREDIBILITY, NETWORK_COUNT, VERIFICATION, EDIT, SOURCE, VERIFIED,
  } = lang;
  return (
    <div className="listing-tile">
      {!home
        ? (
          <div className="total-score-container">
            <div className="total-score-left">
              <img src={fileIcon} className="file-image" />
              <div className="credibility-score-wrap">
                <div className="credebitlity-score">
                  {CREDIBILITY}
                  <CustomizedToolTip title="Credibility Score" />
                </div>
                <div className="credibility-score-number">{credibilityScore}</div>
              </div>
            </div>
            <div className="total-score-right">
              <div className="qualification-no">{VERIFIED}</div>
              <div className={verifiedBy === 'source' ? 'qualification-value' : verifiedBy === 'network' ? 'qualification-source' : 'qualification-self'}>
                <img src={verify} />
                {verifiedBy === 'network' ? 'Network' : verifiedBy === 'source' ? SOURCE : 'Self'}
              </div>
            </div>
          </div>
        ) : null}
      <div className="listing-title-body">
        <div className="listing-body-left">
          <img src={bodyIcon} className="job-type" />
          <Divider
            orientation="vertical"
            variant="middle"
            className="divider"
            flexItem
            sx={{
              background: 'rgba(0, 0, 0, 0.10)',
              height: '88px',
              width: '1px',
            }}
          />
          {!home
            ? (
              <div className="body-wrap">
                <div className="body-content">{name}</div>
                <div className="body-content-source">{designation}</div>
                <div className="body-content-year">{year}</div>
                { otherDetails
                  && <ReadMore>{otherDetails}</ReadMore>}
              </div>
            ) : homeContent }
        </div>
        {!home
          ? (
            <div className="listing-body-right">
              <div className="body-right-content">
                {verificationCount > 0
                && (
                <>
                  <div className={verificationCount > 0 && 'curser-pointer'} onClick={() => verificationCount > 0 && setIsModal(true)}>{NETWORK_COUNT}</div>
                  <span className="body-right-no">{verificationCount}</span>

                </>
                )}
              </div>
              <div className="body-right-button">
                {
                  !isSearch ? (
                    <div className="primary-button">
                      <PrimaryButton disabled={isSearch} sx={{ padding: '12px 16px', fontSize: '12px !important' }} onClick={() => setModalOpen(true)}>{VERIFICATION}</PrimaryButton>
                    </div>
                  ) : ''
                }
                {
                  !isSearch ? (
                    <div className="secondary-button">
                      <SecondaryButton disabled={verifiedBy === 'source'} onClick={handleEdit} sx={{ padding: '8px 16px' }}>
                        <img style={{ width: '18px', height: '18px', marginRight: '4px' }} src={edit} />
                        {EDIT}
                      </SecondaryButton>
                    </div>
                  ) : (
                    ''
                  )
                }
              </div>
            </div>
          ) : homeLeft}
      </div>
      <Modal
        openDialog={modalOpen}
        closeDialog={() => setModalOpen(false)}
        dialogContent={(
          <VerificationModal id={id} designation={designation} year={year} organizationName={name} type={type} otherDetails={otherDetails} setModalOpen={setModalOpen} />
        )}
        dialogCss="employmentDialog"
      />
      <Modal
        openDialog={isModal}
        closeDialog={() => setIsModal(false)}
        dialogContent={(
          <AuthorityDetails id={id} />
        )}
        dialogCss="educationDialog"
      />
    </div>
  );
};

export default ListingTile;
