/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import lang from '../../constants/lang';
import { ROUTE_PATH } from '../../constants/route';
import home from '../../assets/svg/homeIcon.svg';
import education from '../../assets/svg/education.svg';
import employment from '../../assets/svg/employment.svg';
import bluehome from '../../assets/svg/blueHome.svg';
import blueEducation from '../../assets/svg/blueTeacher.svg';
import blueEmploy from '../../assets/svg/blueBriefcase.svg';
import './footer.scss';

const Footer = ({
  isSearch = false,
  setCurrentSearchData = () => null,
  currentSearchData = 0,
}) => {
  const [selectedItem, setSelectedItem] = useState();
  const { EMPLOYMENT_NAME, EDUCATION_NAME, HOME } = lang;
  const { DASHBOARD, PATH_EDUCATION, PATH_EMPLOYMENT } = ROUTE_PATH;
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname;

  const handleItemClick = (item) => {
    if (!isSearch) {
      navigate(item);
    } else if (item === PATH_EDUCATION) {
      setCurrentSearchData(1);
    } else if (item === PATH_EMPLOYMENT) {
      setCurrentSearchData(2);
    } else if (DASHBOARD) {
      setCurrentSearchData(0);
    }
    setSelectedItem(item);
  };

  useEffect(() => {
    if (isSearch) {
      if (currentSearchData === 1) {
        setSelectedItem(PATH_EDUCATION);
      } else if (currentSearchData === 2) {
        setSelectedItem(PATH_EMPLOYMENT);
      } else {
        setSelectedItem(DASHBOARD);
      }
    }
  }, [currentSearchData, isSearch]);
  return (
    <div className="footer">
      <div
        className={`sidebar-item ${selectedItem === DASHBOARD || currentRoute === DASHBOARD || (isSearch && currentSearchData === 0) ? 'selected' : ''}`}
        onClick={() => handleItemClick(DASHBOARD)}
      >
        <img src={selectedItem === DASHBOARD || currentRoute === DASHBOARD || (isSearch && currentSearchData === 0) ? bluehome : home} alt="" className="image-icon" />
        {HOME}
      </div>
      <div
        className={`sidebar-item ${selectedItem === PATH_EDUCATION || currentRoute === PATH_EDUCATION || (isSearch && currentSearchData === 1) ? 'selected' : ''}`}
        onClick={() => handleItemClick(PATH_EDUCATION)}
      >
        <img src={selectedItem === PATH_EDUCATION || currentRoute === PATH_EDUCATION || (isSearch && currentSearchData === 1) ? blueEducation : education} alt="" className="image-icon" />
        {EDUCATION_NAME}
      </div>
      <div
        className={`sidebar-item employment ${selectedItem === PATH_EMPLOYMENT || currentRoute === PATH_EMPLOYMENT || (isSearch && currentSearchData === 2) ? 'selected' : ''}`}
        onClick={() => handleItemClick(PATH_EMPLOYMENT)}
      >
        <img src={selectedItem === PATH_EMPLOYMENT || currentRoute === PATH_EMPLOYMENT || (isSearch && currentSearchData === 2) ? blueEmploy : employment} alt="" className="image-icon" />
        {EMPLOYMENT_NAME}
      </div>
    </div>
  );
};

export default Footer;
