/* eslint-disable jsx-a11y/alt-text */
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '../../assets/svg/searchCross.svg';
import lens from '../../assets/svg/search.svg';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: '#ffffff',
  '&:hover': {
    backgroundColor: '#ffffff',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  border: '1px solid #D7D7D7',
  width: '500px',
  color: 'rgba(4, 4, 4, 0.5)',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '250px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '250px',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '330px',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  height: '34px',
  '& .MuiInputBase-input': {
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
}));

export function SearchBox(
  {
    value, onChange, onFocus, onBlur, setSearchValue,
  },
  ...rest
) {
  return (
    <Search>
      <SearchIconWrapper>
        <img src={lens} />
      </SearchIconWrapper>
      <StyledInputBase
        {...rest}
        value={value}
        placeholder="Search People"
        inputProps={{ 'aria-label': 'search' }}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {value && (
        <IconButton
          size="small"
          style={{ position: 'absolute', right: '8px', top: '25%' }}
          onClick={() => setSearchValue('')}
        >
          <img src={ClearIcon} />
        </IconButton>
      )}
    </Search>
  );
}

export default SearchBox;
