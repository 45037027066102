/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { useLocation } from 'react-router-dom';
import Dashboard from '../dashboard';

import './employment.scss';
import lang from '../../constants/lang';
import TotalScore from '../../components/totalScore';
import ListingTile from '../../components/listingTile';
import { PrimaryButton } from '../../components/Button';
import employ from '../../assets/svg/EmpIcon.svg';
import Modal from '../../components/Modal';
import {
  fetchEmploymentById,
  fetchEmploymentList,
} from '../../store/reducers/authentication/apiThunk';
import AddEmployment from './addEmployement';
import NoDataScreen from '../../components/noDataScreen';
import { selectorAuthentication } from '../../store/reducers/authentication';

const EmploymentPage = () => {
  const [isModalOpen, setIsModalOpen] = useState();
  const [employmentId, setEmploymentId] = useState();
  const [employmentEdit, setEmploymentEdit] = useState();
  const [edit, setEdit] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    EMPLOYMENT_NAME,
    EMPLOYMENT_SCORE,
    EMPLOYMENT_NO,
    EMPLOYMENT,
  } = lang;
  const queryParams = new URLSearchParams(location.search);
  const paramId = queryParams.get('id');

  const {
    employmentData: {
      data: employmentList = [],
      credibleScore: credScore = 0,
    } = {},
  } = useSelector(selectorAuthentication);

  useEffect(() => {
    dispatch(fetchEmploymentList(paramId ? `?userId=${paramId}` : ''));
  }, []);

  const handleEdit = async (id) => {
    if (id) {
      setEmploymentId(id);
      const response = await dispatch(fetchEmploymentById(id)).then((val) => val);
      const {
        payload: { status = '', data },
      } = response;
      if (status === true) {
        setEdit(true);
        setEmploymentEdit(data);
        setIsModalOpen(true);
      }
    } else {
      setEdit(false);
      setEmploymentEdit('');
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      handleEdit(location?.state?.id);
    }
  }, []);

  return (
    <Dashboard>
      {credScore !== 0
        ? (
          <div className="employment-page-wrap">
            <div className="employment-header">
              <div className="dash-heading emp-heading">{EMPLOYMENT_NAME}</div>
              {!paramId
                ? (
                  <PrimaryButton onClick={() => {
                    handleEdit();
                  }}
                  >
                    {EMPLOYMENT}
                  </PrimaryButton>
                ) : ''}
            </div>
            <div className="employment-body-wrap">
              <TotalScore
                educationScore={EMPLOYMENT_SCORE}
                educationValue={Math.floor(credScore || 0)}
                qualifications={EMPLOYMENT_NO}
                qualificationNo={employmentList?.length}
              />
              {employmentList && employmentList.map(({
                organization: org, designation, tenureStartDate, tenureEndDate, verificationCount, isCurrentlyWorking, credibleScore, _id, verifiedBy, otherDetails,
              }) => (
                <ListingTile
                  credibilityScore={credibleScore}
                  name={org}
                  designation={designation}
                  year={`${moment(tenureStartDate).format('MMM, YYYY')} - ${isCurrentlyWorking ? 'Present' : moment(tenureEndDate).format('MMM, YYYY')}`}
                  bodyIcon={employ}
                  verificationCount={verificationCount}
                  handleEdit={() => handleEdit(_id)}
                  verifiedBy={verifiedBy}
                  otherDetails={otherDetails}
                  id={_id}
                  type={1}
                />
              ))}
            </div>
            <Modal
              openDialog={isModalOpen}
              closeDialog={() => setIsModalOpen(false)}
              dialogContent={(
                <AddEmployment
                  setIsModalOpen={setIsModalOpen}
                  employmentId={employmentId}
                  employmentEdit={employmentEdit}
                  edit={edit}
                />
        )}
              dialogCss="employmentDialog"
            />
          </div>
        ) : <NoDataScreen employment={true} />}
    </Dashboard>
  );
};

export default EmploymentPage;
