/* eslint-disable max-len */
/* eslint-disable consistent-return */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import create from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const fetchLogout = createAsyncThunk('authentication/logout', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.LOGOUT, data);
    dispatch(updateLoading(false));
    localStorage.clear();
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchLogin = createAsyncThunk('authentication/login', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.LOGIN, data);
    dispatch(updateLoading(false));
    const { data: { data: { token = '' }, status = '' } = {} } = response;
    if (status) {
      localStorage.setItem('auth', token);
    }
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchSignup = createAsyncThunk('authentication/fetchSignup', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.SIGNUP, data);
    dispatch(updateLoading(false));
    const { data: { data: { token = '' }, status = '' } = {} } = response;
    if (status) {
      localStorage.setItem('auth', token);
    }
    return response?.data;
  } catch (err) {
    return err;
  }
});
const fetchEducation = createAsyncThunk('authentication/fetchEducation', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.EDUCATION, data.data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchConfirmVerification = createAsyncThunk('authentication/fetchConfirmVerification', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.CONFIRM_VERIFICATION, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchAddComment = createAsyncThunk('authentication/fetchAddComment', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.ADD_COMMENT, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchEditEducation = createAsyncThunk('authentication/fetchEditEducation', async (requestData, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.patch(`${API_PATH.EDUCATION}${requestData.id}`, requestData.data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const deleteEducation = createAsyncThunk('authentication/deleteEducation', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.delete(API_PATH.EDUCATION + data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchEditEmployment = createAsyncThunk('authentication/fetchEditEmployment', async (requestData, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.patch(`${API_PATH.EMPLOYMENT}${requestData.id}`, requestData.data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const deleteEmployment = createAsyncThunk('authentication/deleteEmployment', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.delete(API_PATH.EMPLOYMENT + data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchEmployment = createAsyncThunk('authentication/fetchEmployment', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.EMPLOYMENT, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const sendVerificationRequest = createAsyncThunk('authentication/sendVerification', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.SEND_VERIFICATION_REQUEST, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchUser = createAsyncThunk('authentication/fetchUser', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.USER, data);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    return err;
  }
});

const FetchAuthorityList = createAsyncThunk('authentication/FetchAuthorityList', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.GET_VERIFIERS + data);
    dispatch(updateLoading(false));
    return response.data;
  } catch (err) {
    return err;
  }
});

const fetchVerifyOtp = createAsyncThunk('authentication/fetchVerifyOtp', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.VERIFY_OTP, data);
    dispatch(updateLoading(false));
    const { data: { data: { accessToken = '' }, status = '' } = {} } = response;
    if (status) {
      localStorage.setItem('auth', accessToken);
    }
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchProfileDetails = createAsyncThunk('authentication/fetchProfileDetails', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.PROFILE_DETAILS, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchEducationList = createAsyncThunk('authentication/fetchEducationList', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(data ? API_PATH.EDUCATION + data : API_PATH.EDUCATION, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});
const fetchEducationById = createAsyncThunk('authentication/fetchEducationById', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.EDUCATION + data, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchEmploymentList = createAsyncThunk('authentication/fetchEmploymentList', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(data ? API_PATH.EMPLOYMENT + data : API_PATH.EMPLOYMENT, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});
const fetchEmploymentById = createAsyncThunk('authentication/fetchEmploymentById', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(`${API_PATH.EMPLOYMENT}/${data}`, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchVerificationList = createAsyncThunk('authentication/fetchEmploymentList', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.VERIFICATION_REQUEST + data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchSentRequestList = createAsyncThunk('authentication/fetchSentRequestList', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.SENT_REQUEST + data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchHomeList = createAsyncThunk('authentication/fetchHomeList', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(data ? API_PATH.HOME + data : API_PATH.HOME, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchForgotPassword = createAsyncThunk('authentication/fetchForgotPassword', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.FORGOT_PASSWORD, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchResetPasswordLink = createAsyncThunk('authentication/fetchResetPasswordLink', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(`${API_PATH.VERIFY_PASSWORD_LINK}/${data?.token}`, data.body);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchResetPassword = createAsyncThunk('authentication/fetchResetPassword', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.RESET_PASSWORD, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchResendOtp = createAsyncThunk('authentication/fetchResendOtp', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.RESEND_OTP, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchUpdateProfile = createAsyncThunk('authentication/fetchUpdateProfile', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const {
      extension = '',
      uploadFile = '',
      profileImagePath = '',
      ...resData
    } = data;
    const s3Data = {
      extension,
    };
    let profileImagePathTemp = profileImagePath || '';
    if (extension) {
      const signedUrlRes = await API.post(API_PATH.GET_S3_URL, s3Data);
      const {
        data: {
          data: {
            imagePath = '',
            imageUploadUrl = '',
          } = {},
        } = {},
      } = signedUrlRes;
      profileImagePathTemp = imagePath;
      const config = {
        method: 'put',
        url: imageUploadUrl,
        data: uploadFile,
      };
      await axios(config);
    }
    let profileData = {
      ...resData,
    };
    if (profileImagePathTemp) {
      profileData = {
        ...resData,
        profileImagePath: profileImagePathTemp,
      };
    }
    const response = await API.put(API_PATH.UPDATE_PROFILE, profileData);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const deleteProfileImage = createAsyncThunk('authentication/deleteProfileImage', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.delete(API_PATH.DELETE_PROFILE_IMAGE);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchResetCurrentPassword = createAsyncThunk('authentication/fetchResetCurrentPassword', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.put(API_PATH.RESET_CURRENT_PASSWORD, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const getProfileSearchData = createAsyncThunk('authentication/fetchSearchData', async (query) => {
  const API = create({});
  try {
    const response = await API.get(API_PATH.SEARCH_DATA + query);
    return response?.data;
  } catch (err) {
    return err;
  }
});

const getUserSearchData = createAsyncThunk('authentication/fetchUserSearchData', async (query) => {
  const API = create({});
  try {
    const response = await API.get(API_PATH.SEARCH_USER_DETAIL + query);
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchSendOtpUpadateEmail = createAsyncThunk('authentication/fetchSendOtpUpadateEmail', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.SEND_OTP_UPDATE_EMAIL, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});
const fetchVerifyOtpUpdateEmail = createAsyncThunk('authentication/fetchVerifyOtpUpdateEmail', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.post(API_PATH.VERIFY_OTP_UPDATE_EMAIL, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchProfileViews = createAsyncThunk('authentication/fetchProfileViews', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.PROFILE_VIEWS + data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchSearchUserDetails = createAsyncThunk('authentication/fetchSearchUserDetails', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(`${API_PATH.SEARCH_USER_DETAILS}/${data.id}`, data.body);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchVerificationRequestsList = createAsyncThunk('authentication/fetchVerificationRequestsList', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(`${API_PATH.VERIFICATION_REQUESTS_LISTS}?status=${data.status}&limit=${data.limit}&page=${data.page}`, data.body);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchAddSubtype = createAsyncThunk('authentication/fetchAddSubtype', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.put(API_PATH.ADD_SUBTYPE, data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchViewedProfile = createAsyncThunk('authentication/fetchViewedProfile', async (data, { dispatch }) => {
  const API = create({});
  try {
    dispatch(updateLoading(true));
    const response = await API.get(API_PATH.VIEWED_PROFILE_LISTS + data);
    dispatch(updateLoading(false));
    return response?.data;
  } catch (err) {
    return err;
  }
});

export {
  fetchLogin,
  fetchLogout,
  fetchUser,
  fetchSignup,
  fetchVerifyOtp,
  fetchProfileDetails,
  fetchForgotPassword,
  fetchEducationList,
  fetchEducationById,
  fetchEducation,
  fetchEmployment,
  fetchEmploymentList,
  fetchEmploymentById,
  fetchUpdateProfile,
  fetchEditEducation,
  fetchEditEmployment,
  fetchResetPasswordLink,
  fetchResetPassword,
  fetchResendOtp,
  fetchHomeList,
  fetchResetCurrentPassword,
  fetchSendOtpUpadateEmail,
  fetchVerifyOtpUpdateEmail,
  getProfileSearchData,
  getUserSearchData,
  fetchVerificationList,
  sendVerificationRequest,
  fetchProfileViews,
  fetchSearchUserDetails,
  fetchVerificationRequestsList,
  deleteEducation,
  deleteProfileImage,
  deleteEmployment,
  FetchAuthorityList,
  fetchConfirmVerification,
  fetchAddComment,
  fetchAddSubtype,
  fetchViewedProfile,
  fetchSentRequestList,
};
