/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-undef */
import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import Layout from '../../layout';
import './customerDashboard.scss';
import lang from '../../constants/lang';
import avatar from '../../assets/svg/avatar.svg';
import edit from '../../assets/svg/edit.svg';
import sms from '../../assets/svg/sms.svg';
import location from '../../assets/svg/Location.svg';
import CustomTabs from '../../components/Tabs';
import verify from '../../assets/svg/verify.svg';
import infoCircle from '../../assets/svg/infoCircle.svg';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextField';
import empty from '../../assets/svg/emptyScreenIcon.svg';
import MyRadioGroup from '../../components/radioButton';
import { validateFileSize, validateFileType, validationOrganizationSchema } from '../../utils/validations/authValidation';
import {
  fetchAddComment,
  fetchAddSubtype, fetchConfirmVerification, fetchProfileDetails, fetchUpdateProfile, fetchVerificationList, fetchViewedProfile, getUserSearchData,
} from '../../store/reducers/authentication/apiThunk';
import { selectorAuthentication } from '../../store/reducers/authentication';
import user from '../../assets/svg/user.svg';
import { ToastNotifyError, ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import { ROUTE_PATH } from '../../constants/route';
import messages from '../../constants/message';
import ReadMore from '../../components/ReadMore';

const customerDashboard = () => {
  const {
    VERIFIED_REQUESTS, PENDING_REQUESTS, VIEWED_PROFILES,
    VIEW_DETAILS, VERIFICATE,
    PENDING, VERIFY,
    VERIFICATION_DETAILS,
    EDUCATION_DETAILS,
    MESSAGE,
    COMMENT,
    ADD_COMMENT_HERE,
    ADD_COMMENT,
    CONFIRM_VERIFICATION,
    VIEW_DETAILS_SUBHEADING,
    NO_DATA,
    NO_VERIFICATION_DATA,
    NO_PENDING_DATA,
    NO_VIEWED_DATA,
    SIGNING_AS_ORGANIZATION,
    NAME_OF_ORGANIZATION,
    CONFIRM,
    EMPLOYMENT_DETAILS,
  } = lang;
  const { MSG_FILE_FORMATE, MSG_FILE_SIZE } = messages;

  const tabListValue = [
    {
      value: 0,
      label: 'Verified Requests',
    },
    {
      value: 1,
      label: 'Pending Requests',
    },
    {
      value: 2,
      label: VIEWED_PROFILES,
    },
  ];

  const {
    profileDetail: {
      data: {
        user: {
          firstName = '',
          lastName = '',
          email: fetchEmail = '',
          country = '',
          organizationName = '',
          profileImagePath = '',
          type = '',
          subType = '',
        } = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);

  const profileImageInput = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const paramId = queryParams.get('id');
  const observerTarget = useRef(null);
  const [file, setFile] = useState(null);
  const [tabValue, setTabValue] = useState(tabListValue[1]?.value);
  const [openViewDetailDialog, setOpenViewDetailDialog] = useState(false);
  const [openConfirmVerificationDetail, setOpenConfirmVerificationDialog] = useState(false);
  const [openOrganizationType, setOpenOrganizationType] = useState(false);
  const [organizationTypeVal, setOrganizationTypeVal] = useState('option3');
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [commentText, setCommentText] = useState();
  const [verificationDetail, setVerificationDetail] = useState([]);
  const [requestType, setRequestType] = useState();
  const [queryDocument, setQueryDocument] = useState();

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    id: '',
    profileImage: '',
  });
  const getVerificationList = async (page = 1) => {
    if (subType !== 'client') {
      const query = `?page=${page}&limit=10&type=${
        tabValue === 0 ? 'verified' : 'pending'
      }`;
      const response = await dispatch(fetchVerificationList(query)).then(
        (val) => val,
      );
      const {
        payload: {
          status = false,
          data: {
            verificationRequests = [],
            perPageData = 0,
            queryDocumentCount = 0,
          },
        },
      } = response;
      if (status === true) {
        if (Math.ceil(queryDocumentCount / perPageData) <= Number(page)) {
          setIsLastPage(true);
        }
        if (page === 1) {
          setCurrentPage(2);
          setUserList(verificationRequests);
        } else {
          if (Math.ceil(queryDocumentCount / perPageData) > Number(page)) {
            setCurrentPage(currentPage + 1);
          }
          setUserList((prev) => [...prev, ...verificationRequests]);
        }
        if (tabValue === 0) {
          setQueryDocument([{ count: queryDocumentCount }, {}, {}]);
        } else {
          setQueryDocument([{}, { count: queryDocumentCount }, {}]);
        }
      }
    } else {
      setUserList([]);
    }
  };

  const getUserDetails = async () => {
    const response = await dispatch(fetchProfileDetails()).then((val) => val);
    const {
      payload: {
        status = '',
        data: { user: { subType: orgType = null } = {} },
      },
    } = response;
    if (status === true && !orgType) {
      setOpenOrganizationType(true);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getViewedProfileList = async (page = 1) => {
    if (subType !== 'source') {
      const query = `?page=${page}&limit=10`;
      const response = await dispatch(fetchViewedProfile(query)).then(
        (val) => val,
      );
      const {
        payload: {
          status = '',
          data = [],
          pagination = {},
        } = {},
      } = response;
      if (status === true) {
        if (Math.ceil(pagination?.totalRecords / pagination?.limit) <= Number(page)) {
          setIsLastPage(true);
        }
        if (page === 1) {
          setUserList(data);
          setCurrentPage(2);
        } else {
          if (Math.ceil(pagination?.totalRecords / pagination?.limit) > Number(page)) {
            setCurrentPage(currentPage + 1);
          }
          setUserList((prev) => [...prev, ...data]);
        }
        setQueryDocument([{}, {}, { count: pagination?.totalRecords }]);
      }
    } else {
      setUserList([]);
    }
  };

  const onScroll = () => {
    if (observerTarget.current) {
      const { scrollTop, scrollHeight, clientHeight } = observerTarget.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (!isLastPage) {
          if (tabValue === 2) {
            getViewedProfileList(currentPage);
          } else {
            getVerificationList(currentPage);
          }
        }
      }
    }
  };

  useEffect(() => {
    setUserList([]);
    setIsLastPage(false);
    setCurrentPage(1);
    if (tabValue === 2) {
      getViewedProfileList(1);
    } else {
      getVerificationList(1);
    }
  }, [tabValue]);

  useEffect(() => {
    if (type === 'individual') {
      navigate(ROUTE_PATH.DASHBOARD);
    }
  }, [type]);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const handleImage = async (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      try {
        const fileObjectURL = URL.createObjectURL(selectedFile);
        setFile(fileObjectURL);
        const updatedData = {
          profileImagePath: profileImagePath || '',
          uploadFile: e.target.files[0],
          extension: e.target.files[0].name.split('.').pop(),
        };
        if (updatedData?.extension && !validateFileType(updatedData?.extension)) {
          ToastNotifyError(MSG_FILE_FORMATE);
        } else if (updatedData?.uploadFile && validateFileSize(updatedData?.uploadFile)) {
          ToastNotifyError(MSG_FILE_SIZE);
        } else {
          const response = await dispatch(fetchUpdateProfile(updatedData)).then((val) => val);
          const {
            payload: {
              status,
            } = {},
          } = response;
          if (status) {
            await dispatch(fetchProfileDetails());
          }
        }
      } catch (error) {
        console.error('Error creating object URL:', error);
      }
    }
  };

  const renderNoDataFound = () => (
    <div className="home-content-wrap">
      <img src={empty} alt="" />
      <div className="no-data">{NO_DATA}</div>
      <div className="no-content">
        {
      tabValue === 0 ? NO_VERIFICATION_DATA : tabValue === 1 ? NO_PENDING_DATA : NO_VIEWED_DATA
      }
      </div>
    </div>
  );

  const renderVerifiedRequest = () => (
    userList.length !== 0
      ? userList.map(({
        userDetails: {
          profileImagePath: eachUserProfileImage = '',
          lastName: eachUserLastName = '',
          email = '',
          firstName: eachUserFirstName = '',
        } = {},
        _id = '',
        employmentDetails = [],
        educationDetails = [],
        requesterComments = '',
      }) => (
        <div
          className="card-wrap"
          onClick={(e) => {
            if (window.screen.width <= 768 && e?.target?.tagName !== 'BUTTON') {
              setOpenViewDetailDialog(true);
              if (employmentDetails.length) {
                setVerificationDetail([{ ...employmentDetails[0], comment: requesterComments?.comment }]);
                setRequestType(0);
                setUserData({
                  name: `${eachUserFirstName} ${eachUserLastName}`,
                  email,
                  id: _id,
                  profileImage: eachUserProfileImage,
                });
              } else {
                setVerificationDetail([{ ...educationDetails[0], comment: requesterComments?.comment }]);
                setRequestType(1);
                setUserData({
                  name: `${eachUserFirstName} ${eachUserLastName}`,
                  email,
                  id: _id,
                  profileImage: eachUserProfileImage,
                });
              }
            }
          }}
        >
          <div className="profilePic-wrap">
            <img src={eachUserProfileImage ? process.env.REACT_APP_IMAGE_URL + eachUserProfileImage : avatar} className="profileImage" alt="" />
          </div>
          <div className="right-content-wrap">
            <div className="name-wrap">
              <div className="name">{`${eachUserFirstName} ${eachUserLastName}`}</div>
              <div className="email">{email}</div>
            </div>
            <div className="button-wrap">
              <SecondaryButton
                sx={{
                  width: 'auto',
                  padding: '12px 16px',
                }}
                onClick={() => {
                  setOpenViewDetailDialog(true);
                  if (employmentDetails.length) {
                    setVerificationDetail([{ ...employmentDetails[0], comment: requesterComments?.comment }]);
                    setRequestType(0);
                    setUserData({
                      name: `${eachUserFirstName} ${eachUserLastName}`,
                      email,
                      id: _id,
                      profileImage: eachUserProfileImage,
                    });
                  } else {
                    setVerificationDetail([{ ...educationDetails[0], comment: requesterComments?.comment }]);
                    setRequestType(1);
                    setUserData({
                      name: `${eachUserFirstName} ${eachUserLastName}`,
                      email,
                      id: _id,
                      profileImage: eachUserProfileImage,
                    });
                  }
                }}
              >
                {VIEW_DETAILS}
              </SecondaryButton>
            </div>
            <div className="status-wrap">
              <div className="verify-tag-wrap">
                <img src={verify} className="mr-c-1" alt="" />
                {VERIFICATE}
              </div>
            </div>
          </div>
        </div>
      )) : renderNoDataFound()
  );

  const renderPendingRequestList = () => (
    userList.length !== 0
      ? userList.map(
        ({
          userDetails: {
            profileImagePath: eachUserProfileImage = '',
            lastName: eachUserLastName = '',
            email = '',
            firstName: eachUserFirstName = '',
          } = {},
          _id = '',
          employmentDetails = [],
          educationDetails = [],
          requesterComments = '',
        }) => (
          <div
            className="card-wrap pending-card-wrap"
            // onClick={(e) => {
            //   if (window.screen.width <= 768 && e?.target?.tagName !== 'BUTTON') {
            //     setOpenViewDetailDialog(true);
            //     if (employmentDetails.length) {
            //       setVerificationDetail([{ ...employmentDetails[0], comment: requesterComments?.comment }]);
            //       setRequestType(0);
            //       setUserData({
            //         name: `${eachUserFirstName} ${eachUserLastName}`,
            //         email,
            //         id: _id,
            //         profileImage: eachUserProfileImage,
            //       });
            //     } else {
            //       setVerificationDetail([{ ...educationDetails[0], comment: requesterComments?.comment }]);
            //       setRequestType(1);
            //       setUserData({
            //         name: `${eachUserFirstName} ${eachUserLastName}`,
            //         email,
            //         id: _id,
            //         profileImage: eachUserProfileImage,
            //       });
            //     }
            //   }
            // }}
          >
            <div className="profilePic-wrap">
              <img src={eachUserProfileImage ? process.env.REACT_APP_IMAGE_URL + eachUserProfileImage : avatar} className="profileImage" alt="" />
            </div>
            <div className="right-content-wrap">
              <div className="name-wrap">
                <div className="name">{`${eachUserFirstName} ${eachUserLastName}`}</div>
                <div className="email">{email}</div>
              </div>
              <div className="button-wrap">
                {/* <SecondaryButton
                  sx={{
                    width: 'auto',
                    padding: '12px 16px',
                    marginRight: '20px',
                  }}
                  onClick={() => {
                    setOpenViewDetailDialog(true);
                    if (employmentDetails.length) {
                      setVerificationDetail([{ ...employmentDetails[0], comment: requesterComments?.comment }]);
                      setRequestType(0);
                      setUserData({
                        name: `${eachUserFirstName} ${eachUserLastName}`,
                        email,
                        id: _id,
                        profileImage: eachUserProfileImage,
                      });
                    } else {
                      setVerificationDetail([{ ...educationDetails[0], comment: requesterComments?.comment }]);
                      setRequestType(1);
                      setUserData({
                        name: `${eachUserFirstName} ${eachUserLastName}`,
                        email,
                        id: _id,
                        profileImage: eachUserProfileImage,
                      });
                    }
                  }}
                >
                  {VIEW_DETAILS}
                </SecondaryButton> */}
              </div>
              <div className="footer-wrap">
                <PrimaryButton
                  sx={{
                    width: 'auto',
                    padding: '13px 16px',
                    height: 'max-content',
                    '@media screen and (max-width: 768px)': {
                      padding: '8px 16px',
                    },
                    '@media screen and (max-width: 480px)': {
                      fontSize: '12px',
                    },
                  }}
                  onClick={() => {
                    setOpenConfirmVerificationDialog(true);
                    if (employmentDetails.length) {
                      setVerificationDetail([{ ...employmentDetails[0], comment: requesterComments?.comment }]);
                      setRequestType(0);
                      setUserData({
                        name: `${eachUserFirstName} ${eachUserLastName}`,
                        email,
                        id: _id,
                        profileImage: eachUserProfileImage,
                      });
                    } else {
                      setVerificationDetail([{ ...educationDetails[0], comment: requesterComments?.comment }]);
                      setRequestType(1);
                      setUserData({
                        name: `${eachUserFirstName} ${eachUserLastName}`,
                        email,
                        id: _id,
                        profileImage: eachUserProfileImage,
                      });
                    }
                  }}
                >
                  {VERIFY}
                </PrimaryButton>
                <div className="status-wrap">
                  <div className="verify-tag-wrap pending-tag-wrap">
                    <img
                      src={infoCircle}
                      className="filterImage mr-c-1"
                      alt=""
                    />
                    {PENDING}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      ) : renderNoDataFound()
  );
  const renderViewedRequestList = () => (
    userList.length !== 0
      ? userList.map(({
        _id = '',
        lastName: eachUserLastName = '',
        email = '',
        firstName: eachUserFirstName = '',
        profileImagePath: eachUserProfileImage = '',
        employmentDetails = [],
        educationDetails = [],
      }) => (
        <div
          className="card-wrap"
        >
          <div className="profilePic-wrap">
            <img src={eachUserProfileImage ? process.env.REACT_APP_IMAGE_URL + eachUserProfileImage : avatar} className="profileImage" alt="" />
          </div>
          <div className="right-content-wrap">
            <div className="name-wrap">
              <div className="name">{`${eachUserFirstName} ${eachUserLastName}`}</div>
              <div className="email">{email}</div>
            </div>
          </div>
        </div>
      )) : renderNoDataFound()
  );

  const renderVerificationRequests = () => {
    switch (tabValue) {
      case 1:
        return renderPendingRequestList();
      case 2:
        return renderViewedRequestList();
      default:
        return renderVerifiedRequest();
    }
  };

  const handleCloseDialog = () => {
    setOpenViewDetailDialog(false);
    setOpenOrganizationType(false);
    setOpenConfirmVerificationDialog(false);
  };

  const viewDetailContent = () => (
    <div className="verificationDetail-content-wrap">
      <div className="header-wrap">
        <div className="heading">{VERIFICATION_DETAILS}</div>
      </div>
      <div className="content-wrap">
        <div className="profile-detail-wrap">
          <div className="profile-pic-wrap">
            <img src={userData?.profileImage ? process.env.REACT_APP_IMAGE_URL + userData.profileImage : avatar} className="profileImage" alt="" />
          </div>
          <div className="other-detail-wrap">
            <div className="name">{userData?.name}</div>
            <div className="email">{userData?.email}</div>
          </div>
        </div>
        {
            verificationDetail[0]?.organization || verificationDetail[0]?.institute
              ? (
                <div className="education-detail-wrap">
                  <div className="heading mb-c-1">
                    {requestType === 0 ? EMPLOYMENT_DETAILS : EDUCATION_DETAILS}
                  </div>
                  <div className="subheading mb-c-1">
                    {requestType === 0
                      ? verificationDetail[0]?.organization
                      : verificationDetail[0]?.institute}
                  </div>
                  <div className="description mb-c-1">
                    {requestType === 0
                      ? verificationDetail[0]?.designation
                      : verificationDetail[0]?.course}
                  </div>
                  <div className="description">
                    { verificationDetail[0]?.yearOfQualification || `${moment(verificationDetail[0]?.tenureStartDate).format('MMM, YYYY')} - ${verificationDetail[0]?.tenureEndDate !== null ? moment(verificationDetail[0]?.tenureEndDate).format('MMM, YYYY') : 'Present'}`}
                  </div>
                  {
                    verificationDetail[0]?.otherDetails
                   && (
                   <div className="description" style={{ marginTop: '8px' }}>
                     <ReadMore textSize={70}>{verificationDetail[0]?.otherDetails}</ReadMore>
                   </div>
                   )
                  }
                </div>
              ) : ''
          }
        {
            verificationDetail[0]?.comment ? (
              <div className="education-detail-wrap">
                <div className="heading mb-c-1">{MESSAGE}</div>
                <div className="description">{verificationDetail[0]?.comment}</div>
              </div>
            ) : ''
          }
      </div>
    </div>
  );

  const handleConfirmVerification = async () => {
    const data = {
      id: userData?.id,
      comment: commentText,
    };
    const response = await dispatch(fetchConfirmVerification(data)).then(
      (val) => val,
    );
    const { payload: { status = '' } = {} } = response;
    if (status === true) {
      ToastNotifySuccess('confirm request successfully');
      setOpenConfirmVerificationDialog(false);
      setCommentText('');
    }
  };

  const handleAddComment = async () => {
    if (!commentText) {
      ToastNotifyError("Comment filled can't be empty");
    } else {
      const data = {
        id: userData?.id,
        comment: commentText,
      };
      const response = await dispatch(fetchAddComment(data)).then(
        (val) => val,
      );
      const { payload: { status } } = response;
      if (status === true) {
        ToastNotifySuccess('Feedback sent successfully');
        setOpenConfirmVerificationDialog(false);
        setCommentText('');
        getVerificationList();
      }
    }
  };

  const confirmVerificationDetailContent = () => (
    <div className="verificationDetail-content-wrap">
      <div className="header-wrap">
        <div className="heading">{VERIFICATION_DETAILS}</div>
        <div className="subheading">{VIEW_DETAILS_SUBHEADING}</div>
      </div>
      <div className="content-wrap">
        <div className="profile-detail-wrap">
          <div className="profile-pic-wrap">
            <img
              src={
                userData?.profileImage
                  ? process.env.REACT_APP_IMAGE_URL + userData.profileImage
                  : avatar
              }
              className="profileImage"
              alt=""
            />
          </div>
          <div className="other-detail-wrap">
            <div className="name">{userData?.name}</div>
            <div className="email">{userData?.email}</div>
          </div>
        </div>
        <div className="education-detail-wrap">
          <div className="heading mb-c-1">
            {requestType === 0 ? EMPLOYMENT_DETAILS : EDUCATION_DETAILS}
          </div>
          <div className="subheading mb-c-1">
            {requestType === 0
              ? verificationDetail[0]?.organization
              : verificationDetail[0]?.institute}
          </div>
          <div className="description mb-c-1">
            {requestType === 0
              ? verificationDetail[0]?.designation
              : verificationDetail[0]?.course}
          </div>
          <div className="description">
            {verificationDetail[0]?.yearOfQualification
              || `${moment(verificationDetail[0]?.tenureStartDate).format(
                'MMM, YYYY',
              )} - ${
                verificationDetail[0]?.tenureEndDate !== null
                  ? moment(verificationDetail[0]?.tenureEndDate).format(
                    'MMM, YYYY',
                  )
                  : 'Present'
              }`}
          </div>
          {verificationDetail[0]?.otherDetails && (
            <div className="description" style={{ marginTop: '8px' }}>
              <ReadMore textSize={70}>
                {verificationDetail[0]?.otherDetails}
              </ReadMore>
            </div>
          )}
        </div>
        {verificationDetail[0]?.comment ? (
          <div className="education-detail-wrap">
            <div className="heading mb-c-1">{MESSAGE}</div>
            <div className="description">{verificationDetail[0]?.comment}</div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="footer-wrap">
        <div className="comment-wrap">
          <TextInput
            placeholder={ADD_COMMENT_HERE}
            name="comment"
            type="text"
            multiline
            rows={1}
            label={COMMENT}
            onChange={(e) => setCommentText(e.target.value)}
            value={commentText}
            noIcon
          />
        </div>
        <div className="button-wrap">
          <PrimaryButton
            sx={{
              marginRight: '28px',
            }}
            onClick={() => handleConfirmVerification()}
          >
            {CONFIRM_VERIFICATION}
          </PrimaryButton>
          <SecondaryButton
            sx={{
              '@media screen and (max-width: 480px)': {
                marginBottom: '16px',
              },
            }}
            onClick={() => handleAddComment()}
          >
            {ADD_COMMENT}
          </SecondaryButton>
        </div>
      </div>
    </div>
  );

  const returnOptionVal = (val) => {
    switch (val) {
      case 'option1':
        return 'Source';
      case 'option2':
        return 'Client';
      default:
        return 'Both';
    }
  };

  const submitHandler = async (values) => {
    const { nameOfOrganization } = values;
    const reqData = {
      organizationName: nameOfOrganization,
      subType: returnOptionVal(organizationTypeVal),
    };
    const response = await dispatch(fetchAddSubtype(reqData)).then((val) => val);
    const {
      payload: { status = '' } = {},
    } = response;
    if (status === true) {
      setOpenOrganizationType(false);
      dispatch(fetchProfileDetails());
    }
  };

  const formik = useFormik({
    initialValues: {
      nameOfOrganization: '',
    },
    validationSchema: validationOrganizationSchema,
    onSubmit: submitHandler,
  });

  const {
    handleSubmit,
    values: {
      nameOfOrganization,
    } = {},
    touched: {
      nameOfOrganization: tucNameOfOrganization = false,
    } = {},
    errors: {
      nameOfOrganization: errNameOfOrganization,
    } = {},
  } = formik;

  const handleChange = (e, name) => {
    formik.setFieldValue(name, e.target.value);
  };

  const viewOrganizationType = () => (
    <div className="organization-popup-wrap">
      <div className="dialogHeading">
        {SIGNING_AS_ORGANIZATION}
      </div>
      <div className="userType-wrap">
        <MyRadioGroup option1="Source" option2="Client" option3="Both" value={organizationTypeVal} onChange={(e) => setOrganizationTypeVal(e.target.value)} />
      </div>
      <div>
        <form onSubmit={handleSubmit} autoComplete="off">
          <TextInput
            label={NAME_OF_ORGANIZATION}
            placeholder={NAME_OF_ORGANIZATION}
            require="true"
            type="text"
            name="nameOfOrganization"
            onChange={(e) => handleChange(e, 'nameOfOrganization')}
            value={nameOfOrganization}
            error={tucNameOfOrganization && !!errNameOfOrganization}
            helper={tucNameOfOrganization && errNameOfOrganization}
            noIcon
            maxLength={50}
            classes="firstname-field-wrap"
          />
          <PrimaryButton type="submit">{CONFIRM}</PrimaryButton>
        </form>
      </div>
    </div>
  );

  return (
    <>
      <Layout dashboard type={type} disableScroll>
        <div className="customer-dashboard-wrap" ref={observerTarget} onScroll={onScroll}>
          <div className="customer-dashboard-container">
            <div className="profile-detail-wrap">
              <div className="left-content-wrap">
                <div className="profile-pic-wrap">
                  <img src={profileImagePath ? process.env.REACT_APP_IMAGE_URL + profileImagePath : avatar} alt="Profile Pic" className="profilePic" />
                  {/* <img src={edit} className="edit-icon-image" alt="" onClick={() => profileImageInput.current.click()} />
                <input
                  type="file"
                  ref={profileImageInput}
                  id="file-input"
                  accept="image/png, image/jpeg"
                  className="fileUploadInput"
                  onChange={(e) => handleImage(e)}
                /> */}
                </div>
                <div className="user-detail-wrap">
                  <div className="name">{`${firstName} ${lastName}`}</div>
                  <div className="email-wrap">
                    <img src={sms} className="mr-c-1" alt="" />
                    {fetchEmail}
                  </div>
                  {
                  country ? (
                    <div className="location-wrap mt-c-2">
                      <img src={location} className="mr-c-1" alt="" />
                      {country}
                    </div>
                  ) : ''
                }
                </div>
              </div>
              {
              organizationName ? (
                <div className="right-content-wrap">
                  <div className="heading">Organization</div>
                  <div className="organizationName">{organizationName}</div>
                </div>
              ) : ''
            }
            </div>
            <div className="content-wrap content-wrap-main ">
              <div className="tabs-wrap tabs-wrap-main">
                <CustomTabs
                  tabList={tabListValue}
                  selectedtabValue={tabValue}
                  OnChange={(newValue) => handleTabChange(newValue)}
                  data={queryDocument}
                />
              </div>
              <div className="cards-wrap card-wrap-main">
                {renderVerificationRequests()}
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal
        openDialog={openViewDetailDialog}
        closeDialog={() => {
          handleCloseDialog();
          setCommentText('');
        }}
        dialogContent={viewDetailContent()}
        dialogCss="verificationDetailDialog"
      />
      <Modal
        openDialog={openConfirmVerificationDetail}
        closeDialog={() => handleCloseDialog()}
        dialogContent={confirmVerificationDetailContent()}
        dialogCss="verificationDetailDialog"
      />
      {
        openOrganizationType
      && (
      <Modal
        openDialog={openOrganizationType}
        closeDialog={() => handleCloseDialog()}
        dialogContent={viewOrganizationType()}
        dialogCss="organizationDialog"
        closeButVisible
      />
      )
    }
    </>
  );
};
export default customerDashboard;
