/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './style.scss';

const ReadMore = ({ children, textSize = 80 }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const Content = ({ children: child }) => {
    const text = child;
    return (
      <div className="text">
        {isReadMore ? (
          <span className="container_text">{`${text.slice(0, textSize)} `}</span>
        ) : (
          <span className="container_text">{`${text} `}</span>
        )}
        {text.length > textSize && (
          <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? (
              'Read More'
            ) : (
              'Read Less'
            )}
          </span>
        )}
      </div>
    );
  };
  return (
    <div className="container_wrapper">
      {/* <h2> */}
      <Content>
        {children}
      </Content>
      {/* </h2> */}
    </div>
  );
};

export default ReadMore;
