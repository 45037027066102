/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../layout';
import lang from '../../../constants/lang';
import { validationPasswordSchema } from '../../../utils/validations/authValidation';
import TextInput from '../../../components/TextField';
import { PrimaryButton } from '../../../components/Button';
import './resetPassword.scss';
import { ROUTE_PATH } from '../../../constants/route';
import visible from '../../../assets/svg/visible.svg';
import slashVisible from '../../../assets/svg/slashVisible.svg';
import {
  fetchResetPassword,
  fetchResetPasswordLink,
} from '../../../store/reducers/authentication/apiThunk';
import { selectorAuthentication } from '../../../store/reducers/authentication';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import Modal from '../../../components/Modal';

const RetryReset = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
    <circle cx="30.5" cy="30.5" r="30" fill="#F5C204" fillOpacity="0.16" />
    <circle cx="30.5" cy="30.5" r="20" fill="#F5C204" fillOpacity="0.25" />
    <path d="M36.6032 38.5H24.3972C23.1682 38.5 22.2414 38.0747 21.7868 37.3028C21.3322 36.5302 21.4204 35.5301 22.0348 34.4865L28.1378 24.1186C28.7518 23.0748 29.591 22.5 30.5 22.5C31.409 22.5 32.2482 23.0748 32.8622 24.1186L38.9652 34.4865C39.5796 35.5301 39.6678 36.5302 39.2132 37.3028C38.7588 38.0747 37.832 38.5 36.6032 38.5ZM31.7456 34.851C31.7456 34.2287 31.2312 33.7242 30.5968 33.7242C29.9624 33.7242 29.448 34.2287 29.448 34.851C29.448 35.4733 29.9624 35.9778 30.5968 35.9778C31.2312 35.9778 31.7456 35.4733 31.7456 34.851ZM31.7766 28.0699C31.7766 27.431 31.2484 26.9129 30.597 26.9129C29.9456 26.9129 29.4174 27.431 29.4174 28.0699L29.696 32.1762H29.6998C29.7402 32.6297 30.124 32.9868 30.597 32.9868C31.0344 32.9868 31.3994 32.6825 31.4838 32.278C31.4906 32.2452 31.4912 32.2103 31.4942 32.1764H31.5022L31.7766 28.0699Z" fill="#F5C204" />
  </svg>
);

const ResetPassword = () => {
  const {
    RESET_PASSWORD, NEW_PASSWORD, CONFIRM_PASSWORD, BACK,
  } = lang;

  const { PASSWORD_LOGIN, FORGOT_PASSWORD } = ROUTE_PATH;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [retryDialog, setRetryDialog] = useState(false);

  const { resetPassword: { data: { email = '' } = {} } = {} } = useSelector(
    selectorAuthentication,
  );

  const verifyToken = async (token) => {
    const data = {
      token,
      body: {},
    };
    const response = await dispatch(fetchResetPasswordLink(data)).then((val) => val);
    const {
      payload: { status = '' } = {},
    } = response;
    if (!status) {
      setRetryDialog(true);
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const token = urlSearchParams.get('token');
    verifyToken(token);
  }, []);

  const submitHandler = async (values) => {
    const requestData = {
      email,
      password: values.password,
    };
    const response = await dispatch(fetchResetPassword(requestData)).then(
      (val) => val,
    );
    const { payload: { status = '', message = '' } = {} } = response;
    if (status === true) {
      ToastNotifySuccess(message);
      navigate(PASSWORD_LOGIN);
    } else {
      ToastNotifyError('Your new password should be different from previous');
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationPasswordSchema,
    onSubmit: submitHandler,
  });
  const {
    handleSubmit,
    values: { password, confirmPassword } = {},
    touched: {
      password: tucNewPassword = false,
      confirmPassword: tucConfirmPassword = false,
    } = {},
    errors: {
      password: errNewPassword = false,
      confirmPassword: errConfirmPassword = false,
    } = {},
  } = formik;
  const handleChange = (e, name) => {
    const { target } = e;
    formik.setFieldValue(name, target.value.trim());
  };

  const handleClickShowPassword = (name) => {
    switch (name) {
      case 'newPassword':
        setShowPassword(!showPassword);
        break;
      case 'confirmPassword':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const retryContent = () => (
    <div className="retry_container">
      <div className="retry_icon"><RetryReset /></div>
      <div className="retry_heading">
        Link Expired
      </div>
      <div className="retry_subheading">
        The link to reset the password has expired.
      </div>
      <div className="retry_footer">
        <PrimaryButton
          sx={{
            padding: '12px 16px', width: 'fit-content', fontSize: '12px', lineHeight: '12px',
          }}
          onClick={() => navigate(FORGOT_PASSWORD)}
        >
          Try again
        </PrimaryButton>
      </div>
    </div>
  );

  return (
    <Layout>
      <div className="reset-password-wrap">
        <div className="content-heading">{RESET_PASSWORD}</div>
        <div className="content-wrap">
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextInput
              label={NEW_PASSWORD}
              placeholder={NEW_PASSWORD}
              require="true"
              type={showPassword ? 'text' : 'password'}
              name="password"
              onChange={(e) => handleChange(e, 'password')}
              value={password}
              error={tucNewPassword && !!errNewPassword}
              helper={tucNewPassword && errNewPassword}
              noIcon
              icon={(
                <span
                  onClick={() => handleClickShowPassword('newPassword')}
                  className="icons-wrap"
                >
                  {showPassword ? (
                    <img src={visible} alt="" />
                  ) : (
                    <img src={slashVisible} alt="" />
                  )}
                </span>
              )}
            />
            <TextInput
              classes="confirmPassword-wrap"
              label={CONFIRM_PASSWORD}
              placeholder={CONFIRM_PASSWORD}
              require="true"
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirmPassword"
              onChange={(e) => handleChange(e, 'confirmPassword')}
              value={confirmPassword}
              error={tucConfirmPassword && !!errConfirmPassword}
              helper={tucConfirmPassword && errConfirmPassword}
              noIcon
              icon={(
                <span
                  onClick={() => handleClickShowPassword('confirmPassword')}
                  className="icons-wrap"
                >
                  {showConfirmPassword ? (
                    <img src={visible} alt="" />
                  ) : (
                    <img src={slashVisible} alt="" />
                  )}
                </span>
              )}
            />
            <PrimaryButton type="submit" className="loginBtn">
              {RESET_PASSWORD}
            </PrimaryButton>
          </form>
          <div
            className="back-button-wrap"
            onClick={() => navigate(ROUTE_PATH.FORGOT_PASSWORD)}
          >
            <KeyboardBackspaceRoundedIcon
              sx={{
                marginRight: '7px',
              }}
            />
            {BACK}
          </div>
        </div>
      </div>
      <Modal
        openDialog={retryDialog}
        dialogContent={retryContent()}
        closeButVisible="false"
        dialogCss="retry_modal"
      />
    </Layout>
  );
};

export default ResetPassword;
