import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import './button.scss';

export const PrimaryButton = styled(Button)({
  background: '#0B1F3E',
  borderRadius: '0px',
  padding: '18px 12px',
  width: '100%',
  textTransform: 'none',
  lineHeight: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  color: '#FFFFFF',
  boxShadow: 'none',
  fontFamily: 'Poppins, sans-serif',
  border: 'none',
  whiteSpace: 'nowrap',
  textWrap: 'wrap',
  '&:hover': {
    backgroundColor: '#283955',
  },
});

export const SecondaryButton = styled(Button)({
  background: '#FFFFFF',
  borderRadius: '0px',
  padding: '17px 12px',
  textTransform: 'none',
  lineHeight: 'normal',
  width: '100%',
  fontFamily: 'Poppins, sans-serif',
  fontWeight: '600',
  border: '1px solid #0B1F3E',
  fontSize: '16px',
  color: '#0B1F3E',
  textWrap: 'wrap',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
});

export const TrinaryButton = styled(Button)({
  background: '#999999',
  backgroundColor: '#999999',
  borderRadius: '0px',
  padding: '18px 0px',
  textTransform: 'none',
  width: '100%',
  lineHeight: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  fontFamily: 'Poppins, sans-serif',
  color: '#FFFFFF',
  boxShadow: 'none',
  border: 'none',
  whiteSpace: 'nowrap',
  textWrap: 'wrap',
  '&:hover': {
    backgroundColor: '#999999',
  },
});

export const DeleteButton = styled(Button)({
  background: '#FFFFF',
  backgroundColor: '#FFFF',
  borderRadius: '0px',
  padding: '18px 0px',
  textTransform: 'none',
  width: '100%',
  lineHeight: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  fontFamily: 'Poppins, sans-serif',
  color: '#F42121',
  boxShadow: 'none',
  border: '1px solid var(--Error, #F42121);',
  whiteSpace: 'nowrap',
  textWrap: 'wrap',
  '&:hover': {
    backgroundColor: '#FFFF',
  },
});
