/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import './authorityDetail.scss';
import lang from '../../constants/lang';
import avatar from '../../assets/svg/avatar.svg';
import { FetchAuthorityList } from '../../store/reducers/authentication/apiThunk';

const AuthorityDetails = ({ id }) => {
  const [source, setSource] = useState([]);
  const [network, setNetwork] = useState([]);
  const {
    AUTHORITY_DETAILS,
    VERIFICATION_DETAIL,
    NETWORK,
    COMMENT,
    ORGANIZATION,
  } = lang;

  const dispatch = useDispatch();

  const getVerifiers = async () => {
    const response = await dispatch(FetchAuthorityList(id)).then((val) => val);
    const {
      payload: {
        status = '',
        data: { source: sourceData = {}, network: networkData = {} },
      },
    } = response;
    if (status === true) {
      setSource(sourceData);
      setNetwork(networkData);
    }
  };

  useEffect(() => {
    getVerifiers();
  }, []);

  return (
    <div className="authority-details-container">
      <div className="authority-request-header">{AUTHORITY_DETAILS}</div>
      <div>
        {source.length > 0 ? (
          <div className="authority-request-header block-heading">
            {VERIFICATION_DETAIL}
          </div>
        ) : null}

        {source.length > 0
          && source.map(
            ({
              verifierDetails: {
                email, firstName, lastName, profileImagePath, organizationName,
              },
              verifierComments,
            }) => (
              <>
                <div className="organization-network-request">
                  <Avatar
                    src={
                      profileImagePath
                        ? process.env.REACT_APP_IMAGE_URL + profileImagePath
                        : avatar
                    }
                    sx={{ width: '42px', height: '42px' }}
                  />
                  <div className="content-wrap">
                    <div className="organization-request-name">
                      {`${firstName} ${lastName}`}
                    </div>
                    <div className="organization-request-email">{email}</div>
                    <div className="heading-display">{ORGANIZATION}</div>
                    <div className="organization-name">{organizationName}</div>
                  </div>
                </div>

                {verifierComments?.comment && verifierComments !== null ? (
                  <div className="comment-box">
                    <div className="heading-display">{COMMENT}</div>
                    <div className="comment">{verifierComments?.comment}</div>
                    <div className="heading-display">
                      {moment(verifierComments?.createdAt).format(
                        'MMM DD, YYYY',
                      )}
                    </div>
                  </div>
                ) : null}
              </>
            ),
          )}
      </div>
      {network.length > 0
        ? (
          <>
            <div className="network authority-request-header">{NETWORK}</div>
            {network.length
            && network.map(
              ({
                verifierDetails: {
                  email, firstName, lastName, profileImagePath,
                }, verifierComments,
              }) => (
                <div className="organization-network-request network-request">
                  <div className="detail-wrap">
                    <Avatar
                      src={profileImagePath
                        ? process.env.REACT_APP_IMAGE_URL + profileImagePath
                        : avatar}
                      sx={{ width: '42px', height: '42px' }}
                    />
                    <div className="content-wrap">
                      <div className="organization-request-name">
                        {`${firstName} ${lastName}`}
                      </div>
                      <div className="organization-request-email">{email}</div>
                    </div>
                  </div>
                  {verifierComments?.comment !== '' && verifierComments !== null ? (
                    <div className="comment-box">
                      <div className="heading-display">{COMMENT}</div>
                      <div className="comment">{verifierComments?.comment}</div>
                      <div className="heading-display">
                        {moment(verifierComments?.createdAt).format('MMM DD, YYYY')}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ),
            )}
          </>
        ) : null}
    </div>
  );
};

export default AuthorityDetails;
