/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Layout from '../../layout';
import { fetchLogin } from '../../store/reducers/authentication/apiThunk';
import lang from '../../constants/lang';
import TextInput from '../../components/TextField';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { validationSchema } from '../../utils/validations/authValidation';
import { ROUTE_PATH } from '../../constants/route';
import './passwordLogin.scss';
import visible from '../../assets/svg/visible.svg';
import slashVisible from '../../assets/svg/slashVisible.svg';
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import messages from '../../constants/message';
import { supportMail } from '../../constants/constants';

function PasswordLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    EMAIL_ADDRESS, SIGN_IN,
    DONT_HAVE_ACCOUNT, SIGN_UP,
    PASSWORD, SIGN_IN_OTP,
    FORGOT_PASSWORD: FORGOT_PASSWORD_CONTENT, ORGANIZATION, FACING_ISSUE_CONTACT,

  } = lang;
  const {
    DASHBOARD, SIGNUP, FORGOT_PASSWORD, LOGIN, CUSTOMER_DASHBOARD,
  } = ROUTE_PATH;
  const { MSG_SIGNED_IN } = messages;
  const [showPassword, setShowPassword] = useState(false);

  const submitHandler = async (values) => {
    const requestObj = {
      ...values,
      loginType: 2,
    };
    const response = await dispatch(fetchLogin(requestObj)).then((val) => val);
    const { payload: { status = '', data: { user: { type = '' } = {} } = {} } = {} } = response;
    if (status === true) {
      ToastNotifySuccess(MSG_SIGNED_IN);
      if (type === ORGANIZATION.toLowerCase()) {
        navigate(CUSTOMER_DASHBOARD);
      } else {
        navigate(DASHBOARD);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      email: location?.state?.email || '',
      password: '',
    },
    // eslint-disable-next-line object-shorthand
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });
  const {
    handleSubmit,
    values: { email, password } = {},
    touched: { email: tucEmail = false, password: tucPassword = false } = {},
    errors: { email: errEmail, password: errPassword } = {},
  } = formik;
  const handleChange = (e, name) => {
    const { target } = e;
    formik.setFieldValue(name, target.value.trim());
  };

  const handleSupportMail = () => {
    const mailtoLink = `mailto:${supportMail}`;
    setTimeout(() => {
      window.open(mailtoLink, '_blank');
    }, 100);
  };
  return (
    <Layout>
      <div className="login-content">
        <div className="content-heading">{SIGN_IN}</div>
        <div className="login-content-wrap">
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextInput
              label={EMAIL_ADDRESS}
              placeholder={EMAIL_ADDRESS}
              require="true"
              type="text"
              name="email"
              onChange={(e) => handleChange(e, 'email')}
              value={email}
              error={tucEmail && !!errEmail}
              helper={tucEmail && errEmail}
              noIcon
              icon={(
                <EmailOutlinedIcon
                  sx={{
                    color: '#7a869aa3',
                  }}
                />
              )}
            />
            <div className="mt-c-3">
              <TextInput
                label={PASSWORD}
                placeholder={PASSWORD}
                require="true"
                type={showPassword ? 'text' : 'password'}
                name="password"
                onChange={(e) => handleChange(e, 'password')}
                value={password}
                error={tucPassword && !!errPassword}
                helper={tucPassword && errPassword}
                icon={(
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="icons-wrap"
                  >
                    {showPassword ? (
                      <img src={visible} alt="" />
                    ) : (
                      <img src={slashVisible} alt="" />
                    )}
                  </span>
                )}
                noIcon
              />
            </div>
            <div className="forgot-password">
              <span
                className="curser-pointer"
                onClick={() => navigate(FORGOT_PASSWORD)}
              >
                {`${FORGOT_PASSWORD_CONTENT}?`}
              </span>
            </div>
            <PrimaryButton
              type="submit"
              disabled={email === '' || password === ''}
              className="loginBtn"
            >
              {SIGN_IN}
            </PrimaryButton>
          </form>
          <div className="orDivider">
            <div className="dividerLine" />
            OR
            <div className="dividerLine" />
          </div>
          <div>
            <SecondaryButton
              sx={{
                // width: '75%',
                wordBreak: 'break-word',
              }}
              onClick={() => navigate(LOGIN, {
                state: {
                  email,
                },
              })}
            >
              {SIGN_IN_OTP}
            </SecondaryButton>
          </div>
          <div className="content-footer-wrap">
            {DONT_HAVE_ACCOUNT}
            {' '}
            <span
              className="redirectionBtn sign-up"
              onClick={() => navigate(SIGNUP)}
            >
              {SIGN_UP}
            </span>
          </div>
          <div className="otp-content-footer-wrap">
            {FACING_ISSUE_CONTACT}
            <span className="supportMail" onClick={() => handleSupportMail()}>{supportMail}</span>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PasswordLogin;
