/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import lang from '../../constants/lang';
import './otpField.scss';
import { fetchResendOtp, fetchSendOtpUpadateEmail } from '../../store/reducers/authentication/apiThunk';
import { ToastNotifySuccess } from '../Toast/ToastNotify';
import messages from '../../constants/message';
import { REGEX } from '../../constants/constants';

const OtpField = (props) => {
  const {
    value = null, classes = '', handleOtpChange = () => {}, error = '', helper = '', resendOtpEmail = '', reqOtpType = '',
  } = props;
  const {
    DIDNT_RECEIVE_OTP, RESEND,
  } = lang;
  const { OTP_PATTERN } = REGEX;
  const { MSG_OTP_RESEND } = messages;

  const [otp, setOtp] = useState(value);
  const dispatch = useDispatch();
  const params = useParams();
  const handleChange = (e) => {
    const alphanumericValue = e.replace(OTP_PATTERN, '');
    if (alphanumericValue) {
      setOtp(alphanumericValue);
      handleOtpChange(alphanumericValue);
    }
  };

  const num = localStorage.getItem('counter') <= 0 ? 30 : localStorage.getItem('counter');
  const [counter, setCounter] = useState(num);

  useEffect(() => {
    localStorage.setItem('counter', counter);
    if (counter > 0) { setTimeout(() => setCounter(counter - 1), 1000); }
  }, [counter]);

  const handleResendOtp = async () => {
    const data = {
      email: resendOtpEmail,
      otpType: '',
    };
    if (params?.type === '1') {
      data.otpType = 1;
      const response = await dispatch(fetchResendOtp(data)).then((val) => val);
      const {
        payload: { status = '' } = {},
      } = response;
      if (status === true) {
        ToastNotifySuccess(MSG_OTP_RESEND);
      }
    } else if (params?.type === '2') {
      data.otpType = 2;
      const response = await dispatch(fetchResendOtp(data)).then((val) => val);
      const {
        payload: { status = '' } = {},
      } = response;
      if (status === true) {
        ToastNotifySuccess(MSG_OTP_RESEND);
      }
    } else if (reqOtpType === 4) {
      data.otpType = 4;
      await dispatch(fetchSendOtpUpadateEmail(data)).then((val) => val);
    }
    setOtp('');
    setCounter(30);
  };

  return (
    <div className={`otpField-wrap ${classes}`}>
      <OtpInput
        value={otp}
        onChange={(e) => {
          if (/^\d+$/.test(e)) { handleChange(e); }
        }}
        numInputs={6}
        renderInput={({ onChange: onChangeNum, ...InputProps }) => (
          <input
            {...InputProps}
            onChange={(e) => {
              if (/^\d+$/.test(e.target.value)) {
                onChangeNum(e);
              }
            }}
          />
        )}
      />
      <div className="textfield-error">
        {error ? (
          <div>
            <span>{helper}</span>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="content-footer-wrap otp-footer-wrap">
        {DIDNT_RECEIVE_OTP}
        {' '}
        {
          counter > 0 ? <span className="redirectionBtn" style={{ cursor: 'default', textDecoration: 'none' }}>{`${RESEND} in 00:${counter}s`}</span> : (
            <span className="redirectionBtn" onClick={() => handleResendOtp()}>{RESEND}</span>
          )
        }
      </div>
    </div>
  );
};

export default OtpField;
