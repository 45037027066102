/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './totalScore.scss';
import fileIcon from '../../assets/svg/fileIcon.svg';
import CustomizedToolTip from '../CustomizedTooltip';

const TotalScore = ({
  educationScore, educationValue, qualifications, qualificationNo,
}) => (
  <div className="total-score-wrap">
    <div className="total-score-left">
      <img src={fileIcon} className="file-image" />
      <div className="credibility-score-wrap">
        <div className="credebitlity-score">
          {educationScore}
          <CustomizedToolTip
            title="Overall Credible Score"
          />
        </div>
        <div className="credibility-score-number">{educationValue}</div>
      </div>
    </div>
    <div className="total-score-right">
      <div className="qualification-no">{qualifications}</div>
      <div className="qualification-value">{qualificationNo}</div>
    </div>
  </div>
);

export default TotalScore;
