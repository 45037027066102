import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATH } from '../constants/route';
import Login from '../pages/login';
import Signup from '../pages/signup';
import LandingPage from '../pages/landing';
import Otp from '../pages/otp';
import PasswordLogin from '../pages/passwordLogin';
import ForgotPassword from '../pages/forgotPassword';
import ResetPassword from '../pages/forgotPassword/resetPassword';
import Home from '../pages/Home';
import MyProfile from '../pages/myProfile';
import EducationPage from '../pages/education';
import EmploymentPage from '../pages/Employment';
import PrivateRoute from './PrivateRoute';
import CustomerDashboard from '../pages/customerDashboard';
import VerificationRequests from '../pages/verficationRequests';
import AboutCredible from '../pages/AboutCredible';
import SearchDetails from '../pages/search';
import SearchOrganization from '../pages/search/searchOrganization';
import SentRequests from '../pages/SentRequest';
import PublicRoute from './PublicRoute';

const routes = createBrowserRouter([
  {
    path: ROUTE_PATH.LANDING_PAGE,
    element: <PublicRoute><LandingPage /></PublicRoute>,
  },
  {
    path: ROUTE_PATH.LOGIN,
    element: <PublicRoute><Login /></PublicRoute>,
  },
  {
    path: ROUTE_PATH.SIGNUP,
    element: <PublicRoute><Signup /></PublicRoute>,
  },
  {
    path: ROUTE_PATH.OTP,
    element: <PublicRoute><Otp /></PublicRoute>,
  },
  {
    path: ROUTE_PATH.PASSWORD_LOGIN,
    element: <PublicRoute><PasswordLogin /></PublicRoute>,
  },
  {
    path: ROUTE_PATH.FORGOT_PASSWORD,
    element: <PublicRoute><ForgotPassword /></PublicRoute>,
  },
  {
    path: ROUTE_PATH.RESET_PASSWORD,
    element: <PublicRoute><ResetPassword /></PublicRoute>,
  },
  {
    path: ROUTE_PATH.DASHBOARD,
    element: <PrivateRoute><Home /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.SEARCH,
    element: <PrivateRoute><SearchDetails /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.SEARCH_ORG,
    element: <PrivateRoute><SearchOrganization /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.MY_PROFILE,
    element: <PrivateRoute><MyProfile /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.PATH_EDUCATION,
    element: <PrivateRoute><EducationPage /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.PATH_EMPLOYMENT,
    element: <PrivateRoute><EmploymentPage /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.VERIFICATION_REQUESTS,
    element: <PrivateRoute><VerificationRequests /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.SENT_REQUESTS,
    element: <PrivateRoute><SentRequests /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.CUSTOMER_DASHBOARD,
    element: <PrivateRoute><CustomerDashboard /></PrivateRoute>,
  },
  {
    path: ROUTE_PATH.ABOUT_CREDIBLE,
    element: <PrivateRoute><AboutCredible /></PrivateRoute>,
  },
]);

export default routes;
