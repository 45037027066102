/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import './style.scss';
import { useNavigate } from 'react-router-dom';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Avatar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/svg/logo.svg';
import { PrimaryButton } from '../components/Button';
import lang from '../constants/lang';
import { ROUTE_PATH } from '../constants/route';
import Modal from '../components/Modal';
import logout from '../assets/svg/logout.svg';
import avatar from '../assets/svg/avatar.svg';
import arrow from '../assets/svg/downArrow.svg';
import back from '../assets/svg/arrowRight.svg';
import rightArrow from '../assets/svg/rightArrow.svg';
import dashLine from '../assets/svg/dashLine.svg';
import {
  fetchHomeList,
  fetchLogout, fetchProfileDetails, getProfileSearchData, getUserSearchData,
} from '../store/reducers/authentication/apiThunk';
import { selectorAuthentication } from '../store/reducers/authentication';
import lens from '../assets/svg/whiteLens.svg';
import { SearchBox } from '../components/Search';
import SideDrawer from '../components/drawer';
import CustomBreadcrummbs from '../components/Breadcrumbs';

const Layout = ({ children, disableScroll = false, ...props }) => {
  const { showFooter = false, dashboard = false, type = '' } = props;
  const {
    FOOTER_CONTENT,
    COPYRIGHT, LOGOUT_HEADING,
    LOGOUT_SUBHEADING,
    VIEW_PROFILE,
    ABOUT_CC,
    LOGOUT,
    LOGOUT_BUTTON_TEXT,
    HOME,
    ORGANIZATION,
  } = lang;
  const {
    LOGIN,
    MY_PROFILE,
    ABOUT_CREDIBLE,
    LANDING_PAGE,
    DASHBOARD,
    CUSTOMER_DASHBOARD,
  } = ROUTE_PATH;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openlogoutDialog, setOpenlogoutDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [openResultBar, setOpenResultBar] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const searchResultRef = useRef();
  const [loading, setLoading] = useState('');
  const [listOpen, setListOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const [totalPages, setTotalPage] = useState();
  const [searchUser, setSearchUser] = useState({});
  const [showSearch, setShowSearch] = useState(false);

  const {
    profileDetail: {
      data: {
        user: {
          firstName = '',
          lastName = '',
          profileImagePath: fetchedProfileImagePath = '',
          type: userType = '',
        } = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);

  const handleCloseDialog = () => {
    setOpenlogoutDialog(false);
  };

  const handleLogout = async () => {
    await dispatch(fetchLogout());
    navigate(LOGIN);
  };
  const token = localStorage.getItem('auth');

  const onScroll = () => {
    if (searchResultRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = searchResultRef.current;
      if (scrollTop + clientHeight + 1 >= scrollHeight && !loading && !wasLastList) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const getSearchData = async (value) => {
    setLoading(true);
    const delay = 300;

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, delay);
    });

    const query = `?search=${encodeURIComponent(
      value.toString(),
    )}&page=${currPage}&page_size=10`;
    const response = await dispatch(getProfileSearchData(query)).then((val) => val);
    const {
      payload: {
        data = [], status = false, page: pageNo = 0, totalPages: tPage = 0,
      } = {},
    } = response;
    setLoading(false);
    if (status === true) {
      if (Number(pageNo) === Number(tPage)) {
        setWasLastList(true);
      }
      if (pageNo > 1) {
        setPrevPage(currPage);
        setTotalPage(tPage);
        setPage(pageNo);
        setSearchData([...searchData, ...data]);
      } else {
        if (data.length) {
          setSearchData(data);
        } else {
          setSearchData([]);
        }
      }
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const optimizedFn = useCallback(debounce(getSearchData), []);

  const searchHandler = (e) => {
    setWasLastList(false);
    setCurrPage(1);
    setPrevPage(0);
    setOpenResultBar(true);
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (!searchValue) {
      setOpenResultBar(false);
      setSearchData([]);
    } else {
      optimizedFn(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (currPage !== 1) {
      if (!wasLastList && prevPage !== currPage) {
        getSearchData(searchValue.trim());
      }
    }
  }, [currPage, wasLastList, prevPage]);

  useEffect(() => {
    // Close Tooltip on click outside the tooltip
    function handleClickOutside(event) {
      if (
        searchResultRef.current
        && !searchResultRef.current.contains(event.target)
      ) {
        // setSearchValue('');
        setOpenResultBar(false);
        setWasLastList(false);
        setCurrPage(1);
        setPrevPage(0);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleSearchUser = async (id) => {
    try {
      const response = await dispatch(getUserSearchData(id));
      const {
        payload: {
          data: { user = {} }, status = false,
        },
      } = response;
      if (status === true) {
        dispatch(fetchHomeList(`?userId=${id}`));
        setOpenResultBar(false);
        setWasLastList(false);
        setCurrPage(1);
        setPrevPage(0);
        if (user?.type === 'individual') {
          navigate(`/search/${id}`, { replace: true });
          props?.setCurrentSearchData(0);
        } else {
          navigate(`/search-organization/${id}`, { replace: true });
        }
      }
    } catch (error) {
      console.error('Error fetching user search data:', error);
      setOpenResultBar(false);
    }
  };

  const logoutContent = () => (
    <div className="edit-profile-wrap logout-wrap">
      <div className="profile-pic-wrap">
        <img src={logout} alt="" />
      </div>
      <div className="heading mb-c-2">{LOGOUT_HEADING}</div>
      <div className="subHeading mb-c-3">{LOGOUT_SUBHEADING}</div>
      <PrimaryButton
        sx={{
          width: 'auto',
          padding: '12px 16px',
          '@media screen and (max-width: 480px)': {
            padding: '12px',
            fontSize: '12px',
          },
        }}
        onClick={() => handleLogout()}
      >
        {LOGOUT_BUTTON_TEXT}
      </PrimaryButton>
    </div>
  );

  const renderSearchResult = () => {
    if (openResultBar) {
      return (
        <div
          className="search-wrap"
          onScroll={onScroll}
          ref={searchResultRef}
        >
          {searchData.length > 0 ? (
            searchData.map(({
              firstName: fName, lastName: lName, email, profileImagePath, _id, type: searchUserType = '', subType: searchOrgType = '',
            }) => (
              <div className="search-content-wrap curser-pointer" onClick={() => handleSearchUser(_id)}>
                <Avatar src={profileImagePath ? process.env.REACT_APP_IMAGE_URL + profileImagePath : avatar} />
                <div className="search-detail-wrap">
                  <div className="search-name" style={{ display: 'flex' }}>
                    <div>{`${fName} ${lName}`}</div>
                    {searchUserType === 'organization' && searchOrgType === 'source'
                    && <div className="search_source_tag"><span className="search_source_tag_text" style={{ marginLeft: '12px' }}>Source</span></div>}
                  </div>
                  {/* <div className="search-email">{email}</div> */}
                </div>
              </div>
            ))
          ) : (
            <div className="no-match">No matches found</div>
          )}
          {loading && (
            <div className="text-center my-c-1">
              <CircularProgress
                sx={{ color: '#0B1F3E' }}
                size={25}
                thickness={4}
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="layout-wrap">
        <div className={`layout-header ${dashboard && !showSearch ? 'layout-header-dashboard' : 'dashboard-header'}`}>
          {!showSearch
            ? (
              <div
                className="logo-wrap curser-pointer"
                onClick={() => {
                  if (token) {
                    if (userType === ORGANIZATION.toLowerCase()) {
                      navigate(CUSTOMER_DASHBOARD);
                    } else {
                      navigate(DASHBOARD);
                    }
                  } else {
                    navigate(LANDING_PAGE);
                  }
                }}
              >
                <img src={logo} alt="" className="logoImage" />
              </div>
            ) : ''}
          { dashboard
            ? (
              <>
                <div className={!showSearch ? 'search-layout' : 'search-layout-wrap'}>
                  {showSearch
                    ? <div className="back-arrow curser-pointer" onClick={() => setShowSearch(false)}><img src={back} /></div> : ''}
                  <div className={!showSearch ? 'search-box' : 'search-user'}>
                    <SearchBox onChange={searchHandler} value={searchValue} setSearchValue={setSearchValue} />
                  </div>
                  {!showSearch
                    ? <div className="search-lens" onClick={() => setShowSearch(true)}><img src={lens} /></div> : ''}
                  {renderSearchResult()}
                </div>
                {!showSearch
                  ? (
                    <div className="userProfile">
                      <Avatar src={fetchedProfileImagePath ? process.env.REACT_APP_IMAGE_URL + fetchedProfileImagePath : avatar} className="image-avatar" />
                      <div className="user-image-drawer">
                        <Avatar src={fetchedProfileImagePath ? process.env.REACT_APP_IMAGE_URL + fetchedProfileImagePath : avatar} onClick={() => setDrawer(true)} className="user-image" />
                        <SideDrawer openDrawer={drawer} toggleDrawer={() => setDrawer(false)} />
                      </div>
                      <div className="user-profile-name">
                        <div className="header-user-name">{`${firstName} ${lastName}`}</div>
                        <img src={arrow} className="curser-pointer" onClick={() => setListOpen(!listOpen)} />
                      </div>
                    </div>
                  ) : ''}
                <div className="listView">
                  {listOpen && (
                    <div className="listView-wrap curser-pointer">
                      <div className="list-item" onClick={() => navigate(MY_PROFILE)}>
                        {VIEW_PROFILE}
                        <img src={rightArrow} />
                      </div>
                      <img src={dashLine} />
                      <div
                        className="list-item"
                        onClick={() => {
                          navigate(ABOUT_CREDIBLE);
                          setListOpen(!listOpen);
                        }}
                      >
                        {ABOUT_CC}
                        <img src={rightArrow} />
                      </div>
                      <img src={dashLine} />
                      <div className="list-item curser-pointer danger" onClick={() => setOpenlogoutDialog(true)}>{LOGOUT}</div>
                    </div>
                  )}
                </div>

              </>
            ) : ''}
        </div>
        <div className={disableScroll ? 'layout-content-wrap layout-content-scroll ' : 'layout-content-wrap'}>
          {children}
        </div>
        {
        showFooter ? (
          <div className="layout-footer-wrap">
            {COPYRIGHT}
            {' '}
            &copy; 2023
            {' '}
            {FOOTER_CONTENT}
          </div>
        ) : ''
      }
      </div>
      <Modal
        openDialog={openlogoutDialog}
        closeDialog={() => handleCloseDialog()}
        dialogContent={logoutContent()}
        dialogCss="editProfileDialog logoutDialog"
      />
    </>
  );
};

export default Layout;
