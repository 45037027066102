/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import './employment.scss';
import lang from '../../constants/lang';
import { DeleteButton, PrimaryButton } from '../../components/Button';
import employ from '../../assets/svg/EmpIcon.svg';
import TextInput from '../../components/TextField';
import MyCheckbox from '../../components/chechBox';
import CustomDateRangePicker from '../../components/datePicker';
import {
  deleteEmployment,
  fetchEditEmployment,
  fetchEmployment,
  fetchEmploymentList,
} from '../../store/reducers/authentication/apiThunk';
import toastMessages from '../../constants/toastMessages';
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import { validationEmploymentSchema } from '../../utils/validations/authValidation';
import Modal from '../../components/Modal';

const AddEmployment = ({
  setIsModalOpen, employmentId, employmentEdit, edit, handleCallBack = () => {},
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [check, setCheck] = useState(employmentEdit?.isCurrentlyWorking ? employmentEdit?.isCurrentlyWorking : false);
  const dispatch = useDispatch();
  const {
    CHECKBOX_LABEL,
    SAVE_DETAILS,
    ORGANISATION,
    DESIGNATION,
    DATE_PICKER,
    OTHER_DETAILS,
    END_DATE,
    START_DATE,
    ORGANIZATION,
    ROLE,
    DELETE_EMPLOYMENT,
    EMPLOYMENT_TEXT,
    DELETE,
    CANCEL,
  } = lang;

  const {
    successToast: { TST_EMPLOYMENT_ADD, TST_EMPLOYMENT_DEL, TST_EMPLOYMENT_UPDATE },
  } = toastMessages;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const handleDelete = async () => {
    const data = `/${employmentId}`;
    await dispatch(deleteEmployment(data)).then((val) => val);
    ToastNotifySuccess(TST_EMPLOYMENT_DEL);
    setOpenDialog(false);
    setIsModalOpen(false);
    await dispatch(fetchEmploymentList());
  };

  const submitHandler = async (values) => {
    const data = {
      organization: values.organization,
      designation: values.role,
      tenureStartDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : '',
      tenureEndDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null,
      isCurrentlyWorking: values.checked,
      otherDetails: values.otherDetails ? values.otherDetails : '',
    };
    if (!edit) {
      const response = await dispatch(fetchEmployment(data)).then((val) => val);
      const {
        payload: { status = '' },
      } = response;
      if (status === true) {
        handleCallBack();
        ToastNotifySuccess(TST_EMPLOYMENT_ADD);
        setIsModalOpen(false);
      }
    } else {
      const requestData = {
        data,
        id: `/${employmentId}`,
      };
      const response = await dispatch(fetchEditEmployment(requestData)).then((val) => val);
      const {
        payload: { status },
      } = response;
      if (status === true) {
        ToastNotifySuccess(TST_EMPLOYMENT_UPDATE);
        setIsModalOpen(false);
      }
    }
    await dispatch(fetchEmploymentList());
  };
  const formik = useFormik({
    initialValues: {
      organization: employmentEdit?.organization || '',
      role: employmentEdit?.designation || '',
      checked: employmentEdit?.isCurrentlyWorking || false,
      otherDetails: employmentEdit?.otherDetails || '',
      startDate: employmentEdit?.tenureStartDate ? moment(employmentEdit?.tenureStartDate).toDate() : '',
      endDate: employmentEdit?.tenureEndDate ? moment(employmentEdit?.tenureEndDate).toDate() : '',
    },
    validationSchema: validationEmploymentSchema,
    onSubmit: submitHandler,
  });
  const handleChange = (e, name) => {
    const { target } = e;
    if (name === 'checked') {
      formik.setFieldValue(name, target.checked);
      formik.setFieldValue('endDate', '');
      setCheck(target.checked);
    } else {
      formik.setFieldValue(name, target.value);
    }
  };

  const handleDeleteEducation = () => (
    <div className="delete-dialog-wrap">
      <div className="dialog-heading">{DELETE_EMPLOYMENT}</div>
      <div className="dialog-content">{EMPLOYMENT_TEXT}</div>
      <div className="bottom-buttons">
        <DeleteButton onClick={handleDelete}>{DELETE}</DeleteButton>
        <PrimaryButton color="primary" onClick={() => setOpenDialog(false)}>
          {CANCEL}
        </PrimaryButton>
      </div>
    </div>

  );

  const {
    handleSubmit,
    values: {
      organization, role, otherDetails, checked, startDate, endDate,
    } = {},
    touched: {
      organization: tucOrganization = false,
      role: tucRole = false,
      startDate: tucStart = false,
      endDate: tucEnd = false,
    } = {},
    errors: {
      organization: errOrganization, role: errRole, startDate: errStart, endDate: errEnd,
    } = {},
  } = formik;

  const handleDateChange = (date, dateType) => {
    if (dateType === 'startDate') {
      setSelectedStartDate(date);
      formik.setFieldValue(dateType, date);
    } else if (dateType === 'endDate') {
      formik.setFieldValue(dateType, date);
    }
  };
  return (
    <div className="employment-modal">
      <div className="employment-head">
        <img src={employ} width="50px" />
      </div>
      <div className="modal-title">
        {!edit ? 'Add Employment' : 'Edit Employment'}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="employment-modal-wrap">
          <TextInput
            label={ORGANISATION}
            require="true"
            name="organization"
            value={organization}
            onChange={(e) => handleChange(e, 'organization')}
            error={tucOrganization && !!errOrganization}
            helper={tucOrganization && errOrganization}
            fullWidth
            margin="normal"
            placeholder={ORGANIZATION}
            maxLength={50}
          />
          <TextInput
            label={DESIGNATION}
            require="true"
            name="role"
            value={role}
            onChange={(e) => handleChange(e, 'role')}
            error={tucRole && !!errRole}
            helper={tucRole && errRole}
            fullWidth
            margin="normal"
            placeholder={ROLE}
            maxLength={50}
          />
          <div className="employment-checkbox-wrap">
            <MyCheckbox
              name="checked"
              checked={checked}
              onChange={(e) => handleChange(e, 'checked')}
              label={(
                <div className="employment-checkbox">
                  {CHECKBOX_LABEL}
                </div>
                    )}
            />
          </div>
          <div className={DATE_PICKER}>
            <CustomDateRangePicker
              label={START_DATE}
              name="startDate"
              require
              selectedDate={startDate}
              handleDateChange={(date) => handleDateChange(date, 'startDate')}
              error={tucStart && !!errStart}
              helper={tucStart && errStart}
              maxDate={today}
            />
            <CustomDateRangePicker
              label={END_DATE}
              name="endDate"
              require={!check}
              selectedDate={endDate}
              handleDateChange={(date) => handleDateChange(date, 'endDate')}
              disabled={check}
              minDate={selectedStartDate}
              error={tucEnd && !!errEnd}
              helper={tucEnd && errEnd}
              maxDate={today}
            />
          </div>
          <TextInput
            label={OTHER_DETAILS}
            name="otherDetails"
            value={otherDetails}
            multiline
            rows={2}
            onChange={(e) => handleChange(e, 'otherDetails')}
            fullWidth
            margin="normal"
            placeholder={OTHER_DETAILS}
            maxLength={250}
          />
          <div className="bottom-buttons">
            {edit
            && <DeleteButton onClick={() => setOpenDialog(true)}>{DELETE}</DeleteButton>}
            <PrimaryButton
              type="submit"
              variant="contained"
              color="primary"
            >
              {SAVE_DETAILS}
            </PrimaryButton>
          </div>
        </div>
      </form>
      <Modal
        openDialog={openDialog}
        closeDialog={() => setOpenDialog(false)}
        dialogContent={handleDeleteEducation()}
        dialogCss="educationDialog deleteDialog"
      />
    </div>
  );
};

export default AddEmployment;
