/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import {
  deleteEducation, fetchEditEducation, fetchEducation, fetchEducationList,
} from '../../store/reducers/authentication/apiThunk';
import toastMessages from '../../constants/toastMessages';
import { validationEducationSchema } from '../../utils/validations/authValidation';
import edu from '../../assets/svg/eduIcon.svg';
import TextInput from '../../components/TextField';
import lang from '../../constants/lang';
import { DeleteButton, PrimaryButton } from '../../components/Button';
import Modal from '../../components/Modal';

const AddEducation = ({
  edit, educationId, educationEdit, setIsModalOpen, handleCallBack = () => {},
}) => {
  const { successToast: { TST_EDUCATION_ADD, TST_EDUCATION_UPDATE } } = toastMessages;
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const {
    SAVE_DETAILS,
    COURSE_NAME,
    INSTITUTE_NAME,
    QUALIFICATIONYEAR,
    OTHER_DETAILS,
    INSTITUE_NAME,
    DEGREE_NAME,
    DELETE_EDUCATION,
    EDUCATION_TEXT,
    DELETE,
    CANCEL,
  } = lang;

  const handleDelete = async () => {
    await dispatch(deleteEducation(educationId)).then((val) => val);
    ToastNotifySuccess('Education Deleted Successfully');
    setOpenDialog(false);
    setIsModalOpen(false);
    await dispatch(fetchEducationList());
  };
  const submitHandler = async (values) => {
    const data = {
      course: values.courseName,
      institute: values.instituteName,
      yearOfQualification: values.qualificationYear,
      otherDetails: values.otherDetails,
    };
    if (!edit) {
      const requestData = {
        data,
        id: educationId,
      };
      const response = await dispatch(fetchEducation(requestData)).then((val) => val);
      const {
        payload: { status },
      } = response;
      if (status === true) {
        handleCallBack();
        ToastNotifySuccess(TST_EDUCATION_ADD);
        setIsModalOpen(false);
      }
    } else {
      const requestData = {
        data,
        id: educationId,
      };
      const response = await dispatch(fetchEditEducation(requestData)).then((val) => val);
      const {
        payload: { status },
      } = response;
      if (status === true) {
        ToastNotifySuccess(TST_EDUCATION_UPDATE);
        setIsModalOpen(false);
      }
    }
    await dispatch(fetchEducationList());
  };

  const formik = useFormik({
    initialValues: {
      courseName: educationEdit?.course || '',
      instituteName: educationEdit?.institute || '',
      qualificationYear: educationEdit?.yearOfQualification || '',
      otherDetails: educationEdit?.otherDetails || '',
    },
    validationSchema: validationEducationSchema,
    onSubmit: submitHandler,
  });

  const handleChange = (e, name) => {
    const { target } = e;
    formik.setFieldValue(name, target.value);
  };

  const handleDeleteEducation = () => (
    <div className="delete-dialog-wrap">
      <div className="dialog-heading">{DELETE_EDUCATION}</div>
      <div className="dialog-content">{EDUCATION_TEXT}</div>
      <div className="bottom-buttons">
        <DeleteButton onClick={handleDelete}>{DELETE}</DeleteButton>
        <PrimaryButton color="primary" onClick={() => setOpenDialog(false)}>
          {CANCEL}
        </PrimaryButton>
      </div>
    </div>

  );

  const {
    handleSubmit,
    values: {
      courseName, instituteName, qualificationYear, otherDetails,
    } = {},
    touched: {
      courseName: tucCourse = false,
      instituteName: tucInstitute = false,
      qualificationYear: tucQualification = false,
    } = {},
    errors: {
      courseName: errCourse,
      instituteName: errInstitute,
      qualificationYear: errQualification,
    } = {},
  } = formik;

  return (
    <div className="education-modal">
      <div className="employment-head">
        <img src={edu} width="50px" />
      </div>
      <div className="modal-title">{edit ? 'Edit Education' : 'Add Education'}</div>
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
        <div className="education-modal-wrap">
          <TextInput
            label={COURSE_NAME}
            name="courseName"
            value={courseName}
            onChange={(e) => handleChange(e, 'courseName')}
            error={tucCourse && !!errCourse}
            helper={tucCourse && errCourse}
            fullWidth
            require="true"
            margin="normal"
            placeholder={DEGREE_NAME}
            maxLength={50}
          />
          <TextInput
            label={INSTITUTE_NAME}
            name="instituteName"
            value={instituteName}
            onChange={(e) => handleChange(e, 'instituteName')}
            error={tucInstitute && !!errInstitute}
            helper={tucInstitute && errInstitute}
            fullWidth
            require="true"
            margin="normal"
            placeholder={INSTITUE_NAME}
            maxLength={50}
          />
          <TextInput
            label={QUALIFICATIONYEAR}
            name="qualificationYear"
            value={qualificationYear}
            onChange={(e) => handleChange(e, 'qualificationYear')}
            error={tucQualification && !!errQualification}
            helper={tucQualification && errQualification}
            fullWidth
            require="true"
            margin="normal"
            placeholder={QUALIFICATIONYEAR}
            maxLength={4}
            type="year"
          />
          <TextInput
            label={OTHER_DETAILS}
            name="otherDetails"
            value={otherDetails}
            onChange={(e) => handleChange(e, 'otherDetails')}
            multiline
            rows={2}
            fullWidth
            margin="normal"
            placeholder={OTHER_DETAILS}
            maxLength={250}
          />
          <div className="bottom-buttons">
            {edit
            && <DeleteButton onClick={() => setOpenDialog(true)}>{DELETE}</DeleteButton>}
            <PrimaryButton type="submit" color="primary">
              {SAVE_DETAILS}
            </PrimaryButton>
          </div>
        </div>
      </form>
      <Modal
        openDialog={openDialog}
        closeDialog={() => setOpenDialog(false)}
        dialogContent={handleDeleteEducation()}
        dialogCss="educationDialog deleteDialog"
      />
    </div>
  );
};

export default AddEducation;
