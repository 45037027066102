import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import './textField.scss';
import '../../styles/_global.scss';
import { makeStyles } from '@mui/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses } from '@mui/material';
import textIcon from '../../assets/svg/textIcon.svg';
import CustomizedToolTip from '../CustomizedTooltip';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} enterTouchDelay={0} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F4F6FA',
    color: '#2B2724',
    width: '240px',
    height: '57px',
    padding: '9px 8px 6px 13px',
    border: '1px solid var(--stroke-grey, #E2E2E2)',
    borderRadius: '6px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: 11,
  },
}));

const focusedColor = '#CDA14B';
const useStyles = makeStyles(() => ({
  root: {
    // input label when focused
    '& label.Mui-focused': {
      color: focusedColor,
    },
    // focused color for input with variant='standard'
    '& .MuiInput-underline:after': {
      borderBottomColor: focusedColor,
    },
    // focused color for input with variant='filled'
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: focusedColor,
    },
    // focused color for input with variant='outlined'
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: focusedColor,
      },
      '&:hover fieldset': {
        borderColor: focusedColor,
      },
    },
  },
  customTooltip: {
    width: '200px',
    borderRadius: '3px !important',
    backgroundColor: '#FFEC89 !important',
    color: 'black !important',
    padding: '12px 18px !important',
    fontSize: '14px !important',
  },
}));

export default function TextInput(props) {
  const classes = useStyles();
  const textIconVisible = props.type === 'text';
  return (
    <div className={props.classes} style={props.style}>
      <div
        className="input-Label_text label-space"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {props?.noIcon ? '' : textIconVisible && <img className="text-icon" src={textIcon} alt="" />}
        {props.label}
        {props.iconLabel && (
          <LightTooltip title={props.msg} placement="right">
            <span
              style={{
                marginRight: '8px',
                marginLeft: '5px',
                display: 'flex',
                alignItems: 'flexEnd',
                cursor: 'pointer',
              }}
            >
              {props.iconLabel}
            </span>
          </LightTooltip>
        )}
        {props?.require && (
          <span style={{ color: '#DE350B', fontWeight: '400' }}>*</span>
        )}
        {props?.tooltip ? (
          <span>
            <CustomizedToolTip title={props?.tooltip} />
          </span>
        ) : ''}
      </div>
      <TextField
        {...props}
        id={props.id}
        autoFocus={false}
        className={
          // eslint-disable-next-line no-nested-ternary
          props?.error
            ? `custom-field field-error ${classes.root}`
            : props.disabledText
              ? `custom-field ${classes.root} disabledText`
              : `custom-field ${classes.root}`
        }
        label=""
        size="small"
        margin="normal"
        fullWidth
        autoComplete="off"
        InputProps={{
          endAdornment: props.icon ? (
            <InputAdornment position="end">{props.icon}</InputAdornment>
          ) : (
            ''
          ),
          startAdornment: props.starticon ? (
            <InputAdornment position="start">
              <AttachMoneyIcon style={{ color: '#999999', fontSize: '20px' }} />
            </InputAdornment>
          ) : (
            ''
          ),
          autoComplete: 'new-password',
          inputProps: {
            maxLength: props?.maxLength,
            minlength: props?.minlength,
          },
        }}
      />
      <div className="textfield-error">
        {props?.error ? (
          <div>
            <span>{props?.helper}</span>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
