import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorAuthentication } from '../store/reducers/authentication';
import { ROUTE_PATH } from '../constants/route';

function PublicRoute({ children }) {
  const {
    profileDetail: {
      data: {
        user: {
          // subType = "",
          type = '',
        } = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);
  const auth = localStorage.getItem('auth');
  if (auth && type === 'organization') return <Navigate to={ROUTE_PATH?.CUSTOMER_DASHBOARD} />;
  if (auth && type === 'individual') return <Navigate to={ROUTE_PATH?.DASHBOARD} />;
  return !auth ? children : <Navigate to="/" />;
}

PublicRoute.prototype = {
  children: PropTypes.element,
};

export default PublicRoute;
