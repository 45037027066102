/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './style.scss';
import crossIcon from '../../assets/svg/circleCross.svg';

const Modal = ({
  openDialog = false,
  closeDialog,
  noHeader,
  dialogCss,
  heading,
  dialogContent,
  dialogAction,
  closeButVisible,
}) => {
  const [open, setOpen] = useState(openDialog);
  useEffect(() => {
    if (openDialog) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openDialog]);
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    closeDialog();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        disableBackdropClick="true"
        className={`${dialogCss} dialogBox`}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!noHeader && (
          <DialogTitle id="alert-dialog-title">
            <div className="dialog-heading">
              <div className="dialog-title ">{heading}</div>
              <div onClick={handleClose} className="crossIcon">
                {closeButVisible ? '' : (
                  <img
                    src={crossIcon}
                    alt=""
                    style={{ color: '#212B36', cursor: 'pointer' }}
                    onClick={handleClose}
                  />
                )}
              </div>
            </div>
          </DialogTitle>
        )}
        <DialogContent>{dialogContent}</DialogContent>
        {dialogAction}
      </Dialog>
    </div>
  );
};

export default Modal;
