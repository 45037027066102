import { createSlice } from '@reduxjs/toolkit';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import {
  fetchLogin, fetchSignup, fetchVerifyOtp,
  fetchProfileDetails, fetchResetPasswordLink,
  fetchResetCurrentPassword,
  fetchForgotPassword,
  fetchSendOtpUpadateEmail,
  fetchUpdateProfile,
  fetchEducationList,
  fetchEmploymentList,
  fetchHomeList,
  getUserSearchData,
  fetchLogout,
} from './apiThunk';

const initialStateData = {
  login: {},
  signup: {},
  profileDetail: {},
  resetPassword: {},
  educationData: {},
  employmentData: {},
  homeData: {},
  searchDetail: {},
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: { ...initialStateData },
  reducers: {
    updatelogin: (state, action) => {
      state.login = action.payload;
    },
    logout: () => initialStateData,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.login = action?.payload;
    });
    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.login = action?.payload;
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchLogout.fulfilled, () => initialStateData);
    builder.addCase(fetchLogout.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchSignup.fulfilled, (state, action) => {
      state.signup = action?.payload;
    });
    builder.addCase(fetchSignup.rejected, (state, action) => {
      state.signup = action?.payload;
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchVerifyOtp.fulfilled, () => {
    });
    builder.addCase(fetchVerifyOtp.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchProfileDetails.fulfilled, (state, action) => {
      state.profileDetail = action?.payload;
    });
    builder.addCase(fetchProfileDetails.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchResetPasswordLink.fulfilled, (state, action) => {
      state.resetPassword = action?.payload;
    });
    // builder.addCase(fetchResetPasswordLink.rejected, (state, action) => {
    //   // ToastNotifyError(action?.error?.message);
    // });
    builder.addCase(fetchForgotPassword.fulfilled, (state, action) => {
      ToastNotifySuccess(action?.payload?.message);
    });
    builder.addCase(fetchForgotPassword.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchResetCurrentPassword.fulfilled, (state, action) => {
      ToastNotifySuccess(action?.payload?.message);
    });
    builder.addCase(fetchResetCurrentPassword.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchSendOtpUpadateEmail.fulfilled, (state, action) => {
      ToastNotifySuccess(action?.payload?.message);
    });
    builder.addCase(fetchSendOtpUpadateEmail.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    // builder.addCase(fetchUpdateProfile.fulfilled, (state, action) => {
    //   ToastNotifySuccess(action?.payload?.message);
    // });
    builder.addCase(fetchUpdateProfile.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchEducationList.fulfilled, (state, action) => {
      state.educationData = action?.payload;
    });
    builder.addCase(fetchEducationList.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchEmploymentList.fulfilled, (state, action) => {
      state.employmentData = action?.payload;
    });
    builder.addCase(fetchEmploymentList.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(fetchHomeList.fulfilled, (state, action) => {
      state.homeData = action?.payload;
    });
    builder.addCase(fetchHomeList.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
    builder.addCase(getUserSearchData.fulfilled, (state, action) => {
      state.searchDetail = action?.payload;
    });
    builder.addCase(getUserSearchData.rejected, (state, action) => {
      ToastNotifyError(action?.error?.message);
    });
  },
});

const { reducer } = authenticationSlice;
const selectorAuthentication = (state) => state.authentication;
const {
  updatelogin,
} = authenticationSlice.actions;
export {
  updatelogin,
  selectorAuthentication,
};

export default reducer;
