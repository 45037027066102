/* eslint-disable arrow-body-style */
import React from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import IconButton from '@mui/material/IconButton';
import { InputLabel } from '@mui/material';
import './datePicker.scss';
import CalenderDays from '../../assets/svg/calendarDays';

const CustomDatePicker = ({
  label, selectedDate, handleDateChange, disabled, minDate, error, helper, maxDate, require,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="date-picker-wrap">
        <InputLabel htmlFor="custom-datepicker">
          {label}
          {require && (
          <span className="required-asterik">*</span>
          )}

        </InputLabel>

        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          format="dd/MM/yyyy"
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              margin="normal"
              helperText=""
              require={require}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => params.onClick()} disabled={disabled}>
                    <CalenderDays />
                  </IconButton>
                ),
              }}
              slots={{
                openPickerIcon: <CalenderDays />,
              }}
            />
          )}
        />
        <div className="textfield-error pt-c-1">
          {error ? (
            <div>
              <span>{helper}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
