const ROUTE_PATH = {
  PAGE404: '*',
  LANDING_PAGE: '/',
  LOGIN: '/login',
  OTP: '/otp/:type',
  SIGNUP: '/signup',
  PASSWORD_LOGIN: '/password-login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  DASHBOARD: '/home',
  SEARCH: '/search/:id',
  SEARCH_ORG: '/search-organization/:id',
  MY_PROFILE: '/my-profile',
  PATH_EDUCATION: '/education',
  PATH_EMPLOYMENT: '/employment',
  VERIFICATION_REQUESTS: '/verficationRequests',
  SENT_REQUESTS: '/sent-requests',
  CUSTOMER_DASHBOARD: '/customer-dashboard',
  ABOUT_CREDIBLE: '/about',
};

const API_PATH = {
  USER: '/products',
  LOGIN: '/login',
  SIGNUP: '/send-verification-code',
  VERIFY_OTP: '/verify-code',
  PROFILE_DETAILS: '/profile',
  FORGOT_PASSWORD: '/reset-password-link',
  EDUCATION: '/education/',
  EMPLOYMENT: '/employment',
  VERIFY_PASSWORD_LINK: '/verify-reset-password-link',
  RESET_PASSWORD: '/reset-password',
  RESEND_OTP: '/resend-otp',
  UPDATE_PROFILE: '/update-profile',
  RESET_CURRENT_PASSWORD: '/reset-current-password',
  LOGOUT: '/logout',
  HOME: '/home-data',
  SEND_OTP_UPDATE_EMAIL: '/send-otp-update-email',
  VERIFY_OTP_UPDATE_EMAIL: '/verify-otp-update-email',
  SEARCH_DATA: '/search-user',
  SEARCH_USER_DETAIL: '/search-user-details/',
  VERIFICATION_REQUEST: '/self-verification-requests',
  SENT_REQUEST: '/verification-requests',
  SEND_VERIFICATION_REQUEST: 'send-verification-request',
  PROFILE_VIEWS: '/profile-views',
  SEARCH_USER_DETAILS: '/search-user-details',
  VERIFICATION_REQUESTS_LISTS: '/verification-requests',
  GET_VERIFIERS: '/verifiers/',
  CONFIRM_VERIFICATION: '/approve-verification-request/',
  ADD_COMMENT: '/comment-on-verification-request',
  GET_S3_URL: '/image-upload-url',
  ADD_SUBTYPE: '/add-subtype',
  VIEWED_PROFILE_LISTS: '/visited-profiles',
  DELETE_PROFILE_IMAGE: '/profile-picture',
};

export { ROUTE_PATH, API_PATH };
