/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../layout';
import CustomBreadcrummbs from '../../components/Breadcrumbs';
import sms from '../../assets/svg/sms.svg';
import user from '../../assets/svg/user.svg';
import avatar from '../../assets/svg/avatar.svg';
import edit from '../../assets/svg/edit.svg';
import locate from '../../assets/svg/Location.svg';
import editProfileUser from '../../assets/svg/editProfileUser.svg';
import changePasswordKeyIcon from '../../assets/svg/changePasswordKeyIcon.svg';
import { DeleteButton, PrimaryButton, SecondaryButton } from '../../components/Button';
import './myProfile.scss';
import lang from '../../constants/lang';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextField';
import SingleSelect from '../../components/SingleSelect';
import {
  validationEditProfileSchema, validationChangePasswordSchema, validationEmailSchema, validateFileType, validationEditOrganizationProfileSchema,
  validateFileSize,
} from '../../utils/validations/authValidation';
import visible from '../../assets/svg/visible.svg';
import slashVisible from '../../assets/svg/slashVisible.svg';
import OtpField from '../../components/Otp';
import { selectorAuthentication } from '../../store/reducers/authentication';
import { ROUTE_PATH } from '../../constants/route';
import {
  deleteProfileImage,
  fetchForgotPassword,
  fetchProfileDetails,
  fetchResetCurrentPassword,
  fetchSendOtpUpadateEmail,
  fetchUpdateProfile,
  fetchVerifyOtpUpdateEmail,
  getUserSearchData,
} from '../../store/reducers/authentication/apiThunk';
import {
  ToastNotifyError,
  ToastNotifySuccess,
} from '../../components/Toast/ToastNotify';
import messages from '../../constants/message';
import MyRadioGroup from '../../components/radioButton';
import CountryLists from '../../constants/countryList';

const returnOptionVal = (val) => {
  switch (val) {
    case 'option1':
      return 'source';
    case 'option2':
      return 'client';
    case 'option3':
      return 'both';
    default:
      return 'source';
  }
};

const returnOptionData = (val) => {
  switch (val) {
    case 'source':
      return 'option1';
    case 'client':
      return 'option2';
    case 'both':
      return 'option3';
    default:
      return 'option1';
  }
};

const MyProfile = () => {
  const {
    EDIT_PROFILE,
    CHANGE_PASSWORD,
    HOME,
    MY_PROFILE,
    FIRST_NAME,
    LAST_NAME,
    EMAIL_ADDRESS,
    COUNTRY,
    UPDATE_DETAILS,
    CURRENT_PASSWORD,
    CONFIRM_PASSWORD,
    NEW_PASSWORD,
    CHANGE_PASSWORD_SUBHEADING,
    FORGOT_PASSWORD,
    ENTER_OTP_RECEIVED,
    VERIFY_EMAIL,
    VERIFY,
    ENTER_EMAIL_TO_RESET,
    GET_LINK,
    NAME_OF_ORGANIZATION,
  } = lang;

  const { MSG_OTP_VERIFIED, MSG_FILE_FORMATE, MSG_FILE_SIZE } = messages;

  const { DASHBOARD, CUSTOMER_DASHBOARD } = ROUTE_PATH;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileImageInput = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const paramId = queryParams.get('id');

  const {
    profileDetail: {
      data: {
        user: {
          firstName: fetchedFirstName = '',
          lastName: fetchedLastName = '',
          email: fetchedEmail = '',
          country: fetchedCountry = '',
          profileImagePath = '',
          subType = '',
          type = '',
          organizationName: fetchedOrganizationName = '',
        } = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);
  const profileInitialData = {
    firstName: fetchedFirstName,
    lastName: fetchedLastName,
    email: fetchedEmail,
    country: fetchedCountry,
    organizationName: fetchedOrganizationName,
  };
  const [file, setFile] = useState(null);
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [openVerifyEmail, setOpenVerifyEmail] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [editedDetails, setEditedDetails] = useState('');
  const [organizationTypeVal, setOrganizationTypeVal] = useState(
    returnOptionData(subType),
  );
  const [searchUser, setSearchUser] = useState({});
  const [toggleEditProfileImage, setToggleEditProfileImage] = useState(false);

  const handleShowPassoword = (name) => {
    switch (name) {
      case 'currentPassword':
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case 'newPassword':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirmPassword':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const breadcrumbsList = [
    <div
      onClick={() => {
        navigate(type === 'organization' ? CUSTOMER_DASHBOARD : DASHBOARD);
      }}
      style={{ cursor: 'pointer' }}
    >
      {HOME}
    </div>,
    <Typography key="2" color="text.primary">
      {MY_PROFILE}
    </Typography>,
  ];

  const submitHandler = async ({ organizationName, ...values }) => {
    if (type === lang?.ORGANIZATION.toLowerCase()) {
      values.subType = returnOptionVal(organizationTypeVal);
      values.organizationName = organizationName;
    }
    const { email } = values;
    if (fetchedEmail !== email) {
      const otpRequestData = {
        email,
        otpType: 4,
      };
      const otpResponse = await dispatch(
        fetchSendOtpUpadateEmail(otpRequestData),
      ).then((val) => val);
      const { payload: { status = '' } = {} } = otpResponse;
      if (status === true) {
        setOpenVerifyEmail(true);
        setEditedDetails(values);
      }
    } else {
      const profileData = await dispatch(fetchUpdateProfile(values)).then((val) => val);
      ToastNotifySuccess(profileData?.payload?.message);
      await dispatch(fetchProfileDetails(values)).then((val) => val);
      setOpenEditProfile(false);
      setEditedDetails(values);
    }
  };

  const changePasswordSubmitHandler = async (values, { resetForm }) => {
    const { currentPassword = '', newPassword = '' } = values;
    const data = {
      currentPassword,
      newPassword,
    };
    await dispatch(fetchResetCurrentPassword(data));
    resetForm();
    setOpenChangePassword(false);
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  };

  const forgotPasswordSubmitHandler = async (values) => {
    const data = {
      email: values.forgotPasswordEmail,
    };
    const response = await dispatch(fetchForgotPassword(values)).then(
      (val) => val,
    );
    const { payload: { status = '' } = {} } = response;
    if (status === true) {
      setOpenForgotPassword(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (paramId) {
          const response = await dispatch(getUserSearchData(paramId));
          const {
            payload: {
              data: { user: userDetail = {} },
              status = false,
            },
          } = response;

          if (status === true) {
            setSearchUser(userDetail);
          }
        }
      } catch (error) {
        console.error('Error fetching user search data:', error);
      }
    };

    fetchData();
  }, [paramId]);

  const formik = useFormik({
    initialValues: profileInitialData,
    validationSchema: type === lang?.ORGANIZATION.toLowerCase() ? validationEditOrganizationProfileSchema : validationEditProfileSchema,
    onSubmit: submitHandler,
  });

  const changePasswordFormik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationChangePasswordSchema,
    onSubmit: changePasswordSubmitHandler,
  });

  const forgotPasswordFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationEmailSchema,
    onSubmit: forgotPasswordSubmitHandler,
  });

  const handleCloseDialog = (name) => {
    switch (name) {
      case 'editProfile':
        setOpenEditProfile(false);
        // formik.resetForm();
        formik.setValues(profileInitialData);
        setOrganizationTypeVal(
          returnOptionData(subType),
        );
        break;
      case 'changePassword':
        changePasswordFormik.resetForm();
        setOpenChangePassword(false);
        break;
      case 'verifyEmail':
        setOtpValue('');
        setOpenVerifyEmail(false);
        break;
      case 'forgotPassword':
        forgotPasswordFormik.resetForm();
        setOpenForgotPassword(false);
        break;
      default:
        break;
    }
  };

  const handleForgotPasswordClick = () => {
    handleCloseDialog('changePassword');
    setOpenForgotPassword(true);
  };

  const {
    handleSubmit,
    values: {
      firstName, lastName, email, country, organizationName,
    } = {},
    touched: {
      firstName: tucFirstName = false, lastName: tucLastName = false, email: tucEmail = false, country: tucCountry = false, organizationName: tucOrg = false,
    } = {},
    errors: {
      firstName: errFirstName, lastName: errLastName, email: errEmail, country: errCountry, organizationName: errOrg,
    } = {},
  } = formik;

  const {
    handleSubmit: changePasswordHandleSubmit,
    values: { currentPassword, newPassword, confirmPassword } = {},
    touched: {
      currentPassword: tucCurrentPassword = false,
      newPassword: tucNewPassword = false,
      confirmPassword: tucConfirmPassword = false,
    } = {},
    errors: {
      currentPassword: errCurrentPassword,
      newPassword: errNewPassword,
      confirmPassword: errConfirmPassword,
    } = {},
  } = changePasswordFormik;

  const {
    handleSubmit: forgotPasswordHandleSubmit,
    values: { email: forgotPasswordEmail } = {},
    touched: { email: tucForgotPasswordEmail = false } = {},
    errors: { email: errForgotPasswordEmail } = {},
  } = forgotPasswordFormik;

  const handleChange = (e, fieldName, name) => {
    const { target } = e;
    switch (name) {
      case 'editProfile':
        if (fieldName === 'organizationName' || fieldName === 'firstName' || fieldName === 'lastName') {
          formik.setFieldValue(fieldName, target.value);
        } else {
          formik.setFieldValue(fieldName, target.value.trim());
        }
        break;
      case 'changePassword':
        changePasswordFormik.setFieldValue(fieldName, target.value.trim());
        break;
      case 'forgotPassword':
        forgotPasswordFormik.setFieldValue('email', target.value.trim());
        break;
      default:
        break;
    }
  };

  const customOtpChange = (e) => {
    setOtpValue(e);
  };

  const handleSubmitOtp = async () => {
    const requestObj = {
      email,
      otp: otpValue,
      otpType: 4,
    };
    const response = await dispatch(fetchVerifyOtpUpdateEmail(requestObj)).then(
      (val) => val,
    );
    const { payload: { status = '' } = {} } = response;
    if (status === true) {
      ToastNotifySuccess(MSG_OTP_VERIFIED);
      setOpenVerifyEmail(false);
      const responseProfile = await dispatch(fetchUpdateProfile(editedDetails)).then((val) => val);
      const { payload: { status: statusProfile = '' } = {} } = responseProfile;
      if (statusProfile === true) {
        await dispatch(fetchProfileDetails()).then((val) => val);
        setOpenEditProfile(false);
        setOtpValue('');
        setOrganizationTypeVal(
          returnOptionData(subType),
        );
      } else {
        setOpenEditProfile(false);
        formik.resetForm();
        setOtpValue('');
        setOrganizationTypeVal(
          returnOptionData(subType),
        );
      }
    }
  };

  const editProfileContent = () => (
    <div className="edit-profile-wrap">
      <div className="profile-pic-wrap">
        <img src={editProfileUser} alt="" />
      </div>
      <div className="heading mb-c-3">{EDIT_PROFILE}</div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="name-wrap mb-c-3">
          <TextInput
            label={FIRST_NAME}
            placeholder={FIRST_NAME}
            require="true"
            type="text"
            name="firstName"
            onChange={(e) => handleChange(e, 'firstName', 'editProfile')}
            value={firstName}
            error={tucFirstName && !!errFirstName}
            helper={tucFirstName && errFirstName}
            noIcon
            disabled={firstName}
            maxLength={50}
            classes="firstname-field-wrap"
          />
          <TextInput
            label={LAST_NAME}
            placeholder={LAST_NAME}
            require="true"
            type="text"
            name="lastName"
            onChange={(e) => handleChange(e, 'lastName', 'editProfile')}
            value={lastName}
            error={tucLastName && !!errLastName}
            helper={tucLastName && errLastName}
            noIcon
            disabled={lastName}
            maxLength={50}
            classes="lastname-field-wrap"
          />
        </div>
        <TextInput
          label={EMAIL_ADDRESS}
          placeholder={EMAIL_ADDRESS}
          require="true"
          type="text"
          name="email"
          onChange={(e) => handleChange(e, 'email', 'editProfile')}
          value={email}
          error={tucEmail && !!errEmail}
          helper={tucEmail && errEmail}
          noIcon
          classes="mb-c-3"
          icon={(
            <EmailOutlinedIcon
              sx={{
                color: '#7a869aa3',
              }}
            />
          )}
        />
        {
          type === lang?.ORGANIZATION.toLowerCase()
          && (
          <>
            <div className="userType-wrap mb-c-3">
              <MyRadioGroup option1="Source" option2="Client" option3="Both" value={organizationTypeVal} onChange={(e) => setOrganizationTypeVal(e.target.value)} />
            </div>
            <div>
              <TextInput
                label={NAME_OF_ORGANIZATION}
                placeholder={NAME_OF_ORGANIZATION}
                require="true"
                type="text"
                name="organizationName"
                onChange={(e) => handleChange(e, 'organizationName', 'editProfile')}
                value={organizationName}
                error={tucOrg && !!errOrg}
                helper={tucOrg && errOrg}
                // tooltip="Organization Name is required"
                maxLength={50}
                noIcon
                classes="firstname-field-wrap"
              />
            </div>
          </>
          )
        }
        <SingleSelect
          option={CountryLists.sort() || []}
          value={country}
          onChange={(e) => {
            formik.setFieldValue(
              'country',
              JSON.parse(JSON.stringify(e.target.value)),
            );
          }}
          native={false}
          name="country"
          classes="country-field-wrap mb-c-3"
          renderValue={(selected) => {
            if (
              Object.keys(selected)?.length === 0
              || Object.keys(selected)?.length === undefined
            ) {
              return <span className="placeholder">{COUNTRY}</span>;
            }
            return selected;
          }}
          labeltext={COUNTRY}
          color="primary"
          variant="outlined"
          placeholder={COUNTRY}
          error={tucCountry && !!errCountry}
          helper={tucCountry && errCountry}
          require
        />
        <PrimaryButton type="submit">{UPDATE_DETAILS}</PrimaryButton>
      </form>
    </div>
  );

  const changePasswordContent = () => (
    <div className="edit-profile-wrap">
      <div className="profile-pic-wrap">
        <img src={changePasswordKeyIcon} alt="" />
      </div>
      <div className="heading mb-c-2">{CHANGE_PASSWORD}</div>
      <div className="subHeading mb-c-3">{CHANGE_PASSWORD_SUBHEADING}</div>
      <form onSubmit={changePasswordHandleSubmit} autoComplete="off">
        <TextInput
          label={CURRENT_PASSWORD}
          placeholder={CURRENT_PASSWORD}
          require="true"
          type={showCurrentPassword ? 'text' : 'password'}
          name="currentPassword"
          onChange={(e) => handleChange(e, 'currentPassword', 'changePassword')}
          value={currentPassword}
          error={tucCurrentPassword && !!errCurrentPassword}
          helper={tucCurrentPassword && errCurrentPassword}
          noIcon
          icon={(
            <span
              onClick={() => handleShowPassoword('currentPassword')}
              className="icons-wrap"
            >
              {showCurrentPassword ? (
                <img src={visible} alt="" />
              ) : (
                <img src={slashVisible} alt="" />
              )}
            </span>
          )}
          classes="mb-c-3"
        />
        <TextInput
          label={NEW_PASSWORD}
          placeholder={NEW_PASSWORD}
          require="true"
          type={showNewPassword ? 'text' : 'password'}
          name="newPassword"
          onChange={(e) => handleChange(e, 'newPassword', 'changePassword')}
          value={newPassword}
          error={tucNewPassword && !!errNewPassword}
          helper={tucNewPassword && errNewPassword}
          noIcon
          icon={(
            <span
              onClick={() => handleShowPassoword('newPassword')}
              className="icons-wrap"
            >
              {showNewPassword ? (
                <img src={visible} alt="" />
              ) : (
                <img src={slashVisible} alt="" />
              )}
            </span>
          )}
          classes="mb-c-3"
        />
        <TextInput
          label={CONFIRM_PASSWORD}
          placeholder={CONFIRM_PASSWORD}
          require="true"
          type={showConfirmPassword ? 'text' : 'password'}
          name="confirmPassword"
          onChange={(e) => handleChange(e, 'confirmPassword', 'changePassword')}
          value={confirmPassword}
          error={tucConfirmPassword && !!errConfirmPassword}
          helper={tucConfirmPassword && errConfirmPassword}
          noIcon
          icon={(
            <span
              onClick={() => handleShowPassoword('confirmPassword')}
              className="icons-wrap"
            >
              {showConfirmPassword ? (
                <img src={visible} alt="" />
              ) : (
                <img src={slashVisible} alt="" />
              )}
            </span>
          )}
          classes="mb-c-3"
        />
        <PrimaryButton type="submit" className="mb-c-2">
          {CHANGE_PASSWORD}
        </PrimaryButton>
      </form>
    </div>
  );

  const VerifyEmailContent = () => (
    <div className="edit-profile-wrap verify-email-wrap">
      <div className="heading mb-c-3">{VERIFY_EMAIL}</div>
      <div className="subHeading">{ENTER_OTP_RECEIVED}</div>
      <div className="email-wrap mb-c-3">{email}</div>
      <OtpField
        value={otpValue}
        classes="verifyOtp"
        handleOtpChange={(e) => customOtpChange(e)}
        resendOtpEmail={email}
        reqOtpType={4}
      />
      <PrimaryButton
        className="mb-c-2"
        disabled={otpValue === ''}
        onClick={() => handleSubmitOtp()}
      >
        {VERIFY}
      </PrimaryButton>
    </div>
  );

  const forgotPasswordContent = () => (
    <div className="edit-profile-wrap verify-email-wrap">
      <div className="heading mb-c-1">{FORGOT_PASSWORD}</div>
      <div className="subHeading mb-c-3">{ENTER_EMAIL_TO_RESET}</div>
      <form onSubmit={forgotPasswordHandleSubmit} autoComplete="off">
        <TextInput
          label={EMAIL_ADDRESS}
          placeholder={EMAIL_ADDRESS}
          require="true"
          type="text"
          name="email"
          onChange={(e) => handleChange(e, 'forgotPasswordEmail', 'forgotPassword')}
          value={forgotPasswordEmail}
          error={tucForgotPasswordEmail && !!errForgotPasswordEmail}
          helper={tucForgotPasswordEmail && errForgotPasswordEmail}
          noIcon
          classes="mb-c-3"
          icon={(
            <EmailOutlinedIcon
              sx={{
                color: '#7a869aa3',
              }}
            />
          )}
        />
        <PrimaryButton type="submit" className="mb-c-2">
          {GET_LINK}
        </PrimaryButton>
      </form>
    </div>
  );

  const handleImage = async (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      try {
        const fileObjectURL = URL.createObjectURL(selectedFile);
        setFile(fileObjectURL);
        const updatedData = {
          profileImagePath: profileImagePath || '',
          uploadFile: e.target.files[0],
          extension: e.target.files[0].name.split('.').pop(),
        };
        if (
          updatedData?.extension
          && !validateFileType(updatedData?.extension)
        ) {
          ToastNotifyError(MSG_FILE_FORMATE);
        } else if (
          updatedData?.uploadFile
          && validateFileSize(updatedData?.uploadFile)
        ) {
          ToastNotifyError(MSG_FILE_SIZE);
        } else {
          const response = await dispatch(fetchUpdateProfile(updatedData)).then(
            (val) => val,
          );
          const { payload: { status } = {} } = response;
          if (status) {
            ToastNotifySuccess(profileImagePath ? 'Profile picture updated successfully' : 'Profile picture uploaded successfully');
            setToggleEditProfileImage(false);
            await dispatch(fetchProfileDetails());
          }
        }
      } catch (error) {
        console.error('Error creating object URL:', error);
      }
    }
  };

  const handleDeleteProfileImage = async () => {
    const response = await dispatch(deleteProfileImage()).then(
      (val) => val,
    );
    const { payload: { status } = {} } = response;
    if (status) {
      ToastNotifySuccess('Profile picture removed successfully');
      setToggleEditProfileImage(false);
      await dispatch(fetchProfileDetails());
    }
  };

  const editProfileImageContent = () => (
    <div className="profile-pic-edit-modal-content">
      <div className="profile-pic-edit-modal">
        <img
          src={
                  profileImagePath && !paramId
                    ? process.env.REACT_APP_IMAGE_URL + profileImagePath
                    : paramId ? process.env.REACT_APP_IMAGE_URL + searchUser?.profileImagePath : avatar
                }
          alt=""
          className="profile-image"
        />
        <div className="edit-button-wrap">
          <input
            type="file"
            id="file-input"
            ref={profileImageInput}
            accept="image/png, image/jpeg"
            className="edit-fileUploadInput"
            onInput={(e) => handleImage(e)}
            onChange={(e) => { e.target.value = ''; }}
          />
        </div>
      </div>
      <div className="photo-edit-button">
        <DeleteButton
          sx={{
            padding: '14.5px 0px',
            minWidth: '210px',
            width: 'fit-content',
            cursor: profileImagePath ? 'pointer' : 'not-allowed !important',
            pointerEvents: 'visible !important',
          }}
          onClick={handleDeleteProfileImage}
          disabled={!profileImagePath}

        >
          Delete
        </DeleteButton>
        <PrimaryButton
          sx={{
            padding: '14.5px 0px',
            minWidth: '210px',
            width: 'fit-content',
            marginLeft: '28px',
            '@media screen and (max-width: 630px)': {
              marginTop: '28px',
              marginLeft: '0px',
            },

          }}
          onClick={() => profileImageInput.current.click()}
        >
          {profileImagePath ? 'Update Profile Picture' : 'Add Profile Picture'}
        </PrimaryButton>
      </div>
    </div>
  );

  return (
    <Layout dashboard>
      <div className="myProfile-wrap">
        <div className="myProfile-header">
          <CustomBreadcrummbs breadcrumbs={breadcrumbsList} />
        </div>
        <div className="mobile-header">{MY_PROFILE}</div>
        <div className="myProfile-content-wrap">
          {
            fetchedOrganizationName
          && !paramId ? (
            <div className="org_container">
              <div className="org_heading">Organization</div>
              <div className="org_name">{fetchedOrganizationName}</div>
            </div>
              ) : searchUser?.organizationName
          && paramId ? (
            <div className="org_container">
              <div className="org_heading">Organization</div>
              <div className="org_name">{searchUser?.organizationName}</div>
            </div>
                ) : null
          }
          <div className="details-wrap">
            <div className="profile-pic-wrap">
              <img
                src={
                  profileImagePath && !paramId
                    ? process.env.REACT_APP_IMAGE_URL + profileImagePath
                    : paramId ? process.env.REACT_APP_IMAGE_URL + searchUser?.profileImagePath : avatar
                }
                alt=""
                className="profile-image"
              />
              <div className="edit-button-wrap">
                <img
                  src={edit}
                  className="edit-icon-image"
                  alt=""
                  onClick={() => setToggleEditProfileImage(true)}
                />
              </div>
            </div>
            <div className="left-content-wrap">
              <div className="username-wrap">
                {paramId ? searchUser?.firstName : fetchedFirstName}
                {' '}
                {paramId ? searchUser?.LastName : fetchedLastName}
              </div>
              <div className="email-wrap">
                <img src={sms} className="mr-c-1" alt="" />
                {paramId ? searchUser?.email : fetchedEmail}
              </div>
              {fetchedCountry && !paramId ? (
                <div className="location-wrap">
                  <img src={locate} className="mr-c-1" alt="" />
                  {fetchedCountry}
                </div>
              ) : searchUser.country && paramId ? (
                <div className="location-wrap">
                  <img src={locate} className="mr-c-1" alt="" />
                  {searchUser.country}
                </div>
              ) : ''}
              {fetchedOrganizationName && (

                <div className="org_res_container">
                  <div className="org_res_heading">
                    Organization
                  </div>
                  <div className="org_res_name">
                    {fetchedOrganizationName}
                  </div>
                </div>
              ) }
            </div>
            {!paramId
              ? (
                <div className="right-content-wrap">
                  <PrimaryButton
                    sx={{
                      padding: '14.5px 0px',
                      minWidth: '166px',
                    }}
                    onClick={() => setOpenEditProfile(true)}
                  >
                    {EDIT_PROFILE}
                  </PrimaryButton>
                  <SecondaryButton
                    sx={{
                      padding: '13.5px 0px',
                      minWidth: '195px',
                    }}
                    onClick={() => setOpenChangePassword(true)}
                  >
                    {CHANGE_PASSWORD}
                  </SecondaryButton>
                </div>
              ) : null}
          </div>
        </div>
      </div>
      <Modal
        openDialog={openEditProfile}
        closeDialog={() => handleCloseDialog('editProfile')}
        dialogContent={editProfileContent()}
        dialogCss="editProfileDialog"
      />
      <Modal
        openDialog={openChangePassword}
        closeDialog={() => handleCloseDialog('changePassword')}
        dialogContent={changePasswordContent()}
        dialogCss="editProfileDialog changePasswordDialog"
      />
      <Modal
        openDialog={openVerifyEmail}
        closeDialog={() => handleCloseDialog('verifyEmail')}
        dialogContent={VerifyEmailContent()}
        dialogCss="editProfileDialog changePasswordDialog verifyEmailDialog"
        closeButVisible
      />
      <Modal
        openDialog={openForgotPassword}
        closeDialog={() => handleCloseDialog('forgotPassword')}
        dialogContent={forgotPasswordContent()}
        dialogCss="editProfileDialog changePasswordDialog forgotPasswordDialog"
      />

      <Modal
        openDialog={toggleEditProfileImage}
        closeDialog={() => setToggleEditProfileImage(false)}
        dialogContent={editProfileImageContent()}
        dialogCss="editProfileImageDialog"
        heading="Profile Photo"
      />
    </Layout>
  );
};

export default MyProfile;
