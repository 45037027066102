const lang = {
  EMAIL_ADDRESS: 'Email Address',
  PASSWORD: 'Password',
  SIGN_IN: 'Sign In',
  LOGOUT_FIELD: 'Logout',
  SAVE: 'Save',
  SAVE_CHANGES: 'Save Changes',
  DELETE: 'Delete',
  MAX_CHAR_LIMIT_IS: 'Max. characters limit is',
  CONTACT_INFO: 'Contact info',
  YES: 'Yes',
  NO: 'No',
  CANCEL: 'Cancel',
  SURE_LOGOUT: 'Are sure you want to Logout?',
  NAME: 'Name',
  ADDRESS: 'Address',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  CUSTOMER_FIELD: 'Customer',
  LOGIN_CREDIBLE: 'Log Into Credible Circle',
  LOGIN_WITH_OTP: 'Sign In with One-Time Password (OTP)',
  LOGIN_WITH_PASSWORD: 'Sign In with Password',
  DONT_HAVE_ACCOUNT: 'Don’t have an account?',
  SIGN_UP: 'Sign Up',
  SIGNUP_HEADING: 'Sign Up',
  SIGNUP_SUB_HEADING:
    'Please enter the following details to create your account.',
  CONFIRM_PASSWORD: 'Confirm Password',
  SIGNUP_RADIO: 'Signing up as an:',
  SIGNUP: 'Signup',
  LOGIN: 'Login',
  OTP_VERIFICATION: 'OTP Verification',
  ENTER_OTP_RECEIVED:
    'Enter the One-Time Password (OTP) received in your email',
  DIDNT_RECEIVE_OTP: 'Didn’t receive the OTP?',
  FOOTER_CONTENT: 'Credible Circle. All Rights Reserved.',
  RESEND: 'Resend',
  VERIFY: 'Verify',
  ABOUT_DESCRIPTION:
    'At Credible Circle, we are dedicated to providing you with a reliable and comprehensive background verification solution. Our mission is to empower individuals and organizations with the knowledge they need to make informed decisions.At Credible Circle, we are dedicated to providing you with a reliable and comprehensive background verification solution. Our mission is to empower individuals and organizations with the knowledge they need to make informed decisions.',
  ABOUT_US: 'About Us',
  CONTACT_US: 'Contact Us',
  WE_VALUE_UR_FEEDBACK: 'We value your feedback',
  CONTACT_EMAIL: 'contactus@crediblecircle.com',
  PRIVACY_POLICY_TERMS_CONDITION: 'Privacy Policy/Terms of service',
  PRIVACY_DESCRIPTION:
    'At Credible Circle, we are dedicated to providing you with a reliable and comprehensive background verification solution. Our mission is to empower individuals and organizations with the knowledge they need to make informed decisions.At Credible Circle, we are dedicated to providing you with a reliable and comprehensive background verification solution. Our mission is to empower individuals and organizations with the knowledge they need to make informed decisions.',
  COPYRIGHT: 'Copyright',
  AGREE_TO: 'I agree to',
  TERMS_CONDITION: 'Terms and Privacy Policy',
  PRIVACY: 'Privacy Policy',
  HAVE_ACCOUNT: 'Already have an account?',
  INDIVIDUAL: 'Individual',
  ORGANISATION: 'Organization',
  SIGN_IN_OTP: 'Sign In with One-Time Password (OTP)',
  FORGOT_PASSWORD: 'Forgot Password',
  ENTER_EMAIL_TO_RESET: 'Enter your registered email to reset your password.',
  GET_LINK: 'Get link',
  BACK: 'Back',
  RESET_PASSWORD: 'Reset Password',
  NO_DATA: 'No Data Found',
  NO_DATA_CONTENT:
    "You haven't added any education & employment details yet.",
  NO_DATA_CONTENT_EMP:
    "You haven't added any employment details yet.",
  NO_DATA_CONTENT_EDU:
    "You haven't added any education details yet.",
  EDUCATION: 'Add Education',
  EMPLOYMENT: 'Add Employment',
  VIEW_NO: 'Who viewed your profile',
  VERIFICATION_REQUEST: 'Requests Received',
  EDUCATION_NAME: 'Education',
  EMPLOYMENT_NAME: 'Employment',
  NEW_PASSWORD: 'New Password',
  HOME: 'Home',
  MY_PROFILE: 'My Profile',
  EDIT_PROFILE: 'Edit Profile',
  CHANGE_PASSWORD: 'Change Password',
  COUNTRY: 'Country of Residence',
  UPDATE_DETAILS: 'Update Details',
  CURRENT_PASSWORD: 'Current Password',
  CHANGE_PASSWORD_SUBHEADING:
    'Your new password must be different from the previous password.',
  VERIFY_EMAIL: 'Verify Email',
  TERMS: 'Terms and Privacy Policy',
  PRIVACY_H: 'Privacy Policy',
  ACCEPTANCE: '1. Acceptance of Terms',
  ACCEPTANCE_MSG:
    'By using the App, you agree to be bound by these Terms, whether or not you have registered as a user. If you do not agree to these Terms, please do not use the App.',
  USER_REGISTRATION: '2. User Registration',
  REGISTRATION_MSG:
    'To use certain features of the App, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.',
  USER_RESPONSIBILITY: '3. User Responsibilities',
  STEP_ONE:
    'a. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device to prevent unauthorized access to your account.',
  STEP_TWO:
    'b. You are solely responsible for all activities that occur under your account or password.',
  STEP_THREE:
    'c. You agree to notify us immediately of any unauthorized use of your account or password.',
  CREDIBILITY_SCORE: 'Overall Credibility Score',
  EDUCATION_SCORE: 'Education Credibility Score',
  QUALIFICATION_NO: 'Number of Qualifications',
  EMPLOYMENT_SCORE: 'Employment Credibility Score',
  EMPLOYMENT_NO: 'Total Employment ',
  CREDIBILITY: 'Credibility Score',
  VERIFIED: 'Verification level',
  NETWORK_COUNT: 'No. of people who verified this qualification',
  VERIFICATION: 'Request Verification',
  EDIT: 'Edit',
  SOURCE: 'Source',
  VERIFIED_REQUESTS: 'Requests Received',
  PENDING_REQUESTS: 'Requests Received',
  SENT_REQUESTS: 'Requests Sent',
  VIEW_DETAILS: 'View Details',
  VERIFICATE: 'Verified',
  PENDING: 'Pending',
  VERIFICATION_DETAILS: 'Verification Details',
  EDUCATION_DETAILS: 'Education details',
  EMPLOYMENT_DETAILS: 'Employment details',
  MESSAGE: 'Message',
  COMMENT: 'Comment',
  ADD_COMMENT_HERE: 'Add comment here...',
  ADD_COMMENT: 'Send Feedback',
  CONFIRM_VERIFICATION: 'Confirm Verification',
  VIEW_DETAILS_SUBHEADING:
    'Please review the details below to confirm that these are correct',
  INCOMPLTE_PROFILE_HEADING: 'Your profile is incomplete!',
  INCOMPLETE_PROFILE_SUBHEADING:
    "You haven't added any education or employment details yet.",
  LOGOUT_HEADING: 'Log out?',
  LOGOUT_SUBHEADING: 'Are you sure you want to log out?',
  LOGOUT_BUTTON_TEXT: 'Yes, Log out',
  CHANGE: 'Change',
  CHECKBOX_LABEL: 'I am currently working in this role',
  SAVE_DETAILS: 'Save Details',
  DESIGNATION: 'Role/Designation',
  DATE_PICKER: 'date-picker',
  OTHER_DETAILS: 'Other details',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  VIEW_PROFILE: 'View Profile',
  LOGOUT: 'Log out',
  ABOUT_CC: 'About Credible Circle',
  COURSE_NAME: 'Course name',
  INSTITUTE_NAME: 'Institute name',
  QUALIFICATIONYEAR: 'Year of qualification',
  HIGHEST_VERIFICATION: 'Highest Level of Verification',
  DEGREE_NAME: 'Name of Course',
  INSTITUE_NAME: 'Name of Institute',
  ORGANIZATION: 'Organization',
  ROLE: 'Role',
  FACING_ISSUE_CONTACT: 'Facing any issue? Contact us at',
  CREDIBLE_CIRCLE: 'Credible Circle',
  WHERE_CREDIBILITY_MATTERS: 'Where Credibility Matters',
  LANDING_DESCRIPTION_ONE: 'What is Credibility?',
  LANDING_DESCRIPTION_TWO: 'What you claim to be true, is true',
  HOW_DOES_IT_WORKS: 'How does it work?',
  CONTACT: 'Contact',
  TERMS_CONDITION_PRIVACY_POLICY: 'Terms and Privacy Policy',
  AUTHORITY_DETAILS: 'Authorities Details',
  ADD_MESSAGE: 'Add message here...',
  SEND_DETAILS: 'Send Request',
  REQUEST_VERIFICATION: 'Request for Verification',
  VERIFICATION_NOTE:
    'Please enter the details of the Individual or Organization you are requesting for verification',
  VERIFICATION_DETAIL: 'Verification Authorities',
  NETWORK: 'Network',
  VIEWED_PROFILES: 'Viewed Profiles',
  NO_VERIFICATION_DATA:
    "You haven't verified any requests yet.",
  NO_PENDING_DATA: "You haven't received any pending requests yet.",
  NO_VIEWED_DATA: "You haven't view any profile",
  SIGNING_AS_ORGANIZATION: 'Signing up as an Organization',
  NAME_OF_ORGANIZATION: 'Name of Organization',
  CONFIRM: 'Confirm',
  DELETE_EDUCATION: 'Delete Details',
  EDUCATION_TEXT: 'Are you sure you want to delete education?',
  DELETE_EMPLOYMENT: 'Delete Details',
  EMPLOYMENT_TEXT: 'Are you sure you want to delete employment?',
};

export default lang;
