/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import Dashboard from '../dashboard';
import './education.scss';
import lang from '../../constants/lang';
import TotalScore from '../../components/totalScore';
import ListingTile from '../../components/listingTile';
import { PrimaryButton } from '../../components/Button';
import edu from '../../assets/svg/eduIcon.svg';
import { fetchEducationById, fetchEducationList } from '../../store/reducers/authentication/apiThunk';
import AddEducation from './addEducation';
import Modal from '../../components/Modal';
import NoDataScreen from '../../components/noDataScreen';
import { selectorAuthentication } from '../../store/reducers/authentication';

const EducationPage = () => {
  const [isModalOpen, setIsModalOpen] = useState();
  const [educationId, setEducationId] = useState();
  const [educationEdit, setEducationEdit] = useState();
  const location = useLocation();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const paramId = queryParams.get('id');
  const {
    EDUCATION_NAME,
    EDUCATION_SCORE,
    QUALIFICATION_NO,
    EDUCATION,
  } = lang;

  const {
    educationData: {
      data: educationList = [],
      credibleScore: credScore = 0,
    } = {},
  } = useSelector(selectorAuthentication);

  useEffect(() => {
    dispatch(fetchEducationList(paramId ? `?userId=${paramId}` : ''));
  }, []);

  const handleEdit = async (id) => {
    if (id) {
      setEducationId(id);
      const response = await dispatch(fetchEducationById(id)).then((val) => val);
      const {
        payload: { status = '', data },
      } = response;
      if (status === true) {
        setEducationEdit(data);
        setEdit(true);
        setIsModalOpen(true);
      }
    } else {
      setEducationEdit();
      setEdit(false);
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      handleEdit(location?.state?.id);
    }
  }, []);

  return (
    <Dashboard>
      {credScore !== 0
        ? (
          <div className="education-page-wrap">
            <div className="education-header">
              <div className="dash-heading edu-heading">{EDUCATION_NAME}</div>
              {!paramId
                ? (
                  <PrimaryButton onClick={() => {
                    setEdit(false);
                    setEducationEdit({});
                    setIsModalOpen(true);
                  }}
                  >
                    {EDUCATION}
                  </PrimaryButton>
                ) : null}
            </div>
            <div className="education-body-wrap">
              <TotalScore
                educationScore={EDUCATION_SCORE}
                educationValue={Math.floor(credScore || 0)}
                qualifications={QUALIFICATION_NO}
                qualificationNo={educationList?.length}
              />
              {educationList && educationList.map(({
                credibleScore, course, institute, verificationCount, yearOfQualification, _id, verifiedBy, otherDetails,
              }) => (
                <ListingTile
                  credibilityScore={credibleScore}
                  name={institute}
                  designation={course}
                  verificationCount={verificationCount}
                  year={yearOfQualification}
                  bodyIcon={edu}
                  otherDetails={otherDetails}
                  handleEdit={() => handleEdit(_id)}
                  verifiedBy={verifiedBy}
                  id={_id}
                  type={2}
                />
              ))}
            </div>
            <Modal
              openDialog={isModalOpen}
              closeDialog={() => setIsModalOpen(false)}
              dialogContent={(
                <AddEducation
                  setIsModalOpen={setIsModalOpen}
                  educationId={educationId}
                  educationEdit={educationEdit}
                  edit={edit}
                />
        )}
              dialogCss="educationDialog"
            />
          </div>
        ) : <NoDataScreen education={true} />}
    </Dashboard>
  );
};

export default EducationPage;
