import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';

const CustomCheckbox = styled(Checkbox)({
  // Your custom styles for the Checkbox when it's not checked
  '&.Mui-checked': {
    // Your custom styles for the Checkbox when it's checked
    color: '#0B1F3E', // Change color when checked
  },
});

const MyCheckbox = ({
  label, onChange, checked, helperText, error,
}) => (
  <div className="checkBox">
    <FormControlLabel
      control={(
        <CustomCheckbox
          checked={checked}
          onChange={onChange}
          color="primary"
        />
    )}
      label={label}
    />
    <div className="textfield-error">
      {error ? (
        <div>
          <span>{helperText}</span>
        </div>
      ) : (
        ''
      )}
    </div>

  </div>
);
export default MyCheckbox;
