/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import empty from '../../assets/svg/emptyScreenIcon.svg';
import lang from '../../constants/lang';
import { PrimaryButton, SecondaryButton } from '../Button';
import './noData.scss';
import Modal from '../Modal';
import AddEducation from '../../pages/education/addEducation';
import AddEmployment from '../../pages/Employment/addEmployement';

const NoDataScreen = ({
  education, employment, isSearch = false, currentSearchData = 0,
}) => {
  const [isModalOpen, setIsModalOpen] = useState();
  const [isOpen, setIsOpen] = useState();
  const {
    NO_DATA, NO_DATA_CONTENT, NO_DATA_CONTENT_EMP, NO_DATA_CONTENT_EDU, EDUCATION, EMPLOYMENT,
  } = lang;
  return (
    <div className="no-data-wrap">
      <div className="home-content-wrap">
        <img src={empty} alt="" />
        <div className="no-data">{NO_DATA}</div>
        {
          !isSearch
            ? <div className="no-content">{education ? NO_DATA_CONTENT_EDU : employment ? NO_DATA_CONTENT_EMP : NO_DATA_CONTENT}</div>
            : <div className="no-content">{currentSearchData === 1 ? NO_DATA_CONTENT_EDU : currentSearchData === 2 ? NO_DATA_CONTENT_EMP : NO_DATA_CONTENT}</div>

        }
        {!isSearch
          ? (
            <div>
              {education
                ? (
                  <div className="buttons">
                    <PrimaryButton
                      sx={{
                        padding: '12px 16px',
                        '@media screen and (max-width: 480px)': {
                          maxWidth: '137px',
                          maxHeight: '36px',
                          padding: '12px',
                          fontSize: '12px',
                          margin: 'auto',
                        },
                      }}
                      onClick={() => setIsModalOpen(true)}
                    >
                      {EDUCATION}

                    </PrimaryButton>
                  </div>
                ) : employment ? (
                  <div className="buttons">
                    <PrimaryButton
                      sx={{
                        padding: '12px 16px',
                        '@media screen and (max-width: 480px)': {
                          maxWidth: '137px',
                          maxHeight: '36px',
                          padding: '12px',
                          fontSize: '12px',
                          margin: 'auto',
                        },
                      }}
                      onClick={() => setIsOpen(true)}
                    >
                      {EMPLOYMENT}

                    </PrimaryButton>
                  </div>
                ) : (
                  <div className="buttons">
                    <PrimaryButton
                      sx={{
                        padding: '12px 16px',
                        '@media screen and (max-width: 480px)': {
                          maxWidth: '137px',
                          maxHeight: '36px',
                          padding: '12px',
                          fontSize: '12px',
                          margin: 'auto',
                        },
                      }}
                      onClick={() => setIsModalOpen(true)}
                    >
                      {EDUCATION}

                    </PrimaryButton>
                    <SecondaryButton
                      sx={{
                        padding: '12px 16px',
                        '@media screen and (max-width: 480px)': {
                          margin: 'auto',
                          maxWidth: '137px',
                          maxHeight: '36px',
                          padding: '12px',
                          fontSize: '12px',
                        },
                      }}
                      onClick={() => setIsOpen(true)}
                    >
                      {EMPLOYMENT}

                    </SecondaryButton>
                  </div>
                )}

            </div>
          ) : ''}
      </div>
      <Modal
        openDialog={isModalOpen}
        closeDialog={() => setIsModalOpen(false)}
        dialogContent={(
          <AddEducation
            setIsModalOpen={setIsModalOpen}
          />
        )}
        dialogCss="educationDialog"
      />
      <Modal
        openDialog={isOpen}
        closeDialog={() => setIsOpen(false)}
        dialogContent={(
          <AddEmployment
            setIsModalOpen={setIsOpen}
          />
        )}
        dialogCss="employmentDialog"
      />
    </div>
  );
};

export default NoDataScreen;
