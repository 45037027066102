/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useFormik } from 'formik';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Layout from '../../layout';
import lang from '../../constants/lang';
import { validationEmailSchema } from '../../utils/validations/authValidation';
import TextInput from '../../components/TextField';
import { PrimaryButton } from '../../components/Button';
import './forgotPassword.scss';
import { ROUTE_PATH } from '../../constants/route';
import { fetchForgotPassword } from '../../store/reducers/authentication/apiThunk';

const ForgotPassword = () => {
  const {
    FORGOT_PASSWORD, ENTER_EMAIL_TO_RESET, EMAIL_ADDRESS, GET_LINK, BACK,
  } = lang;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (values) => {
    dispatch(fetchForgotPassword(values));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationEmailSchema,
    onSubmit: submitHandler,
  });
  const {
    handleSubmit,
    values: { email } = {},
    touched: { email: tucEmail = false } = {},
    errors: { email: errEmail } = {},
  } = formik;
  const handleChange = (e, name) => {
    const { target } = e;
    formik.setFieldValue(name, target.value.trim());
  };

  return (
    <Layout>
      <div className="forgot-password-wrap">
        <div className="content-heading">
          {FORGOT_PASSWORD}
        </div>
        <div className="content-wrap">
          <div className="subHeading">
            {ENTER_EMAIL_TO_RESET}
          </div>
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextInput
              label={EMAIL_ADDRESS}
              placeholder={EMAIL_ADDRESS}
              require="true"
              type="text"
              name="email"
              onChange={(e) => handleChange(e, 'email')}
              value={email}
              error={tucEmail && !!errEmail}
              helper={tucEmail && errEmail}
              noIcon
              icon={(
                <EmailOutlinedIcon sx={{
                  color: '#7a869aa3',
                }}
                />
            )}
            />
            <PrimaryButton type="submit" className="loginBtn">{GET_LINK}</PrimaryButton>
          </form>
          <div className="back-button-wrap" onClick={() => navigate(ROUTE_PATH.PASSWORD_LOGIN)}>
            <KeyboardBackspaceRoundedIcon sx={{
              marginRight: '7px',
            }}
            />
            {BACK}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
