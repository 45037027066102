/* eslint-disable import/prefer-default-export */
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4f4f4f99',
    },
    secondary: {
      main: '#1a1a1a99',
    },
    customColor: {
      primaryWhite: '#FFFFFF',
      primaryYellow: '#F69824',
      primaryTransparent: 'transparent',
    },
    boxShadow: {
      main: '0px 7px 10px rgba(40, 41, 61, 0.08)',
    },
  },
});
