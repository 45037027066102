/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// App.js
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import './drawer.scss';
import { Divider } from '@mui/material';
import eye from '../../assets/svg/eye.svg';
import people from '../../assets/svg/people.svg';
import avatar from '../../assets/svg/avatar.svg';
import { selectorAuthentication } from '../../store/reducers/authentication';
import lang from '../../constants/lang';
import { ROUTE_PATH } from '../../constants/route';
import left from '../../assets/svg/left.svg';
import { fetchLogout, fetchProfileViews } from '../../store/reducers/authentication/apiThunk';
import { ToastNotifyError } from '../Toast/ToastNotify';
import crossIcon from '../../assets/svg/circleCross.svg';
import logout from '../../assets/svg/logout.svg';
import Modal from '../Modal';
import { PrimaryButton } from '../Button';

const SideDrawer = ({ openDrawer, toggleDrawer }) => {
  const [openViewedProfileDialog, setOpenViewedProfileDialog] = useState(false);
  const [viewedProfileUserList, setViewedProfileUserList] = useState([]);
  const [openlogoutDialog, setOpenlogoutDialog] = useState(false);
  const observerTarget = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const {
    VIEW_NO, LOGOUT, LOGOUT_BUTTON_TEXT, LOGOUT_HEADING,
    LOGOUT_SUBHEADING, SENT_REQUESTS,
  } = lang;

  const { MY_PROFILE, LOGIN } = ROUTE_PATH;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    profileDetail: {
      data: {
        user: {
          firstName = '',
          lastName = '',
          email = '',
          profileViewCount = 0,
          profileImagePath = '',
          type = '',
        } = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);

  const handleLogout = async () => {
    await dispatch(fetchLogout());
    navigate(LOGIN);
  };

  const handleViewedProfileModal = async (page = 1) => {
    if (profileViewCount !== 0) {
      const query = `?page=${page}&limit=10`;
      const response = await dispatch(fetchProfileViews(query)).then((val) => val);
      const { payload: { data: { data = [], pagination = {} } = {}, status = '', message = '' } = {} } = response;
      if (status) {
        if (Math.ceil(pagination?.totalRecords / pagination?.limit) <= Number(page)) {
          console.log('page: ', page);
          setIsLastPage(true);
        }
        if (page === 1) {
          setViewedProfileUserList(data);
          setCurrentPage(2);
        } else {
          if (Math.ceil(pagination?.totalRecords / pagination?.limit) > Number(page)) {
            setCurrentPage((prev) => prev + 1);
          }
          setViewedProfileUserList((prev) => [...prev, ...data]);
        }
        if (!openViewedProfileDialog) {
          setOpenViewedProfileDialog(true);
          toggleDrawer();
        }
      } else {
        ToastNotifyError(message);
      }
    }
  };

  const onScroll = () => {
    if (observerTarget.current) {
      const { scrollTop, scrollHeight, clientHeight } = observerTarget.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log('isLastPage: ', isLastPage, currentPage);
        if (!isLastPage) {
          handleViewedProfileModal(currentPage);
        }
      }
    }
  };

  const logoutContent = () => (
    <div className="edit-profile-wrap logout-wrap">
      <div className="profile-pic-wrap">
        <img src={logout} alt="" />
      </div>
      <div className="heading mb-c-2">{LOGOUT_HEADING}</div>
      <div className="subHeading mb-c-3">{LOGOUT_SUBHEADING}</div>
      <PrimaryButton
        sx={{
          width: 'auto',
          padding: '12px 16px',
          '@media screen and (max-width: 480px)': {
            padding: '12px',
            fontSize: '12px',
          },
        }}
        onClick={() => handleLogout()}
      >
        {LOGOUT_BUTTON_TEXT}
      </PrimaryButton>
    </div>
  );

  const handleCloseDialog = () => {
    setOpenViewedProfileDialog(false);
    setCurrentPage(1);
    setIsLastPage(false);
  };

  const viewedProfileContent = () => (
    <div className="edit-profile-wrap viewed-profile-wrap">
      <div className="heading mb-c-3">{VIEW_NO}</div>
      <div className="content-wrap" ref={observerTarget} onScroll={onScroll}>
        {
          viewedProfileUserList.map(({
            profileImagePath: userProfileImagePath = '', firstName: userFirstName = '', lastName: userLastName = '', email: userEmail = '',
          }, index) => (
            <div className={`card-wrap ${index > 0 ? 'mt-c-2' : ''}`} key={index}>
              <div className="profile-pic-wrap" style={{ overflow: 'visible' }}>
                <img src={userProfileImagePath ? process.env.REACT_APP_IMAGE_URL + userProfileImagePath : avatar} style={{ borderRadius: '50%' }} className="profileImage" alt="" />
              </div>
              <div className="right-wrap">
                <div className="name">{`${userFirstName} ${userLastName}`}</div>
                {/* <div className="mail">{userEmail}</div> */}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );

  const drawerContent = (
    <div className="drawer-container">
      <div className="drawer-profile-section">
        <img src={crossIcon} className="cross-icon" onClick={toggleDrawer} />
        <div className="profile">
          <img src={profileImagePath ? process.env.REACT_APP_IMAGE_URL + profileImagePath : avatar} className="profileImage" />
        </div>
        <div className="user-detail">
          <div className="user-name">{`${firstName} ${lastName}`}</div>
          <div className="profile-arrow" onClick={() => navigate(MY_PROFILE)}>
            <img src={left} />
          </div>
          <div className="user-email">{email}</div>
        </div>
        <Divider sx={{ marginTop: '32px', background: '#252525', opacity: '0.2' }} />
        {
          type === 'individual' ? (
            <div className="drawer-request">
              <div className="view-no" onClick={() => handleViewedProfileModal(1)}>
                <img src={eye} alt="" />
                <div className="title">
                  {VIEW_NO}
                </div>
                <div className="number"><img src={left} /></div>
              </div>
              <div
                className="view-no"
                onClick={() => {
                  navigate(ROUTE_PATH.VERIFICATION_REQUESTS);
                  toggleDrawer();
                }}
              >
                <img src={people} alt="" />
                <div className="title">
                  Requests Received
                </div>
                <div className="number"><img src={left} /></div>
              </div>
              <div
                className="view-no"
                onClick={() => {
                  navigate(ROUTE_PATH.SENT_REQUESTS);
                  toggleDrawer();
                }}
              >
                <img src={people} alt="" />
                <div className="title">
                  Requests Sent
                </div>
                <div className="number"><img src={left} /></div>
              </div>
            </div>
          ) : ''
        }
        <div className="drawer-footer">
          <div className="about-section" onClick={() => navigate(ROUTE_PATH?.ABOUT_CREDIBLE)}>About Credible Circle </div>
          <Divider sx={{ marginTop: '32px', background: '#252525', opacity: '0.2' }} />
          <div className="log-out" onClick={() => setOpenlogoutDialog(true)}>{LOGOUT}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="drawer-wrap">
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{
          width: '340px', maxWidth: '100%', flexShrink: 0, '& .MuiDrawer-paper': { width: '340px', maxWidth: '100%' },
        }}
      >
        {drawerContent}
      </Drawer>
      <Modal
        openDialog={openViewedProfileDialog}
        closeDialog={handleCloseDialog}
        dialogContent={viewedProfileContent()}
        dialogCss="editProfileDialog viewedProfileDialog"
      />
      <Modal
        openDialog={openlogoutDialog}
        closeDialog={() => setOpenlogoutDialog(false)}
        dialogContent={logoutContent()}
        dialogCss="editProfileDialog logoutDialog"
      />
    </div>
  );
};

export default SideDrawer;
