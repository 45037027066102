/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Layout from '../../layout';
import { fetchLogin } from '../../store/reducers/authentication/apiThunk';
import './login.scss';
import lang from '../../constants/lang';
import TextInput from '../../components/TextField';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { validationEmailSchema } from '../../utils/validations/authValidation';
import { ROUTE_PATH } from '../../constants/route';
import { supportMail } from '../../constants/constants';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    SIGN_IN, EMAIL_ADDRESS,
    LOGIN_WITH_OTP, LOGIN_WITH_PASSWORD,
    DONT_HAVE_ACCOUNT, SIGN_UP,
    FACING_ISSUE_CONTACT,
  } = lang;
  const { SIGNUP } = ROUTE_PATH;

  const submitHandler = async (values) => {
    const requestData = {
      ...values,
      loginType: 1,
    };
    const response = await dispatch(fetchLogin(requestData)).then((val) => val);
    const { payload: { status = '' } = {} } = response;
    if (status === true) {
      navigate('/otp/2');
    }
  };
  const formik = useFormik({
    initialValues: {
      email: location?.state?.email || '',
    },
    validationSchema: validationEmailSchema,
    onSubmit: submitHandler,
  });
  const {
    handleSubmit,
    values: { email } = {},
    touched: { email: tucEmail = false } = {},
    errors: { email: errEmail } = {},
  } = formik;
  const handleChange = (e, name) => {
    const { target } = e;
    formik.setFieldValue(name, target.value.trim());
  };

  const handleSupportMail = () => {
    const mailtoLink = `mailto:${supportMail}`;
    setTimeout(() => {
      window.open(mailtoLink, '_blank');
    }, 100);
  };

  return (
    <Layout>
      <div className="login-wrap">
        <div className="content-heading">
          {SIGN_IN}
        </div>
        <div className="login-content-wrap">
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextInput
              label={EMAIL_ADDRESS}
              placeholder={EMAIL_ADDRESS}
              require="true"
              type="text"
              name="email"
              onChange={(e) => handleChange(e, 'email')}
              value={email}
              error={tucEmail && !!errEmail}
              helper={tucEmail && errEmail}
              noIcon
              icon={(
                <EmailOutlinedIcon sx={{
                  color: '#7a869aa3',
                }}
                />
            )}
            />
            <PrimaryButton
              type="submit"
              disabled={email === ''}
              className="loginBtn"
              sx={{
                // width: '75%',
                wordBreak: 'break-word',
              }}
            >
              {LOGIN_WITH_OTP}

            </PrimaryButton>
          </form>
          <div className="orDivider">
            <div className="dividerLine" />
            OR
            <div className="dividerLine" />
          </div>
          <SecondaryButton
            sx={{
              width: '75%',
            }}
            onClick={() => navigate(
              ROUTE_PATH.PASSWORD_LOGIN,
              {
                state: {
                  email,
                },
              },
            )}
          >
            {LOGIN_WITH_PASSWORD}

          </SecondaryButton>
        </div>
        <div className="content-footer-wrap">
          {DONT_HAVE_ACCOUNT}
          {' '}
          <span
            className="redirectionBtn"
            onClick={() => navigate(SIGNUP)}
          >
            {SIGN_UP}
          </span>
        </div>
        <div className="otp-content-footer-wrap">
          {FACING_ISSUE_CONTACT}
          <span className="supportMail" onClick={() => handleSupportMail()}>{supportMail}</span>
        </div>
      </div>
    </Layout>
  );
}

export default Login;
