/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../layout';
import CustomBreadcrummbs from '../../components/Breadcrumbs';
import sms from '../../assets/svg/sms.svg';
import avatar from '../../assets/svg/avatar.svg';
import locate from '../../assets/svg/Location.svg';
import './myProfile.scss';
import lang from '../../constants/lang';

import { selectorAuthentication } from '../../store/reducers/authentication';
import { ROUTE_PATH } from '../../constants/route';

const SearchOrganization = () => {
  const {
    HOME,
    MY_PROFILE,
  } = lang;

  const { CUSTOMER_DASHBOARD, DASHBOARD } = ROUTE_PATH;

  const navigate = useNavigate();

  const {
    profileDetail: {
      data: {
        user: selfData = {},
      } = {},
    } = {},
    searchDetail: {
      data: {
        user: searchUser = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);

  const breadcrumbsList = [
    <div
      onClick={() => {
        navigate(selfData?.type === 'organization' ? CUSTOMER_DASHBOARD : DASHBOARD);
      }}
      style={{ cursor: 'pointer' }}
    >
      {HOME}
    </div>,
    <Typography key="2" color="text.primary">
      {`${searchUser?.firstName} ${searchUser?.lastName}`}
    </Typography>,
  ];

  return (
    <Layout dashboard>
      <div className="myProfile-wrap">
        <div className="myProfile-header">
          <CustomBreadcrummbs breadcrumbs={breadcrumbsList} />
        </div>
        <div className="mobile-header">{MY_PROFILE}</div>
        <div className="myProfile-content-wrap">
          {
            searchUser?.organizationName
          && (
            <div className="org_container">
              <div className="org_heading">Organization</div>
              <div className="org_name">{searchUser?.organizationName}</div>
            </div>
          )
         }
          <div className="details-wrap">
            <div className="profile-pic-wrap">
              <img
                src={
                  searchUser?.profileImagePath
                    ? process.env.REACT_APP_IMAGE_URL + searchUser?.profileImagePath
                    : avatar
                }
                alt=""
                className="profile-image"
              />
            </div>
            <div className="left-content-wrap">
              <div className="username-wrap">
                {searchUser?.firstName}
                {' '}
                {searchUser?.lastName}
              </div>
              {/* <div className="email-wrap">
                <img src={sms} className="mr-c-1" alt="" />
                {searchUser?.email}
              </div> */}
              { searchUser?.country && (
                <div className="location-wrap mt-c-2">
                  <img src={locate} className="mr-c-1" alt="" />
                  {searchUser.country}
                </div>
              )}
              {searchUser?.organizationName && (

                <div className="org_res_container">
                  <div className="org_res_heading">
                    Organization
                  </div>
                  <div className="org_res_name">
                    {searchUser?.organizationName}
                  </div>
                </div>
              ) }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SearchOrganization;
