/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import './home.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Dashboard from '../dashboard';
import TotalScore from '../../components/totalScore';
import ListingTile from '../../components/listingTile';
import lang from '../../constants/lang';
import eduIcon from '../../assets/svg/eduIcon.svg';
import empIcon from '../../assets/svg/EmpIcon.svg';
import { selectorAuthentication } from '../../store/reducers/authentication';
import { fetchEducationList, fetchEmploymentList, fetchHomeList } from '../../store/reducers/authentication/apiThunk';
import NoDataScreen from '../../components/noDataScreen';
import CustomizedToolTip from '../../components/CustomizedTooltip';
import ProfileSection from '../../components/profileSection';

const SearchDetails = () => {
  const { CREDIBILITY_SCORE, HOME } = lang;
  const {
    EDUCATION_NAME,
    EDUCATION_SCORE,
    VIEW_DETAILS,
    EMPLOYMENT_NAME,
    EMPLOYMENT_SCORE,
    INDIVIDUAL,
    QUALIFICATION_NO,
    EMPLOYMENT_NO,
  } = lang;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: searchId = null } = useParams();
  const [currentSearchData, setCurrentSearchData] = useState(0);

  const {
    homeData: {
      data: {
        userScore: {
          educationScore = 0,
          employmentScore = 0,
          overallCredibilityScore = 0,
        } = {},
        educationData = [],
        employmentData = [],
      } = {},
    } = {},
    educationData: {
      data: educationList = [],
      credibleScore: credScoreEdu = 0,
    } = {},
    employmentData: {
      data: employmentList = [],
      credibleScore: credScoreEmp = 0,
    } = {},
  } = useSelector(selectorAuthentication);

  useState(() => {
    if (searchId) {
      dispatch(fetchHomeList(`?userId=${searchId}`));
      setCurrentSearchData(0);
    } else {
      navigate('/home', { replace: true });
    }
  }, [searchId]);
  // end for home data

  useEffect(() => {
    if (currentSearchData === 1) {
      dispatch(fetchEducationList(`?userId=${searchId}`));
    } else if (currentSearchData === 2) {
      dispatch(fetchEmploymentList(`?userId=${searchId}`));
    } else {
      dispatch(fetchHomeList(`?userId=${searchId}`));
    }
  }, [currentSearchData]);

  return (
    <Dashboard
      isSearch
      setCurrentSearchData={setCurrentSearchData}
      currentSearchData={currentSearchData}
    >

      <div className="responsive-profile">
        <ProfileSection isSearch />
      </div>
      {
        currentSearchData === 0 && overallCredibilityScore !== 0
          // condition for home tb
          ? (
            <div className="home-wrap">
              <div className="dash-heading">{HOME}</div>
              <TotalScore
                educationScore={(
                  <div>
                    {CREDIBILITY_SCORE}
                  </div>
          )}
                educationValue={Math.floor(overallCredibilityScore || 0)}
              />
              {educationData && educationData?.length > 0
            && (
            <div>
              <ListingTile
                home
                bodyIcon={eduIcon}
                homeContent={(
                  <div className="home-body">
                    <div className="body-heading">{EDUCATION_NAME}</div>
                    {
                  educationData?.length && (
                  <div className="body-content">
                    {educationData[0]?.institute}
                    {
                      educationData?.length > 1 ? `, + ${educationData?.length - 1}` : ''
                    }
                  </div>
                  )
                }
                    <div
                      className="body-detail curser-pointer"
                      onClick={() => setCurrentSearchData(1)}
                    >
                      {VIEW_DETAILS}
                    </div>
                  </div>
            )}
                homeLeft={(
                  <div className="home-right">
                    <div className="home-right-content">
                      {EDUCATION_SCORE}
                      <CustomizedToolTip
                        title={INDIVIDUAL}
                      />
                    </div>
                    <div className="home-right-no">{educationScore}</div>
                  </div>
            )}
              />
            </div>
            )}
              {employmentData && employmentData?.length > 0
            && (
            <div>
              <ListingTile
                home
                bodyIcon={empIcon}
                homeContent={(
                  <div className="home-body">
                    <div className="body-heading">{EMPLOYMENT_NAME}</div>
                    {employmentData?.length && (
                    <div className="body-content">
                      {employmentData[0]?.organization}
                      {
                      employmentData?.length > 1 ? `, + ${employmentData?.length - 1}` : ''
                    }
                    </div>
                    )}
                    <div
                      className="body-detail curser-pointer"
                      onClick={() => setCurrentSearchData(2)}
                    >
                      {VIEW_DETAILS}
                    </div>
                  </div>
            )}
                homeLeft={(
                  <div className="home-right">
                    <div className="home-right-content">
                      {EMPLOYMENT_SCORE}
                      <CustomizedToolTip
                        title={INDIVIDUAL}
                      />
                    </div>
                    <div className="home-right-no">{employmentScore}</div>
                  </div>
            )}
              />
            </div>
            )}

            </div>
          ) : currentSearchData === 1 && credScoreEdu !== 0 ? (
            <div className="education-page-wrap">
              <div className="education-header">
                <div className="dash-heading edu-heading">{EDUCATION_NAME}</div>
              </div>
              <div className="education-body-wrap">
                <TotalScore
                  educationScore={EDUCATION_SCORE}
                  educationValue={Math.floor(credScoreEdu || 0)}
                  qualifications={QUALIFICATION_NO}
                  qualificationNo={educationList?.length}
                />
                {educationList && educationList.map(({
                  credibleScore,
                  course,
                  institute,
                  verificationCount,
                  yearOfQualification,
                  _id,
                  verifiedBy,
                  otherDetails,
                }) => (
                  <ListingTile
                    credibilityScore={credibleScore}
                    name={institute}
                    designation={course}
                    verificationCount={verificationCount}
                    year={yearOfQualification}
                    bodyIcon={eduIcon}
                    otherDetails={otherDetails}
                    handleEdit={() => null}
                    verifiedBy={verifiedBy}
                    id={_id}
                    type={2}
                    isSearch
                  />
                ))}
              </div>
            </div>
          ) : currentSearchData === 2 && credScoreEmp !== 0 ? (
            <div className="employment-page-wrap">
              <div className="employment-header">
                <div className="dash-heading emp-heading">{EMPLOYMENT_NAME}</div>
              </div>
              <div className="employment-body-wrap">
                <TotalScore
                  educationScore={EMPLOYMENT_SCORE}
                  educationValue={Math.floor(credScoreEmp || 0)}
                  qualifications={EMPLOYMENT_NO}
                  qualificationNo={employmentList?.length}
                />
                {employmentList && employmentList.map(({
                  organization: org,
                  designation,
                  tenureStartDate,
                  tenureEndDate,
                  verificationCount,
                  isCurrentlyWorking,
                  credibleScore, _id,
                  verifiedBy,
                  otherDetails,
                }) => (
                  <ListingTile
                    credibilityScore={credibleScore}
                    name={org}
                    designation={designation}
                    year={`${moment(tenureStartDate).format('MMM, YYYY')} - ${isCurrentlyWorking ? 'Present' : moment(tenureEndDate).format('MMM, YYYY')}`}
                    bodyIcon={empIcon}
                    verificationCount={verificationCount}
                    handleEdit={() => null}
                    verifiedBy={verifiedBy}
                    otherDetails={otherDetails}
                    id={_id}
                    type={1}
                    isSearch
                  />
                ))}
              </div>

            </div>
          )
            : <NoDataScreen isSearch currentSearchData={currentSearchData} />

      }
    </Dashboard>
  );
};

export default SearchDetails;
