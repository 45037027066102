/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import './home.scss';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Dashboard from '../dashboard';
import TotalScore from '../../components/totalScore';
import ListingTile from '../../components/listingTile';
import lang from '../../constants/lang';
import info from '../../assets/svg/infoCircle.svg';
import eduIcon from '../../assets/svg/eduIcon.svg';
import empIcon from '../../assets/svg/EmpIcon.svg';
import incompleteProfile from '../../assets/svg/incompleteProfile.svg';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import Modal from '../../components/Modal';
import { selectorAuthentication } from '../../store/reducers/authentication';
import { ROUTE_PATH } from '../../constants/route';
import { fetchHomeList, fetchProfileDetails } from '../../store/reducers/authentication/apiThunk';
import NoDataScreen from '../../components/noDataScreen';
import CustomizedToolTip from '../../components/CustomizedTooltip';
import AddEducation from '../education/addEducation';
import AddEmployment from '../Employment/addEmployement';

const useStyles = makeStyles(() => ({
  customTooltip: {
    background: '#FBFBFB',
    border: '1px #C1C1C1',
    borderRadius: '8px',
    width: '126px',
    height: '42px',
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '#C1C1C1',
    '& .MuiTooltip-arrow': {
      color: '#FBFBFB', // Set the color of the arrow
    },
  },
}));

const Home = () => {
  const { CREDIBILITY_SCORE, HOME } = lang;
  const classes = useStyles();
  const {
    EDUCATION,
    EMPLOYMENT,
    INCOMPLTE_PROFILE_HEADING,
    INCOMPLETE_PROFILE_SUBHEADING,
    EDUCATION_NAME,
    EDUCATION_SCORE,
    VIEW_DETAILS,
    EMPLOYMENT_NAME,
    EMPLOYMENT_SCORE,
    INDIVIDUAL,
  } = lang;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState();
  const [isOpen, setIsOpen] = useState();

  const {
    profileDetail: {
      data: { user: { isProfileCompleted = true, type: userType = '' } = {} } = {},
    } = {},
    homeData: {
      data: {
        userScore: {
          educationScore = 0,
          employmentScore = 0,
          overallCredibilityScore = 0,
        } = {},
        educationData = [],
        employmentData = [],
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);

  const [incompleteProfileDialog, setIncompleteProfileDialog] = useState(false);

  useEffect(() => {
    if (userType === 'individual') {
      setIncompleteProfileDialog(!isProfileCompleted);
    }
  }, [isProfileCompleted]);

  useState(() => {
    dispatch(fetchProfileDetails());
    dispatch(fetchHomeList());
  }, []);

  const handleCloseDialog = () => {
    setIncompleteProfileDialog(false);
  };
  const handleCallBack = () => {
    dispatch(fetchHomeList());
  };

  const incompleteProfileContent = () => (
    <div className="edit-profile-wrap-incomplete incomplete-profile-wrap">
      <div className="profile-pic-wrap">
        <img src={incompleteProfile} alt="" />
      </div>
      <div className="heading mb-c-2">{INCOMPLTE_PROFILE_HEADING}</div>
      <div className="subHeading mb-c-3">{INCOMPLETE_PROFILE_SUBHEADING}</div>
      <div className="button-wrap">
        <PrimaryButton
          sx={{
            width: 'auto',
            padding: '12px 16px',
            '@media screen and (max-width: 480px)': {
              padding: '12px',
              fontSize: '12px',
            },
          }}
          onClick={() => {
            setIsModalOpen(true);
            handleCloseDialog();
          }}
        >
          {EDUCATION}
        </PrimaryButton>
        <SecondaryButton
          sx={{
            width: 'auto',
            padding: '12px 16px',
            '@media screen and (max-width: 480px)': {
              padding: '12px',
              fontSize: '12px',
            },
          }}
          onClick={() => {
            setIsOpen(true);
            handleCloseDialog();
          }}
        >
          {EMPLOYMENT}
        </SecondaryButton>
      </div>
    </div>
  );

  return (
    <Dashboard>
      { overallCredibilityScore !== 0
        ? (
          <div className="home-wrap">
            <div className="dash-heading">{HOME}</div>
            <TotalScore
              educationScore={(
                <div>
                  {CREDIBILITY_SCORE}
                </div>
          )}
              educationValue={Math.floor(overallCredibilityScore || 0)}
            />
            {educationData && educationData?.length > 0
            && (
            <div>
              <ListingTile
                home
                bodyIcon={eduIcon}
                homeContent={(
                  <div className="home-body">
                    <div className="body-heading">{EDUCATION_NAME}</div>
                    {
                  educationData?.length && (
                  <div className="body-content">
                    {educationData[0]?.institute}
                    {
                      educationData?.length > 1 ? `, + ${educationData?.length - 1}` : ''
                    }
                  </div>
                  )
                }
                    <div
                      className="body-detail curser-pointer"
                      onClick={() => navigate(ROUTE_PATH.PATH_EDUCATION)}
                    >
                      {VIEW_DETAILS}
                    </div>
                  </div>
            )}
                homeLeft={(
                  <div className="home-right">
                    <div className="home-right-content">
                      {EDUCATION_SCORE}
                      <CustomizedToolTip
                        title={INDIVIDUAL}
                      />
                    </div>
                    <div className="home-right-no">{educationScore}</div>
                  </div>
            )}
              />
            </div>
            )}
            {employmentData && employmentData?.length > 0
            && (
            <div>
              <ListingTile
                home
                bodyIcon={empIcon}
                homeContent={(
                  <div className="home-body">
                    <div className="body-heading">{EMPLOYMENT_NAME}</div>
                    {employmentData?.length && (
                    <div className="body-content">
                      {employmentData[0]?.organization}
                      {
                      employmentData?.length > 1 ? `, + ${employmentData?.length - 1}` : ''
                    }
                    </div>
                    )}
                    <div
                      className="body-detail curser-pointer"
                      onClick={() => navigate(ROUTE_PATH.PATH_EMPLOYMENT)}
                    >
                      {VIEW_DETAILS}
                    </div>
                  </div>
            )}
                homeLeft={(
                  <div className="home-right">
                    <div className="home-right-content">
                      {EMPLOYMENT_SCORE}
                      <CustomizedToolTip
                        title={INDIVIDUAL}
                      />
                    </div>
                    <div className="home-right-no">{employmentScore}</div>
                  </div>
            )}
              />
            </div>
            )}

          </div>
        ) : <NoDataScreen />}
      <Modal
        openDialog={incompleteProfileDialog}
        closeDialog={() => handleCloseDialog()}
        dialogContent={incompleteProfileContent()}
        dialogCss="editProfileDialog viewedProfileDialog"
      />
      <Modal
        openDialog={isModalOpen}
        closeDialog={() => setIsModalOpen(false)}
        dialogContent={(
          <AddEducation
            setIsModalOpen={setIsModalOpen}
            handleCallBack={handleCallBack}
          />
        )}
        dialogCss="educationDialog"
      />
      <Modal
        openDialog={isOpen}
        closeDialog={() => setIsOpen(false)}
        dialogContent={(
          <AddEmployment
            setIsModalOpen={setIsOpen}
            handleCallBack={handleCallBack}

          />
        )}
        dialogCss="employmentDialog"
      />
    </Dashboard>
  );
};

export default Home;
