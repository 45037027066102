import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';
import styleMoreModal from './popStyle';
import './style.scss';

export function MoreModal(props) {
  const {
    open, onClosed, className, children,
  } = props;
  return (
    <Modal
      open={open}
      onClose={onClosed}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`${className} modal-c-popup`}
    >
      <Box sx={styleMoreModal}>{children}</Box>
    </Modal>
  );
}

export default MoreModal;
