/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { useLocation, useNavigate } from 'react-router-dom';
import avatar from '../../assets/svg/avatar.svg';
import infoCircle from '../../assets/svg/infoCircle.svg';
import Layout from '../../layout';
import CustomBreadcrummbs from '../../components/Breadcrumbs';
import verify from '../../assets/svg/verify.svg';
import { SecondaryButton } from '../../components/Button';
import './verficationRequests.scss';
import lang from '../../constants/lang';
import CustomTabs from '../../components/Tabs';
import Modal from '../../components/Modal';
import { ROUTE_PATH } from '../../constants/route';
import { fetchSentRequestList, fetchVerificationList } from '../../store/reducers/authentication/apiThunk';
import empty from '../../assets/svg/emptyScreenIcon.svg';
import ReadMore from '../../components/ReadMore';

const SentRequests = () => {
  const {
    HOME,
    VERIFIED_REQUESTS,
    PENDING_REQUESTS,
    SENT_REQUESTS,
    VIEW_DETAILS,
    VERIFICATE,
    PENDING,
    VERIFICATION_DETAILS,
    EDUCATION_DETAILS,
    EMPLOYMENT_DETAILS,
    MESSAGE,
    VIEW_DETAILS_SUBHEADING,
    NO_DATA,
    NO_VERIFICATION_DATA,
    NO_PENDING_DATA,
  } = lang;

  const { DASHBOARD } = ROUTE_PATH;
  const dispatch = useDispatch();

  const tabListValue = [
    {
      value: 0,
      label: SENT_REQUESTS,
    },
    // {
    //   value: 1,
    //   label: SENT_REQUESTS,
    // },
  ];

  // condition for redirection
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const verificationStatus = queryParams.get('status');
  const navigate = useNavigate();
  const defaultTabValue = verificationStatus && (Number(verificationStatus) === tabListValue[1]?.value || Number(verificationStatus) === tabListValue[0]?.value) ? Number(verificationStatus) : tabListValue[0]?.value;

  const [tabValue, setTabValue] = useState(defaultTabValue);
  const [queryDocument, setQueryDocument] = useState(0);
  const [openViewDetailDialog, setOpenViewDetailDialog] = useState(false);
  const [requestType, setRequestType] = useState();
  const [commentText, setCommentText] = useState('');
  const [flag, setFlag] = useState('0');
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState({
    name: '',
    email: '',
    id: '',
    profileImagePath: '',
  });
  const [verificationDetail, setVerificationDetail] = useState([]);
  // const [education, setEducation] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const observerTarget = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    queryParams.set('status', newValue);
    window.history.pushState(null, 'status', `?${queryParams.toString()}`);
  };

  const breadcrumbsList = [
    <div
      onClick={() => {
        navigate(DASHBOARD);
      }}
      style={{ cursor: 'pointer' }}
    >
      {HOME}
    </div>,
    <Typography key="2" color="text.primary">
      {SENT_REQUESTS}
    </Typography>,
  ];

  const getVerificationList = async (page = 1) => {
    const query = `?page=${page}&limit=10&status=${
      tabValue === 0 ? '' : ''
    }`;
    const response = await dispatch(fetchSentRequestList(query)).then(
      (val) => val,
    );
    const {
      payload: {
        status = false,
        data: {
          verificationRequests = [], perPageData = 0, totalDocument = 0,
        },
      },
    } = response;
    if (status === true) {
      if (Math.ceil(totalDocument / perPageData) <= Number(page)) {
        setIsLastPage(true);
      }
      if (page === 1) {
        setCurrentPage(2);
        setUserList(verificationRequests);
      } else {
        if (Math.ceil(totalDocument / perPageData) > Number(page)) {
          setCurrentPage(currentPage + 1);
        }
        setUserList((prev) => [...prev, ...verificationRequests]);
      }
      if (tabValue === 0) {
        setQueryDocument([{ count: totalDocument }, { }]);
      } else {
        setQueryDocument([{ }, { count: totalDocument }]);
      }
    } else {
      setUserList([]);
      setPendingList([]);
    }
  };

  useEffect(() => {
    setUserList([]);
    setPendingList([]);
    setIsLastPage(false);
    setCurrentPage(1);
    getVerificationList(1);
  }, [tabValue]);

  const onScroll = () => {
    if (observerTarget.current) {
      const { scrollTop, scrollHeight, clientHeight } = observerTarget.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log('isLastPage: ', isLastPage, currentPage);
        if (!isLastPage) {
          getVerificationList(currentPage);
        }
      }
    }
  };

  const handleCardClick = (e) => {
    if (window.screen.width <= 768 && e?.target?.tagName !== 'BUTTON') {
      setOpenViewDetailDialog(true);
    }
  };

  const renderNoDataFound = () => (
    <div className="home-content-wrap">
      <img src={empty} alt="" />
      <div className="no-data">{NO_DATA}</div>
      <div className="no-content">
        {"You haven't sent any verification requests yet."}
      </div>
    </div>
  );

  const renderVerifiedRequest = () => (userList.length !== 0
    ? userList.map(
      ({
        userDetails: {
          profileImagePath: eachUserProfileImage = '',
          lastName = '',
          email = '',
          firstName = '',
        } = {},
        _id = '',
        employmentDetails = {},
        educationDetails = {},
        requesterComments = '',
        status = '',
      }) => (
        <div
          className="card-wrap"
          onClick={(e) => {
            setVerificationDetail({ employmentDetails, educationDetails, comment: requesterComments?.comment });
            setRequestType(0);
            setCommentText(requesterComments?.comment || '');
            setUser({
              name: `${firstName} ${lastName}`,
              email,
              id: _id,
              profileImagePath: eachUserProfileImage,
            });
            handleCardClick(e);
          }}
        >
          <div className="profilePic-wrap">
            <img src={eachUserProfileImage ? process.env.REACT_APP_IMAGE_URL + eachUserProfileImage : avatar} className="profileImage" alt="" />
          </div>
          <div className="right-content-wrap">
            <div className="name-wrap">
              <div className="name">{`${firstName} ${lastName}`}</div>
              <div className="email">{email}</div>
            </div>
            <div className="button-wrap">
              <SecondaryButton
                sx={{
                  width: 'auto',
                  padding: '12px 16px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setVerificationDetail({ employmentDetails, educationDetails, comment: requesterComments?.comment });
                  setRequestType(0);
                  setCommentText(requesterComments?.comment || '');
                  setUser({
                    name: `${firstName} ${lastName}`,
                    email,
                    id: _id,
                    profileImagePath: eachUserProfileImage,
                  });
                  setOpenViewDetailDialog(true);
                }}
              >
                {VIEW_DETAILS}
              </SecondaryButton>
            </div>

            {
              status === 'verified'
                ? (
                  <div className="status-wrap">
                    <div className="verify-tag-wrap">
                      <img src={verify} className="mr-c-1" alt="" />
                      {VERIFICATE}
                    </div>
                  </div>
                ) : (
                  <div className="status-wrap">
                    <div className="verify-tag-wrap  pending-tag-wrap">
                      <img
                        src={infoCircle}
                        className="filterImage mr-c-1"
                        alt=""
                      />
                      {PENDING}
                    </div>
                  </div>
                )
            }
          </div>
        </div>
      ),
    )
    : renderNoDataFound());

  const renderVerificationRequests = () => {
    switch (tabValue) {
      case 0:
        return renderVerifiedRequest();
      default:
        return renderVerifiedRequest();
    }
  };

  const handleCloseDialog = () => {
    setOpenViewDetailDialog(false);
  };

  const viewDetailContent = () => (
    <div className="verificationDetail-content-wrap">
      <div className="header-wrap">
        <div className="heading">{VERIFICATION_DETAILS}</div>
      </div>
      <div className="content-wrap">
        <div className="profile-detail-wrap">
          <div className="profile-pic-wrap">
            <img
              src={
                user?.profileImagePath
                  ? process.env.REACT_APP_IMAGE_URL + user.profileImagePath
                  : avatar
              }
              className="profileImage"
              alt=""
            />
          </div>
          <div className="other-detail-wrap">
            <div className="name">{user?.name}</div>
            <div className="email">{user?.email}</div>
          </div>
        </div>
        <div className="education-detail-wrap">
          <div className="heading mb-c-1" style={{ position: 'relative' }}>
            <span>{verificationDetail?.employmentDetails?.organization ? EMPLOYMENT_DETAILS : EDUCATION_DETAILS}</span>
            <span
              style={{
                position: 'absolute',
                right: '0',
                padding: '2px 12px',
                borderRadius: '4px',
                cursor: 'pointer',
                background: '#2e4263',
                color: '#ffffff',
                fontSize: '14px',
              }}
              // eslint-disable-next-line consistent-return
              onClick={() => {
                if (verificationDetail?.employmentDetails?.verifiedBy === 'source' || verificationDetail?.educationDetails?.verifiedBy === 'source') {
                  return false;
                }
                navigate(verificationDetail?.employmentDetails?._id ? '/employment' : '/education', {
                  state: {
                    id: verificationDetail?.employmentDetails?._id || verificationDetail?.educationDetails?._id,
                  },
                });
              }}
            >
              Edit
            </span>
          </div>
          <div className="subheading mb-c-1">
            { verificationDetail?.employmentDetails?.organization
                || verificationDetail?.educationDetails?.institute}
          </div>
          <div className="description mb-c-1">
            {verificationDetail?.employmentDetails?.designation
                || verificationDetail?.educationDetails?.course}
          </div>
          <div className="description">
            {verificationDetail?.educationDetails?.yearOfQualification
                || `${moment(verificationDetail?.employmentDetails?.tenureStartDate).format(
                  'MMM, YYYY',
                )} - ${
                  verificationDetail?.employmentDetails?.tenureEndDate !== null
                    ? moment(verificationDetail?.employmentDetails?.tenureEndDate).format(
                      'MMM, YYYY',
                    )
                    : 'Present'
                }`}
          </div>
          {(verificationDetail?.educationDetails?.otherDetails || verificationDetail?.employmentDetails?.otherDetails) && (
          <div className="description" style={{ marginTop: '8px' }}>
            <ReadMore textSize={70}>
              {verificationDetail?.educationDetails?.otherDetails || verificationDetail?.employmentDetails?.otherDetails}
            </ReadMore>
          </div>
          )}
        </div>
        {verificationDetail?.comment ? (
          <div className="education-detail-wrap">
            <div className="heading mb-c-1">{MESSAGE}</div>
            <div className="description">{verificationDetail?.comment}</div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  return (
    <Layout dashboard disableScroll>
      <div className="myProfile-wrap verification-wrap" ref={observerTarget} onScroll={onScroll}>
        <div className="myProfile-header">
          <CustomBreadcrummbs breadcrumbs={breadcrumbsList} />
        </div>
        <div className="verification-content-wrap content-wrap-main">
          <div className="tabs-wrap tabs-wrap-main">
            <CustomTabs
              tabList={tabListValue}
              selectedtabValue={tabValue}
              OnChange={(newValue) => handleTabChange(newValue)}
              data={queryDocument}
            />
          </div>
          <div className="cards-wrap card-wrap-main">{renderVerificationRequests()}</div>
        </div>
      </div>
      <Modal
        openDialog={openViewDetailDialog}
        closeDialog={() => {
          handleCloseDialog();
          setCommentText('');
        }}
        dialogContent={viewDetailContent()}
        dialogCss="verificationDetailDialog"
      />
    </Layout>
  );
};

export default SentRequests;
