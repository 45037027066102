const toastMessages = {
  successToast: {
    TST_LOGIN_SUCCESSFULLY: 'Signed In Successfully',
    TST_PASSWORD_UPDATE: 'Password updated successfully',
    TST_EMAIL_SENT: 'Code Sent Successfully',
    TST_EMAIL_VERIFICATION_SUCCESS: 'Email Verified Successfully',
    TST_RESEND_SUCCESS: 'OTP Re-sent Successfully',
    TST_SIGNUP_SUCCESSFULLY: 'Signed Up Successfully',
    TST_FOLLOW_SUCCESSFULLY: 'Follow Successfully',
    TST_UNFOLLOW_SUCCESSFULLY: 'Unfollow Successfully',
    TST_EDITED_SUCCESSFULLY: 'Profile Edited Successfully',
    TST_EMPLOYMENT_ADD: 'Employment details added successfully',
    TST_EMPLOYMENT_UPDATE: 'Employment details updated successfully',
    TST_EDUCATION_ADD: 'Education details added successfully',
    TST_EDUCATION_UPDATE: 'Education details updated successfully',
    TST_EMPLOYMENT_DEL: 'Employment Deleted Successfully',
  },

  errorToast: {
    TST_PERMISSION_DENIED: 'Authentication Permission Denied',
    TST_SOMTHING_WRONG: 'Something went wrong, please try after some time',
    TST_AUTHENTICATION_FAILED: 'You have to login first to perform this action',
  },
};
export default toastMessages;
