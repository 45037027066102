/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
// import Sidebar from '../../components/sidebar';
import './dashboard.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Sidebar from '../../components/sidebar';
import ProfileSection from '../../components/profileSection';
import Footer from '../../components/footer';
import Layout from '../../layout';
import CustomBreadcrummbs from '../../components/Breadcrumbs';
import lang from '../../constants/lang';
import { ROUTE_PATH } from '../../constants/route';
import { selectorAuthentication } from '../../store/reducers/authentication';

const Dashboard = ({
  children,
  isSearch = false,
  setCurrentSearchData = () => null,
  currentSearchData = 0,
}) => {
  const { HOME } = lang;
  const navigate = useNavigate();
  const {
    profileDetail: {
      data: {
        user: selfData = {},
      } = {},
    } = {},
    searchDetail: {
      data: {
        user: {
          firstName: fetchedFirstName = '',
          lastName: fetchedLastName = '',
        } = {},
      } = {},
    } = {},
  } = useSelector(selectorAuthentication);
  const {
    DASHBOARD, CUSTOMER_DASHBOARD,
  } = ROUTE_PATH;
  const breadcrumbsList = [
    <div
      onClick={() => {
        navigate(selfData?.type === 'organization' ? CUSTOMER_DASHBOARD : DASHBOARD);
      }}
      style={{ cursor: 'pointer' }}
    >
      {HOME}
    </div>,
    <Typography key="2" color="text.primary">
      <div className="bread-crum">
        {`${fetchedFirstName} ${fetchedLastName}`}
      </div>
    </Typography>,
  ];
  return (
    <Layout
      dashboard
      setCurrentSearchData={setCurrentSearchData}
      currentSearchData={currentSearchData}
      isSearch={isSearch}
    >
      <div className="dashboard-wrap">
        {isSearch && (
          <div className="myProfile-header">
            <CustomBreadcrummbs breadcrumbs={breadcrumbsList} />
          </div>
        )}
        <div className="outer-content-wrap">
          <div className="dashboard-layout-content-wrap">
            <div className="sidebar-wrap">
              <div>
                <ProfileSection isSearch={isSearch} />
              </div>
              <div>
                <Sidebar
                  isSearch={isSearch}
                  setCurrentSearchData={setCurrentSearchData}
                  currentSearchData={currentSearchData}
                />
              </div>
            </div>
            <div className="children-wrap">{children}</div>
          </div>
        </div>
      </div>
      <Footer
        isSearch={isSearch}
        setCurrentSearchData={setCurrentSearchData}
        currentSearchData={currentSearchData}
      />
    </Layout>
  );
};
export default Dashboard;
