/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { useLocation, useNavigate } from 'react-router-dom';
import avatar from '../../assets/svg/avatar.svg';
import Layout from '../../layout';
import CustomBreadcrummbs from '../../components/Breadcrumbs';
import sms from '../../assets/svg/sms.svg';
import verify from '../../assets/svg/verify.svg';
import infoCircle from '../../assets/svg/infoCircle.svg';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import './verficationRequests.scss';
import lang from '../../constants/lang';
import CustomTabs from '../../components/Tabs';
import Modal from '../../components/Modal';
import TextInput from '../../components/TextField';
import { ROUTE_PATH } from '../../constants/route';
import {
  fetchAddComment,
  fetchConfirmVerification,
  fetchVerificationList,
} from '../../store/reducers/authentication/apiThunk';
import empty from '../../assets/svg/emptyScreenIcon.svg';
import {
  ToastNotifyError,
  ToastNotifySuccess,
} from '../../components/Toast/ToastNotify';
import ReadMore from '../../components/ReadMore';

const VerificationRequests = () => {
  const {
    HOME,
    VERIFICATION_REQUEST,
    VERIFIED_REQUESTS,
    PENDING_REQUESTS,
    SENT_REQUESTS,
    VIEW_DETAILS,
    VERIFICATE,
    PENDING,
    VERIFY,
    VERIFICATION_DETAILS,
    EDUCATION_DETAILS,
    EMPLOYMENT_DETAILS,
    MESSAGE,
    COMMENT,
    ADD_COMMENT_HERE,
    ADD_COMMENT,
    CONFIRM_VERIFICATION,
    VIEW_DETAILS_SUBHEADING,
    NO_DATA,
    NO_VERIFICATION_DATA,
    NO_PENDING_DATA,
  } = lang;

  const { DASHBOARD } = ROUTE_PATH;
  const dispatch = useDispatch();

  const tabListValue = [
    {
      value: 0,
      label: 'Verified Requests',
    },
    {
      value: 1,
      label: 'Pending Requests',
    },
    // {
    //   value: 2,
    //   label: SENT_REQUESTS,
    // },
  ];

  // condition for redirection
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const verificationStatus = queryParams.get('status');
  const navigate = useNavigate();
  const defaultTabValue = verificationStatus
    && (Number(verificationStatus) === tabListValue[1]?.value
      || Number(verificationStatus) === tabListValue[0]?.value)
    ? Number(verificationStatus)
    : tabListValue[1]?.value;

  const [tabValue, setTabValue] = useState(defaultTabValue);
  const [queryDocument, setQueryDocument] = useState(0);
  const [openViewDetailDialog, setOpenViewDetailDialog] = useState(false);
  const [requestType, setRequestType] = useState();
  const [commentText, setCommentText] = useState('');
  const [flag, setFlag] = useState('0');
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState({
    name: '',
    email: '',
    id: '',
    profileImagePath: '',
  });
  const [verificationDetail, setVerificationDetail] = useState([]);
  // const [education, setEducation] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const observerTarget = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    queryParams.set('status', newValue);
    window.history.pushState(null, 'status', `?${queryParams.toString()}`);
  };

  const breadcrumbsList = [
    <div
      onClick={() => {
        navigate(DASHBOARD);
      }}
      style={{ cursor: 'pointer' }}
    >
      {HOME}
    </div>,
    <Typography key="2" color="text.primary">
      {VERIFICATION_REQUEST}
    </Typography>,
  ];

  const getVerificationList = async (page = 1) => {
    const query = `?page=${page}&limit=10&type=${
      tabValue === 0 ? 'verified' : 'pending'
    }`;
    const response = await dispatch(fetchVerificationList(query)).then(
      (val) => val,
    );
    const {
      payload: {
        status = false,
        data: {
          verificationRequests = [],
          perPageData = 0,
          queryDocumentCount = 0,
          remainingDocumentCount = 0,
        },
      },
    } = response;
    if (status === true) {
      if (Math.ceil(queryDocumentCount / perPageData) <= Number(page)) {
        setIsLastPage(true);
      }
      // if (page === 1) {
      //   setCurrentPage(2);
      //   setUserList(verificationRequests);
      // } else {
      //   if (Math.ceil(queryDocumentCount / perPageData) > Number(page)) {
      //     setCurrentPage(currentPage + 1);
      //   }
      //   setUserList((prev) => [...prev, ...verificationRequests]);
      // }
      if (tabValue === 0) {
        if (page === 1) {
          setCurrentPage(2);
          setUserList(verificationRequests);
        } else {
          if (Math.ceil(queryDocumentCount / perPageData) > Number(page)) {
            setCurrentPage(currentPage + 1);
          }
          setUserList((prev) => [...prev, ...verificationRequests]);
        }
        setQueryDocument([
          { count: queryDocumentCount },
          { count: remainingDocumentCount },
        ]);
      } else {
        if (page === 1) {
          setCurrentPage(2);
          setPendingList(verificationRequests);
        } else {
          if (Math.ceil(queryDocumentCount / perPageData) > Number(page)) {
            setCurrentPage(currentPage + 1);
          }
          setPendingList((prev) => [...prev, ...verificationRequests]);
        }
        setQueryDocument([
          { count: remainingDocumentCount },
          { count: queryDocumentCount },
        ]);
      }
    } else {
      setUserList([]);
      setPendingList([]);
    }
  };

  useEffect(() => {
    setUserList([]);
    setPendingList([]);
    setIsLastPage(false);
    setCurrentPage(1);
    getVerificationList(1);
  }, [tabValue]);

  const onScroll = () => {
    if (observerTarget.current) {
      const { scrollTop, scrollHeight, clientHeight } = observerTarget.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (!isLastPage) {
          getVerificationList(currentPage);
        }
      }
    }
  };

  const handleCardClick = (e) => {
    if (window.screen.width <= 768 && e?.target?.tagName !== 'BUTTON') {
      setOpenViewDetailDialog(true);
    }
  };

  const renderNoDataFound = () => (
    <div className="home-content-wrap">
      <img src={empty} alt="" />
      <div className="no-data">{NO_DATA}</div>
      <div className="no-content">
        {tabValue === 0 ? NO_VERIFICATION_DATA : NO_PENDING_DATA}
      </div>
    </div>
  );

  const renderVerifiedRequest = () => (userList.length !== 0
    ? userList.map(
      ({
        userDetails: {
          profileImagePath: eachUserProfileImage = '',
          lastName = '',
          email = '',
          firstName = '',
        } = {},
        _id = '',
        employmentDetails = [],
        educationDetails = [],
        requesterComments = '',
      }) => (
        <div
          className="card-wrap"
          onClick={(e) => {
            setFlag(0);
            if (employmentDetails.length) {
              setVerificationDetail([
                {
                  ...employmentDetails[0],
                  comment: requesterComments?.comment,
                },
              ]);
              setRequestType(0);
              setCommentText(requesterComments?.comment || '');
              setUser({
                name: `${firstName} ${lastName}`,
                email,
                id: _id,
                profileImagePath: eachUserProfileImage,
              });
            } else {
              setVerificationDetail([
                {
                  ...educationDetails[0],
                  comment: requesterComments?.comment,
                },
              ]);
              setRequestType(1);
              setUser({
                name: `${firstName} ${lastName}`,
                email,
                id: _id,
                profileImagePath: eachUserProfileImage,
              });
            }
            handleCardClick(e);
          }}
        >
          <div className="profilePic-wrap">
            <img
              src={
                    eachUserProfileImage
                      ? process.env.REACT_APP_IMAGE_URL + eachUserProfileImage
                      : avatar
                  }
              className="profileImage"
              alt=""
            />
          </div>
          <div className="right-content-wrap">
            <div className="name-wrap">
              <div className="name">{`${firstName} ${lastName}`}</div>
              <div className="email">{email}</div>
            </div>
            <div className="button-wrap">
              <SecondaryButton
                sx={{
                  width: 'auto',
                  padding: '12px 16px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (employmentDetails.length) {
                    setVerificationDetail([
                      {
                        ...employmentDetails[0],
                        comment: requesterComments?.comment,
                      },
                    ]);
                    setRequestType(0);
                    setCommentText(requesterComments?.comment || '');
                    setUser({
                      name: `${firstName} ${lastName}`,
                      email,
                      id: _id,
                      profileImagePath: eachUserProfileImage,
                    });
                  } else {
                    setVerificationDetail([
                      {
                        ...educationDetails[0],
                        comment: requesterComments?.comment,
                      },
                    ]);
                    setCommentText(requesterComments?.comment || '');
                    setRequestType(1);
                    setUser({
                      name: `${firstName} ${lastName}`,
                      email,
                      id: _id,
                      profileImagePath: eachUserProfileImage,
                    });
                  }
                  setOpenViewDetailDialog(true);
                  setFlag(0);
                }}
              >
                {VIEW_DETAILS}
              </SecondaryButton>
            </div>
            <div className="status-wrap">
              <div className="verify-tag-wrap">
                <img src={verify} className="mr-c-1" alt="" />
                {VERIFICATE}
              </div>
            </div>
          </div>
        </div>
      ),
    )
    : renderNoDataFound());

  const renderPendingRequestList = () => (pendingList.length !== 0
    ? pendingList.map(
      ({
        userDetails: {
          profileImagePath: eachUserProfileImage = '',
          lastName = '',
          email = '',
          firstName = '',
        } = {},
        _id = '',
        employmentDetails = [],
        educationDetails = [],
        requesterComments = '',
      }) => (
        <div
          className="card-wrap pending-card-wrap"
        >
          <div className="profilePic-wrap">
            <img
              src={
                    eachUserProfileImage
                      ? process.env.REACT_APP_IMAGE_URL + eachUserProfileImage
                      : avatar
                  }
              className="profileImage"
              alt=""
            />
          </div>
          <div className="right-content-wrap">
            <div className="name-wrap">
              <div className="name">{`${firstName} ${lastName}`}</div>
              <div className="email">{email}</div>
            </div>
            <div className="button-wrap">
              {/* <SecondaryButton
                sx={{
                  width: 'auto',
                  padding: '12px 16px',
                  marginRight: '20px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenViewDetailDialog(true);
                  setFlag(0);
                  if (employmentDetails.length) {
                    setVerificationDetail([{ ...employmentDetails[0], comment: requesterComments?.comment }]);
                    setRequestType(0);
                    setCommentText(requesterComments?.comment || '');
                    setUser({
                      name: `${firstName} ${lastName}`,
                      email,
                      id: _id,
                      profileImagePath: eachUserProfileImage,
                    });
                  } else {
                    setVerificationDetail([{ ...educationDetails[0], comment: requesterComments?.comment }]);
                    setRequestType(1);
                    setUser({
                      name: `${firstName} ${lastName}`,
                      email,
                      id: _id,
                      profileImagePath: eachUserProfileImage,
                    });
                  }
                }}
              >
                {VIEW_DETAILS}
              </SecondaryButton> */}
            </div>
            <div className="footer-wrap">
              <PrimaryButton
                sx={{
                  width: 'auto',
                  padding: '13px 16px',
                  height: 'max-content',
                  '@media screen and (max-width: 768px)': {
                    padding: '8px 16px',
                  },
                  '@media screen and (max-width: 480px)': {
                    fontSize: '12px',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenViewDetailDialog(true);
                  setFlag(1);
                  if (employmentDetails.length) {
                    setVerificationDetail([
                      {
                        ...employmentDetails[0],
                        comment: requesterComments?.comment,
                      },
                    ]);
                    setRequestType(0);
                    setUser({
                      name: `${firstName} ${lastName}`,
                      email,
                      id: _id,
                      profileImagePath: eachUserProfileImage,
                    });
                  } else {
                    setVerificationDetail([
                      {
                        ...educationDetails[0],
                        comment: requesterComments?.comment,
                      },
                    ]);
                    setRequestType(1);
                    setUser({
                      name: `${firstName} ${lastName}`,
                      email,
                      id: _id,
                      profileImagePath: eachUserProfileImage,
                    });
                  }
                }}
              >
                {VERIFY}
              </PrimaryButton>
              <div className="status-wrap">
                <div className="verify-tag-wrap pending-tag-wrap">
                  <img
                    src={infoCircle}
                    className="filterImage mr-c-1"
                    alt=""
                  />
                  {PENDING}
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    )
    : renderNoDataFound());

  const renderVerificationRequests = () => {
    switch (tabValue) {
      case 1:
        return renderPendingRequestList();
      default:
        return renderVerifiedRequest();
    }
  };

  const handleConfirmVerification = async () => {
    const data = {
      id: user?.id,
      comment: commentText,
    };
    const response = await dispatch(fetchConfirmVerification(data)).then(
      (val) => val,
    );
    const {
      payload: { status },
    } = response;
    if (status === true) {
      ToastNotifySuccess('confirm request successfully');
      setOpenViewDetailDialog(false);
      setCommentText('');
      getVerificationList();
    }
  };

  const handleAddComment = async () => {
    if (!commentText) {
      ToastNotifyError("Comment filled can't be empty");
    } else {
      const data = {
        id: user?.id,
        comment: commentText,
      };
      const response = await dispatch(fetchAddComment(data)).then((val) => val);
      const {
        payload: { status },
      } = response;
      if (status === true) {
        ToastNotifySuccess('Feedback sent successfully');
        setOpenViewDetailDialog(false);
        setCommentText('');
        getVerificationList();
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenViewDetailDialog(false);
  };

  const viewDetailContent = () => (
    <div className="verificationDetail-content-wrap">
      <div className="header-wrap">
        <div className="heading">{VERIFICATION_DETAILS}</div>
        {flag !== 0 ? (
          <div className="subheading">{VIEW_DETAILS_SUBHEADING}</div>
        ) : (
          ''
        )}
      </div>
      <div className="content-wrap">
        <div className="profile-detail-wrap">
          <div className="profile-pic-wrap">
            <img
              src={
                user?.profileImagePath
                  ? process.env.REACT_APP_IMAGE_URL + user.profileImagePath
                  : avatar
              }
              className="profileImage"
              alt=""
            />
          </div>
          <div className="other-detail-wrap">
            <div className="name">{user?.name}</div>
            <div className="email">{user?.email}</div>
          </div>
        </div>
        {verificationDetail[0]?.organization
        || verificationDetail[0]?.institute ? (
          <div className="education-detail-wrap">
            <div className="heading mb-c-1">
              {requestType === 0 ? EMPLOYMENT_DETAILS : EDUCATION_DETAILS}
            </div>
            <div className="subheading mb-c-1">
              {requestType === 0
                ? verificationDetail[0]?.organization
                : verificationDetail[0]?.institute}
            </div>
            <div className="description mb-c-1">
              {requestType === 0
                ? verificationDetail[0]?.designation
                : verificationDetail[0]?.course}
            </div>
            <div className="description">
              {verificationDetail[0]?.yearOfQualification
                || `${moment(verificationDetail[0]?.tenureStartDate).format(
                  'MMM, YYYY',
                )} - ${
                  verificationDetail[0]?.tenureEndDate !== null
                    ? moment(verificationDetail[0]?.tenureEndDate).format(
                      'MMM, YYYY',
                    )
                    : 'Present'
                }`}
            </div>
            {verificationDetail[0]?.otherDetails && (
              <div className="description" style={{ marginTop: '8px' }}>
                <ReadMore textSize={70}>
                  {verificationDetail[0]?.otherDetails}
                </ReadMore>
              </div>
            )}
          </div>
          ) : (
            ''
          )}
        {verificationDetail[0]?.comment ? (
          <div className="education-detail-wrap">
            <div className="heading mb-c-1">{MESSAGE}</div>
            <div className="description">{verificationDetail[0]?.comment}</div>
          </div>
        ) : (
          ''
        )}
      </div>
      {flag !== 0 ? (
        <div className="footer-wrap">
          <div className="comment-wrap">
            <TextInput
              placeholder={ADD_COMMENT_HERE}
              name="comment"
              type="text"
              multiline
              rows={1}
              onChange={(e) => setCommentText(e.target.value)}
              value={commentText}
              label={COMMENT}
              noIcon
            />
          </div>
          <div className="button-wrap">
            <PrimaryButton
              sx={{
                marginRight: '28px',
              }}
              onClick={() => handleConfirmVerification()}
            >
              {CONFIRM_VERIFICATION}
            </PrimaryButton>
            <SecondaryButton
              sx={{
                '@media screen and (max-width: 480px)': {
                  marginBottom: '16px',
                },
              }}
              onClick={() => handleAddComment()}
            >
              {ADD_COMMENT}
            </SecondaryButton>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );

  return (
    <Layout dashboard disableScroll>
      <div
        className="myProfile-wrap verification-wrap"
        ref={observerTarget}
        onScroll={onScroll}
      >
        <div className="myProfile-header">
          <CustomBreadcrummbs breadcrumbs={breadcrumbsList} />
        </div>
        <div className="verification-content-wrap content-wrap-main">
          <div className="tabs-wrap tabs-wrap-main">
            <CustomTabs
              tabList={tabListValue}
              selectedtabValue={tabValue}
              OnChange={(newValue) => handleTabChange(newValue)}
              data={queryDocument}
            />
          </div>
          <div className="cards-wrap card-wrap-main">
            {renderVerificationRequests()}
          </div>
        </div>
      </div>
      <Modal
        openDialog={openViewDetailDialog}
        closeDialog={() => {
          handleCloseDialog();
          setCommentText('');
        }}
        dialogContent={viewDetailContent()}
        dialogCss="verificationDetailDialog"
      />
    </Layout>
  );
};

export default VerificationRequests;
