/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useState } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import Layout from '../../layout';
import TextInput from '../../components/TextField';
import lang from '../../constants/lang';
import './signup.scss';
import MyRadioGroup from '../../components/radioButton';
import MyCheckbox from '../../components/chechBox';
import { PrimaryButton } from '../../components/Button';
import { validationSignupSchema } from '../../utils/validations/authValidation';
import MoreModal from '../../components/moreModal';
import { ROUTE_PATH } from '../../constants/route';
import cancel from '../../assets/svg/cancel.svg';
import { fetchSignup } from '../../store/reducers/authentication/apiThunk';
import visible from '../../assets/svg/visible.svg';
import slashVisible from '../../assets/svg/slashVisible.svg';
import CustomizedToolTip from '../../components/CustomizedTooltip';
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';

const Signup = () => {
  const [modal, setModal] = useState(false);
  const [modalPrivacy, setModalPrivacy] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [textVisible, setTextVisible] = useState(false);
  const navigate = useNavigate();
  const { LOGIN } = ROUTE_PATH;
  const {
    // eslint-disable-next-line max-len
    SIGNUP_HEADING,
    SIGNUP_SUB_HEADING,
    FIRST_NAME,
    LAST_NAME,
    EMAIL_ADDRESS,
    PASSWORD,
    CONFIRM_PASSWORD,
    SIGNUP_RADIO,
    AGREE_TO,
    TERMS_CONDITION,
    HAVE_ACCOUNT,
    SIGN_UP,
    INDIVIDUAL,
    ORGANISATION,
    SIGN_IN,
    TERMS,
    PRIVACY_H,
    ACCEPTANCE,
    ACCEPTANCE_MSG,
    REGISTRATION_MSG,
    USER_RESPONSIBILITY,
    STEP_ONE,
    STEP_TWO,
    STEP_THREE,
    USER_REGISTRATION,
  } = lang;

  const dispatch = useDispatch();

  const submitHandler = async (values) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      otpType: 1,
      type: values.type === 'option1' ? 'individual' : 'organization',
    };
    const response = await dispatch(fetchSignup(data)).then((val) => val);
    const { payload: { status = '' } = {} } = response;
    if (status === true) {
      navigate('/otp/1');
      ToastNotifySuccess('If you are unable to verify in the next 5 minutes, please email support@crediblecircle.com to get your account activated.');
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      type: 'option1',
      agreeToTerms: false,
    },
    validationSchema: validationSignupSchema,
    onSubmit: submitHandler,
  });

  const {
    handleSubmit,
    values: {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      agreeToTerms,
      type,
    } = {},
    // eslint-disable-next-line max-len
    touched: {
      email: tucEmail = false,
      firstName: tucFirst = false,
      lastName: tucLast = false,
      password: tucPassword = false,
      confirmPassword: tucConfirmPassword = false,
      agreeToTerms: tucAgreedToTerms = false,
    } = {},
    errors: {
      email: errEmail,
      firstName: errFirst,
      lastName: errLast,
      password: errPassword,
      confirmPassword: errConfirmPassword,
      agreeToTerms: errAgreedToTerms,
    } = {},
  } = formik;
  const handleChange = (e, name) => {
    const { target } = e;
    if (name === 'agreeToTerms') {
      formik.setFieldValue(name, target.checked);
    } else if (name === 'firstName' || name === 'lastName') {
      formik.setFieldValue(name, target.value);
    } else {
      formik.setFieldValue(name, target.value.trim());
    }
  };

  const handleTerms = (e) => {
    e.stopPropagation();
    setModal(true);
  };

  const termsModal = () => {
    return (
      <div className="signup-modal">
        <MoreModal open={modal} onClosed={() => setModal(false)}>
          <div className="terms-modal">
            <div
              className="close-button curser-pointer"
              onClick={() => setModal(false)}
            >
              <img src={cancel} />
            </div>
            <div className="modal-heading">{TERMS}</div>
            <div className="title-wrap">
              <div className="title-heading">{ACCEPTANCE}</div>
              <div className="title-content">{ACCEPTANCE_MSG}</div>
            </div>
            <div className="title-wrap">
              <div className="title-heading">{USER_REGISTRATION}</div>
              <div className="title-content">{REGISTRATION_MSG}</div>
            </div>
            <div className="title-wrap">
              <div className="title-heading">{USER_RESPONSIBILITY}</div>
              <div className="title-content">
                <p>{STEP_ONE}</p>
                <p>{STEP_TWO}</p>
                <p>{STEP_THREE}</p>
              </div>
            </div>
          </div>
        </MoreModal>
      </div>
    );
  };

  const privacyModal = () => {
    return (
      <div className="signup-modal">
        <MoreModal open={modalPrivacy} onClosed={() => setModalPrivacy(false)}>
          <div className="terms-modal">
            <div
              className="close-button curser-pointer"
              onClick={() => setModalPrivacy(false)}
            >
              <img src={cancel} />
            </div>
            <div className="modal-heading">{PRIVACY_H}</div>
            <div className="title-wrap">
              <div className="title-heading">{ACCEPTANCE}</div>
              <div className="title-content">{ACCEPTANCE_MSG}</div>
            </div>
            <div className="title-wrap">
              <div className="title-heading">{USER_REGISTRATION}</div>
              <div className="title-content">{REGISTRATION_MSG}</div>
            </div>
            <div className="title-wrap">
              <div className="title-heading">{USER_RESPONSIBILITY}</div>
              <div className="title-content">
                <p>{STEP_ONE}</p>
                <p>{STEP_TWO}</p>
                <p>{STEP_THREE}</p>
              </div>
            </div>
          </div>
        </MoreModal>
      </div>
    );
  };

  return (
    <Layout>
      <div className="signup-wrap">
        <div className="page-heading heading">{SIGNUP_HEADING}</div>
        <div className="page-subheading sub-heading">{SIGNUP_SUB_HEADING}</div>
        <div className="signup-form">
          <form onSubmit={handleSubmit}>
            <div className="radio-group my-c-2">
              <div className="radio-label">{SIGNUP_RADIO}</div>
              <MyRadioGroup
                onChange={(e) => handleChange(e, 'type')}
                value={type}
                option1={(
                  <div>
                    {INDIVIDUAL}
                    <CustomizedToolTip title={INDIVIDUAL} />
                  </div>
                )}
                option2={(
                  <div>
                    {ORGANISATION}
                    <CustomizedToolTip title={ORGANISATION} />
                  </div>
                )}
              />
            </div>
            <div className="name-field">
              <div className="first-name my-c-1">
                <TextInput
                  label={FIRST_NAME}
                  require="true"
                  type="text"
                  name="firstName"
                  sx={{ width: '100%' }}
                  onChange={(e) => handleChange(e, 'firstName')}
                  value={firstName}
                  error={tucFirst && !!errFirst}
                  helper={tucFirst && errFirst}
                  placeholder={FIRST_NAME}
                  maxLength={50}
                  noIcon
                />
              </div>
              <div className="last-name my-c-1">
                <TextInput
                  label={LAST_NAME}
                  require="true"
                  type="text"
                  name="lastName"
                  sx={{ width: '100%' }}
                  placeholder={LAST_NAME}
                  maxLength={50}
                  onChange={(e) => handleChange(e, 'lastName')}
                  value={lastName}
                  error={tucLast && !!errLast}
                  helper={tucLast && errLast}
                  noIcon
                />
              </div>
            </div>
            <div className="email-address my-c-1">
              <TextInput
                label={EMAIL_ADDRESS}
                placeholder={EMAIL_ADDRESS}
                require="true"
                type="text"
                name="email"
                onChange={(e) => handleChange(e, 'email')}
                value={email}
                error={tucEmail && !!errEmail}
                helper={tucEmail && errEmail}
                tooltip={type === 'option2' && 'Please enter official email id'}
                icon={(
                  <EmailOutlinedIcon
                    sx={{
                      color: '#7a869aa3',
                    }}
                  />
                )}
                noIcon
              />
            </div>
            <div className="my-c-1">
              <TextInput
                label={PASSWORD}
                placeholder={PASSWORD}
                require="true"
                type={textVisible ? 'text' : 'password'}
                name="password"
                onChange={(e) => handleChange(e, 'password')}
                value={password}
                error={tucPassword && !!errPassword}
                helper={tucPassword && errPassword}
                noIcon
                icon={(
                  <span
                    onClick={() => setTextVisible(!textVisible)}
                    className="icons-wrap"
                  >
                    {textVisible ? (
                      <img src={visible} alt="" />
                    ) : (
                      <img src={slashVisible} alt="" />
                    )}
                  </span>
                )}
              />
            </div>
            <div className="my-c-1">
              <TextInput
                label={CONFIRM_PASSWORD}
                placeholder={CONFIRM_PASSWORD}
                require="true"
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                onChange={(e) => handleChange(e, 'confirmPassword')}
                value={confirmPassword}
                error={tucConfirmPassword && !!errConfirmPassword}
                helper={tucConfirmPassword && errConfirmPassword}
                icon={(
                  <span
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="icons-wrap"
                  >
                    {showConfirmPassword ? (
                      <img src={visible} alt="" />
                    ) : (
                      <img src={slashVisible} alt="" />
                    )}
                  </span>
                )}
                noIcon
              />
            </div>
            <div className=" my-c-2">
              <div className="terms-condition">
                <MyCheckbox
                  name="agreeToTerms"
                  checked={agreeToTerms}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleChange(e, 'agreeToTerms');
                  }}
                  error={tucAgreedToTerms && !!errAgreedToTerms}
                  // helperText={tucAgreedToTerms && errAgreedToTerms}
                />
                <div className="agree-to">
                  {AGREE_TO}
                  <span className="t-c" style={{ cursor: 'pointer' }} onClick={(e) => handleTerms(e)}>{TERMS_CONDITION}</span>
                </div>
              </div>
              <div className="textfield-error">
                {tucAgreedToTerms && errAgreedToTerms ? (
                  <div>
                    <span>{errAgreedToTerms}</span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <PrimaryButton type="submit">{SIGN_UP}</PrimaryButton>
            <div className="agree-to account-exist my-c-2" style={{ display: 'flex', justifyContent: 'center' }}>
              {HAVE_ACCOUNT}
              <div
                className="t-c curser-pointer signin"
                onClick={() => navigate(LOGIN)}
                style={{ fontSize: '14px' }}
              >
                {SIGN_IN}
              </div>
            </div>
          </form>
        </div>
        {termsModal()}
        {privacyModal()}
      </div>
    </Layout>
  );
};

export default Signup;
